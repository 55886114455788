import { Button, Card, Flex, Typography } from "antd";
import DOProgressTable from "../../components/do_progress/DOProgressTable";
import DOProgressPreviewModal from "../../components/do_progress/DOProgressPreviewModal";
import useGetDOProgressList from "../../functions/DOProgress/getDOProgressList";
import { useEffect, useState } from "react";
import useGetDOProgressDetails from "../../functions/DOProgress/getDOProgressDetails";
import useUpdateDOProgress from "../../functions/DOProgress/updateDOProgress";
import DOProgressSearchForm from "../../components/do_progress/DOProgressSearchForm";
import useRemoveBundle from "../../functions/DOProgress/removeBundle";
import dayjs from "dayjs";
import CustomRangePicker from "../../components/common/CustomRangePicker";

export default function DOProgressPage() {
  // Handle DO Progress preview modal
  const [previewModal, setPreviewModal] = useState(false);
  // Handle DO Progress search modal
  const [searchModal, setSearchModal] = useState(false);
  // For handle specific DO Progress key
  const [selectKey, setSelectKey] = useState(null);

  const [dateValue, setDateValue] = useState([
    dayjs().subtract(6, "day").startOf("day"),
    dayjs().endOf("day"),
  ]);

  // For filter DO Progress data
  const [filterDOProgress, setFilterDOProgress] = useState([]);

  // Api Function to get DO Progress List
  const { getDOProgressList, doProgressList, isLoading } =
    useGetDOProgressList();
  // Api Function to get DO Progress Details
  const { getDOProgressDetails, previewLoading, previewSummary, previewTable } =
    useGetDOProgressDetails();
  // Api Function to update DO Progress
  const { updateDOProgress, updateLoading } = useUpdateDOProgress();
  // Api Function to remove bundle in DO Progress Details
  const { removeBundle, removeLoading } = useRemoveBundle();

  // Function to call api by filter data
  const handleDOProgressList = () =>
    getDOProgressList(dateValue[0], dateValue[1]);
  // Function to get DO Progress detail data and open preview modal
  const handlePreview = (key) =>
    getDOProgressDetails(key, setPreviewModal, setSelectKey);
  // Function to call remove bundle api
  const handleRemove = (key) => {
    removeBundle(
      key,
      handleDOProgressList,
      getDOProgressDetails,
      selectKey,
      setPreviewModal,
      setSelectKey
    );
  };
  // Function to call update DO Progress status api
  const handleUpdate = (key) => updateDOProgress(key, handleDOProgressList);
  // Function for reset filter data
  const resetFilter = () => setFilterDOProgress(doProgressList);
  const onDateChange = (value) => setDateValue(value);

  // For filter the do progress data
  useEffect(() => {
    setFilterDOProgress(doProgressList);
  }, [doProgressList]);

  // For get the do progress data
  useEffect(() => {
    handleDOProgressList();
  }, []);

  return (
    <>
      <Card
        title={
          <Flex justify="space-between" align="center" wrap="wrap" gap="small">
            <Typography.Text>Delivery Order Progress Table</Typography.Text>
            <Flex gap="middle" wrap="wrap">
              <CustomRangePicker
                onDateChange={onDateChange}
                disabled={isLoading}
                search={true}
                showTime={false}
                onFilterClick={handleDOProgressList}
              />
              <Button
                children="Reset"
                disabled={isLoading}
                onClick={resetFilter}
              />
              <Button
                children="Advanced Search"
                type="primary"
                onClick={() => setSearchModal(true)}
                disabled={isLoading}
              />
            </Flex>
          </Flex>
        }
        className="context-card"
        loading={isLoading}
      >
        <DOProgressTable
          tableData={filterDOProgress}
          previewLoading={previewLoading}
          handlePreview={handlePreview}
          updateLoading={updateLoading}
          handleUpdate={handleUpdate}
        />
      </Card>
      <DOProgressPreviewModal
        modalOpen={previewModal}
        setModalOpen={setPreviewModal}
        summaryData={previewSummary}
        tableData={previewTable}
        handleRemove={handleRemove}
        removeLoading={removeLoading}
      />
      <DOProgressSearchForm
        modalOpen={searchModal}
        setModalOpen={setSearchModal}
        tableData={doProgressList}
        setFilterTable={setFilterDOProgress}
      />
    </>
  );
}
