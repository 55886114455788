import { useCallback, useState } from "react";
import useFetch from "../../../hooks/useFetch";
import { useShowMessage } from "../../Common/showMessage";

function useGetChemicalMinMaxList() {
  const [fetchWrapper] = useFetch();
  const [chemicalMinMaxLoading, setChemicalMinMaxLoading] = useState(false);
  const [chemicalMinMaxList, setChemicalMinMaxList] = useState([]);

  const showMessage = useShowMessage();

  const getChemicalMinMaxList = useCallback(() => {
    setChemicalMinMaxLoading(true);
    fetchWrapper({
      endpoint_url: "configuration/getChemicalMinMaxList",
      setLoading: setChemicalMinMaxLoading,
      onSuccess: (fetch_output) => {
        if (fetch_output.status) {
          setChemicalMinMaxList(fetch_output.data);
        } else {
          showMessage({ type: "warning", message: fetch_output.error });
        }
      },
      onFetchError: () => {
        showMessage({ apiType: "fetch", message: "get chemical min max list" });
      },
      onResponseError: () => {
        showMessage({ apiType: "response", message: "Chemical Min Max List" });
      },
    });
  }, [fetchWrapper]);
  return { getChemicalMinMaxList, chemicalMinMaxList, chemicalMinMaxLoading };
}

export default useGetChemicalMinMaxList;
