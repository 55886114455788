import { useCallback, useState } from "react";
import useFetch from "../../../hooks/useFetch";
import { useShowMessage } from "../../Common/showMessage";

function useGetShiftData() {
  const [fetchWrapper] = useFetch();
  const [shiftTableData, setShiftTableData] = useState([]);
  const [shiftColumn, setShiftColumn] = useState([]);
  const [shiftChartData, setShiftChartData] = useState([]);
  const [shiftDownload, setShiftDownload] = useState([]);

  const showMessage = useShowMessage();

  const getShiftData = useCallback(
    (factory, diameter, startDate, endDate, time, setIsLoading) => {
      setIsLoading(true);
      fetchWrapper({
        endpoint_url: "statistics/getShiftData",
        params: {
          factory: factory,
          diameter: diameter,
          startDate: startDate,
          endDate: endDate,
          time: time,
        },
        setLoading: setIsLoading,
        onSuccess: (fetch_ouput) => {
          if (fetch_ouput.status) {
            setShiftTableData(fetch_ouput.table_data);
            setShiftColumn(fetch_ouput.table_column);
            setShiftChartData(fetch_ouput.chart_data);
            setShiftDownload(fetch_ouput.download_data);
          } else {
            showMessage({ type: "warning", message: fetch_ouput.error });
          }
        },
        onFetchError: () => {
          showMessage({ apiType: "fetch", message: "get shift table" });
        },
        onResponseError: () => {
          showMessage({ apiType: "response", message: "Get Shift Table" });
        },
      });
    },
    [fetchWrapper]
  );
  return {
    getShiftData,
    shiftTableData,
    shiftColumn,
    shiftChartData,
    shiftDownload,
  };
}

export default useGetShiftData;
