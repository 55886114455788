import { Button, Flex } from "antd";
import DataTable from "../../common/DataTable";
import CustomDownloadExcelButton from "../../common/CustomDownloadExcelButton";
import dayjs from "dayjs";

const EXCEL_HEADER = {
  date: "Date",
  factory: "Factory",
  product: "Product",
  type: "Type",
  sequence: "Serial Missing",
  missing: "Missing Bundles Qty",
};

export default function DailyBundleTable({ onDetailsClick, tableData }) {
  const DAILY_COLUMN = [
    {
      title: "No.",
      dataIndex: "number",
      key: "number",
      width: 80,
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      width: 160,
    },
    {
      title: "Factory",
      dataIndex: "factory",
      key: "factory",
      width: 100,
    },
    {
      title: "Product",
      dataIndex: "product",
      key: "product",
      width: 100,
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
      width: 100,
    },
    {
      title: "Stage",
      dataIndex: "stage",
      key: "stage",
      width: 100,
    },
    {
      title: "Min Sequence",
      dataIndex: "min_sequence",
      key: "min_sequence",
      width: 160,
    },
    {
      title: "Max Sequence",
      dataIndex: "max_sequence",
      key: "max_sequence",
      width: 160,
    },
    {
      title: "Expected Bundles",
      dataIndex: "expected_bundles",
      key: "expected_bundles",
      width: 160,
    },
    {
      title: "Actual Bundles",
      dataIndex: "actual_bundles",
      key: "actual_bundles",
      width: 160,
    },
    {
      title: "Missing Bundles",
      dataIndex: "missing_bundles",
      key: "missing_bundles",
      width: 160,
    },
    {
      title: "Actual Weight (Tonnes)",
      dataIndex: "actual_weight",
      key: "actual_weight",
      width: 160,
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      width: 150,
      render: (_, record) => {
        const dateName = dayjs(record.date, "YYYY-MM-DD").format("DDMMYY");
        const serialName = `${record.factory}-${record.product.slice(1)}-${
          record.type
        }-${dateName}`;

        return (
          <Flex gap={"small"}>
            <Button
              type="link"
              onClick={() => onDetailsClick(record, serialName)}
            >
              Details
            </Button>
            <CustomDownloadExcelButton
              dataList={formatData(record)}
              excelHeader={EXCEL_HEADER}
              fileName={serialName}
            />
          </Flex>
        );
      },
    },
  ];

  const formatData = (data) => {
    return data?.missing_serial?.map((value) => {
      let missingCount = 1;
      if (value.split(" to ").length == 2) {
        const [start, end] = value.split(" to ").map(Number);
        missingCount = end - start + 1;
      }

      return {
        date: data.date,
        factory: data.factory,
        product: data.product,
        type: data.type,
        sequence: value,
        missing: missingCount,
      };
    });
  };

  return (
    <DataTable
      table_data={tableData}
      table_column={DAILY_COLUMN}
      pageSize={7}
      columnCenter={true}
    />
  );
}
