import { Button, Flex, Tooltip } from "antd";
import DataTable from "../common/DataTable";
import FunctionalButton from "../common/FunctionalButton";
import { FormOutlined, InfoCircleOutlined } from "@ant-design/icons";

export default function DemoWeightTable({ tableData, openWeightModal }) {
  const WEIGHT_COLUMN = [
    {
      title: "No.",
      dataIndex: "number",
      key: "number",
      width: 80,
    },
    {
      title: "Lorry Plate",
      dataIndex: "lorry_plate",
      key: "lorry_plate",
      width: 120,
    },
    {
      title: "Delivery Date",
      dataIndex: "delivery_date",
      key: "delivery_date",
      width: 140,
    },
    {
      title: "Arrival Weight",
      dataIndex: "arrival_weight",
      key: "arrival_weight",
      width: 180,
    },
    {
      title: "Departure Weight",
      dataIndex: "departure_weight",
      key: "departure_weight",
      width: 180,
    },
    {
      title: "Arrival Weight Time",
      dataIndex: "arrival_weight_time",
      key: "arrival_weight_time",
      width: 180,
    },
    {
      title: "Departure Weight Time",
      dataIndex: "departure_weight_time",
      key: "departure_weight_time",
      width: 180,
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      width: 180,
      render: (_, record) => {
        const weighType =
          record.arrival && !record.departure
            ? "departure"
            : !record.arrival && !record.departure
            ? "arrival"
            : null;

        return (
          <Flex gap={"small"} justify="center" align="center">
            {record.check_in && record.arrival && record.departure ? (
              <Tooltip
                title={
                  "Lorry has already assign arrival weight and departure weight"
                }
                children={
                  <Button
                    icon={<InfoCircleOutlined />}
                    style={{ width: "40px", height: "35px" }}
                  />
                }
              />
            ) : record.check_in ? (
              <FunctionalButton
                buttonClickFunction={() => openWeightModal(weighType)}
                icon={<FormOutlined />}
                width="40px"
              />
            ) : (
              <Tooltip
                title={"Lorry has not arrived for check-in"}
                children={
                  <Button
                    icon={<InfoCircleOutlined />}
                    style={{ width: "40px", height: "35px" }}
                  />
                }
              />
            )}
          </Flex>
        );
      },
    },
  ];

  return (
    <DataTable
      table_data={tableData}
      table_column={WEIGHT_COLUMN}
      columnCenter={true}
      pageSize={10}
    />
  );
}
