import { useCallback, useState } from "react";
import useFetch from "../../../hooks/useFetch";
import { useShowMessage } from "../../Common/showMessage";

function useGetDeliveryBundles() {
  const [fetchWrapper] = useFetch();
  const [deliveryChart, setDeliveryChart] = useState([]);
  const [deliverySummary, setDeliverySummary] = useState({});

  const showMessage = useShowMessage();

  const getDeliveryBundles = useCallback(
    (setIsLoading) => {
      setIsLoading(true);
      fetchWrapper({
        endpoint_url: "dashboard/getDeliveryBundles",
        setLoading: setIsLoading,
        onSuccess: (fetch_output) => {
          if (fetch_output.status) {
            setDeliveryChart(fetch_output.chart_data);
            setDeliverySummary(fetch_output.summary_data);
          } else {
            showMessage({ type: "warning", message: fetch_output.error });
          }
        },
        onFetchError: () => {
          showMessage({ apiType: "fetch", message: "get delivery bundles" });
        },
        onResponseError: () => {
          showMessage({ apiType: "response", message: "Get Delivery Bundles" });
        },
      });
    },
    [fetchWrapper]
  );

  return { getDeliveryBundles, deliveryChart, deliverySummary };
}

export default useGetDeliveryBundles;
