import CustomDescription from "../common/CustomDescription";
import CustomModal from "../common/CustomModal";
import { Flex, Image, Tag, Typography } from "antd";
import barMarkImage from "../../assets/Bar_Mark.png";
import FunctionalButton from "../common/FunctionalButton";

export default function DemoDetailsModal({
  modalOpen,
  setModalOpen,
  detailsData,
}) {
  const detailsItems = [
    {
      label: "DO Number",
      key: "do_number",
      children: detailsData?.do_number,
    },
    {
      label: "SO Number",
      key: "so_number",
      children: detailsData?.so_number,
    },
    {
      label: "CO Number",
      key: "co_number",
      children: detailsData?.co_number,
    },
    {
      label: "Customer",
      key: "customer",
      children: detailsData?.customer,
    },
    {
      label: "Address",
      key: "address",
      children: detailsData?.address
        ? detailsData.address.split("\n").map((line, index) => (
            <>
              <Typography.Text key={index} children={line} />
              <br />
            </>
          ))
        : null,
      contentStyle: { display: "block" },
    },
    {
      label: "Delivery Date Time",
      key: "delivery_date_time",
      children: detailsData?.delivery_date_time,
    },
    {
      label: "Product",
      key: "product",
      children: detailsData?.product,
    },
    {
      label: "Number of Bundles",
      key: "number_of_bundles",
      children: detailsData?.number_of_bundles,
    },
    {
      label: "Lorry Plate",
      key: "lorry_plate",
      children: detailsData?.lorry_plate,
    },
    {
      label: "Specification",
      key: "specification",
      children: detailsData?.specification,
    },
    {
      label: "Description of Good",
      key: "description_of_good",
      children: detailsData?.description_of_good,
    },
    {
      label: "Bar Mark",
      key: "bar_mark",
      children: detailsData?.bar_mark ? (
        <Image src={barMarkImage} />
      ) : (
        <Image src={barMarkImage} />
      ),
    },
  ];

  return (
    <CustomModal
      visible={modalOpen}
      onCancel={() => setModalOpen(false)}
      close_icon={true}
      width={1600}
      title={
        <Flex justify="space-between" align="center" style={{ width: "100%" }}>
          <h4 style={{ marginBlockStart: "0px", marginBlockEnd: "0px" }}>
            Delivery Order Details - {detailsData.do_number}
          </h4>
          <Tag
            color={
              detailsData.status == "Pending"
                ? "yellow"
                : detailsData.status == "Reject"
                ? "error"
                : detailsData.status == "In Progress"
                ? "processing"
                : "success"
            }
            children={detailsData.status}
            style={{ marginRight: "30px" }}
          />
        </Flex>
      }
      content={
        <>
          <CustomDescription
            items={detailsItems}
            style={{ marginTop: "30px" }}
          />
          {detailsData.status != "Approve" &&
            detailsData.status != "Reject" && (
              <Flex gap={"middle"} justify="flex-end">
                <FunctionalButton
                  buttonClickFunction={() => {}}
                  children={"Reject"}
                  show_confirmation={true}
                  confirmation_message={{
                    title: "Confirm Reject Delivery Order",
                  }}
                  danger={true}
                  width="100px"
                />
                <FunctionalButton
                  buttonClickFunction={() => {}}
                  children={"Approve"}
                  show_confirmation={true}
                  confirmation_message={{
                    title: "Confirm Approve Delivery Order",
                  }}
                  width="100px"
                />
              </Flex>
            )}
        </>
      }
    />
  );
}
