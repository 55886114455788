import { Card, Flex, Grid, List, Tabs, Typography } from "antd";
import CustomModal from "../../common/CustomModal";
import CustomDescription from "../../common/CustomDescription";
import { useEffect, useState } from "react";
import MissingBundleList from "./MissingBundleList";
import MissingBundleTable from "./MissingBundleTable";

const { useBreakpoint } = Grid;

const DETAIL_LIST = [
  "factory",
  "product",
  "type",
  "date",
  "missing_bundles",
  "missing_weight",
];

const DETAILS_PREFIX = {
  missing_bundles: "bundles",
  missing_weight: "tonnes",
};

export default function MissingBundleModal({
  modalOpen,
  missingDetails,
  onModalClose,
}) {
  const screens = useBreakpoint();
  const [pageSize, setPageSize] = useState(12);

  const DESCRIPTION_ITEMS = Object.entries(missingDetails?.data || {})
    .map(([key, value]) => {
      if (DETAIL_LIST.includes(key))
        return {
          key: key,
          label: key.split("_").map((value) => {
            return value.charAt(0).toUpperCase() + value.slice(1) + " ";
          }),
          children: DETAILS_PREFIX[key]
            ? `${value} ${DETAILS_PREFIX[key]}`
            : value ?? "----",
          span: { xs: 3, sm: 2, md: 1, lg: 1, xl: 1, xxl: 1 },
        };
    })
    .filter(Boolean)
    .sort((a, b) => DETAIL_LIST.indexOf(a.key) - DETAIL_LIST.indexOf(b.key));

  useEffect(() => {
    if (screens.xxl) setPageSize(12);
    else if (screens.xl) setPageSize(9);
    else if (screens.lg) setPageSize(9);
    else if (screens.md) setPageSize(6);
    else if (screens.sm) setPageSize(4);
    else if (screens.xs) setPageSize(3);
    else setPageSize(30);
  }, [screens]);

  return (
    <CustomModal
      title={`Missing Bundle List (${missingDetails?.data?.stage})`}
      visible={modalOpen}
      close_icon={true}
      onCancel={onModalClose}
      width={1200}
      content={
        <>
          <CustomDescription
            items={DESCRIPTION_ITEMS}
            style={{ marginTop: "20px" }}
          />
          <Tabs
            type="card"
            items={[
              {
                label: "Missing List",
                key: "list",
                children: (
                  <MissingBundleList
                    missingDetails={missingDetails}
                    pageSize={pageSize}
                  />
                ),
              },
              {
                label: "Missing Table",
                key: "table",
                children: (
                  <MissingBundleTable
                    tableData={missingDetails?.data?.serial_list || []}
                  />
                ),
              },
            ]}
          />
        </>
      }
    />
  );
}
