import { useContext, useEffect, useRef } from "react";
import { IndexContext } from "../../context/IndexContext";
import { Pie } from "@antv/g2plot";

export default function CustomDonutChart(props) {
  const {
    data = [],
    appendPadding = 10,
    angleField = "value",
    colorField = "type",
    radius = 1,
    innerRadius = 0.5,
    statisticContent = "",
    chartSize = "40vh",
    legendOpen = false,
    labelOpen = false,
    colors = ["#1890FF", "#D9D9D9"],
    animationOpen = true,
    animationDuration = 2000,
    animationType = "wave-in",
    animationEasing = "easeCircleOut",
  } = props;

  const { isLightTheme } = useContext(IndexContext);
  const containerRef = useRef(null);
  const chartRef = useRef(null);

  useEffect(() => {
    if (containerRef.current) {
      if (chartRef.current) {
        chartRef.current.destroy();
        chartRef.current = null;
      }

      chartRef.current = new Pie(containerRef.current, {
        data,
        appendPadding: appendPadding,
        angleField: angleField,
        colorField: colorField,
        radius: radius,
        innerRadius: innerRadius,
        statistic: {
          title: false,
          content: {
            style: {
              whiteSpace: "pre-wrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              color: isLightTheme ? "#000000" : "#FFFFFF",
            },
            content: statisticContent,
          },
        },
        legend: legendOpen,
        label: labelOpen && {
          type: "inner",
          offset: "-50%",
          content: "{value}",
          style: {
            textAlign: "center",
            fontSize: 14,
          },
        },
        interactions: [
          {
            type: "element-highlight",
          },
          {
            type: "legend-highlight",
          },
          {
            type: "active-region",
          },
        ],
        animation: animationOpen && {
          appear: {
            animation: animationType,
            duration: animationDuration,
            easing: animationEasing,
          },
        },
        color: colors,
      });
      chartRef.current.render();
    }

    return () => {
      if (chartRef.current) {
        chartRef.current.destroy();
        chartRef.current = null;
      }
    };
  }, [isLightTheme, data]);

  return <div ref={containerRef} style={{ height: chartSize }} />;
}
