import { useCallback, useState } from "react";
import useFetch from "../../../hooks/useFetch";
import { useShowMessage } from "../../Common/showMessage";

function useCheckRebarStatus() {
  const [fetchWrapper] = useFetch();
  const [rebarCount, setRebarCount] = useState(null);
  const [rebarKey, setRebarKey] = useState(null);
  const [aiImageSrc, setAiImageSrc] = useState([]);

  const showMessage = useShowMessage();

  const checkRebarStatus = useCallback(
    (
      key,
      setIsLoading,
      resetErrorCount,
      addErrorCount,
      setResultType,
      timeoutDuration = 60000
    ) => {
      let timeoutReached = false;
      return new Promise((resolve, reject) => {
        const timeout = setTimeout(() => {
          timeoutReached = true;
          setIsLoading({ progress: 100, state: false, res: false });
          setResultType("timeout_error");
        }, timeoutDuration);

        fetchWrapper({
          endpoint_url: "ai_rebar_count/checkRebarStatus",
          params: {
            id: key,
          },
          onSuccess: (fetch_output) => {
            if (fetch_output.status) {
              if (
                fetch_output.ai_count !== null &&
                fetch_output.ai_image_src != null
              ) {
                setRebarKey(fetch_output.key);
                setRebarCount(fetch_output.ai_count);
                setAiImageSrc(fetch_output.ai_image_src);
                setIsLoading({ progress: 100, state: false, res: true });
                setResultType(fetch_output.result_type);
                resetErrorCount();
                clearTimeout(timeout);
              } else {
                setTimeout(() => {
                  if (!timeoutReached)
                    checkRebarStatus(
                      key,
                      setIsLoading,
                      resetErrorCount,
                      addErrorCount,
                      setResultType
                    );
                  clearTimeout(timeout);
                }, 5000);
              }
            } else {
              addErrorCount();
              if (timeoutReached) {
                setResultType("timeout_error");
              } else {
                setResultType(fetch_output.result_type);
              }
              setIsLoading({ progress: 100, state: false, res: false });
              clearTimeout(timeout);
            }
            resolve(fetch_output);
          },
          onFailure: (error) => {
            setResultType("network_error");
            setIsLoading({ progress: 100, state: false, res: false });
            showMessage({ message: error });
            reject(error);
          },
          onFetchError: () => {
            setResultType("network_error");
            setIsLoading({ progress: 100, state: false, res: false });
            showMessage({ apiType: "fetch", message: "check rebar status" });
            clearTimeout(timeout);
          },
          onResponseError: () => {
            setResultType("network_error");
            setIsLoading({ progress: 100, state: false, res: false });
            showMessage({ apiType: "response", message: "Check Rebar Status" });
            clearTimeout(timeout);
          },
        });
      });
    },
    [fetchWrapper]
  );
  return {
    checkRebarStatus,
    rebarKey,
    rebarCount,
    setRebarCount,
    aiImageSrc,
  };
}

export default useCheckRebarStatus;
