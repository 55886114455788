import { useContext } from "react";
import { IndexContext } from "../../context/IndexContext";

export function useShowMessage() {
  const { messageApi } = useContext(IndexContext);

  const showMessage = ({
    type = "error",
    apiType = null,
    message,
    extra = null,
  }) => {
    let content;

    switch (apiType) {
      case "response":
        content = `Failed to Respond: ${message}. Please Contact Admin. ${
          extra ? extra : ""
        }`;
        break;
      case "fetch":
        content = `Network Error. Failed to ${message}.`;
        break;
      default:
        content = message;
        break;
    }

    messageApi.open({
      type: type,
      content: content,
    });
  };

  return showMessage;
}
