import CustomDonutChart from "../../chart/CustomDonutChart";

export default function InventoryDonutChart({ data }) {
  const configData = [
    {
      type: "Stuck",
      value: data.stuck,
    },
    {
      type: "Success",
      value: data.success,
    },
  ];

  const total = data.success + data.stuck;
  const stuckPercentage = ((data.stuck / total) * 100).toFixed(0);

  return (
    <CustomDonutChart
      data={configData}
      appendPadding={5}
      innerRadius={0.7}
      statisticContent={`${stuckPercentage == "NaN" ? 0 : stuckPercentage}%`}
      chartSize={"16vh"}
    />
  );
}
