import { useCallback, useState } from "react";
import useFetch from "../../hooks/useFetch";
import { useShowMessage } from "../Common/showMessage";

function useGetTagIDTraceability() {
  const [fetchtWrapper] = useFetch();
  const [traceabilityTable, setTraceabilityTable] = useState([]);
  const [traceabilityLoading, setTraceabilityLoading] = useState(false);
  const [traceabilityDownload, setTraceabilityDownload] = useState([]);

  const showMessage = useShowMessage();

  const getTagIDTraceability = useCallback(
    (startDate, endDate) => {
      setTraceabilityLoading(true);
      fetchtWrapper({
        endpoint_url: "traceability/getTagIDTraceability",
        params: {
          startDate: startDate,
          endDate: endDate,
        },
        setLoading: setTraceabilityLoading,
        onSuccess: (fetch_output) => {
          if (fetch_output.status) {
            setTraceabilityTable(fetch_output.table_data);
            setTraceabilityDownload(fetch_output.download_data);
          } else {
            showMessage({ type: "warning", message: fetch_output.error });
          }
        },
        onFetchError: () => {
          showMessage({ apiType: "fetch", message: "get traceabilty data" });
        },
        onResponseError: () => {
          showMessage({
            apiType: "response",
            message: "Get Tag ID Traceability",
          });
        },
      });
    },
    [fetchtWrapper]
  );

  return {
    getTagIDTraceability,
    traceabilityTable,
    traceabilityLoading,
    traceabilityDownload,
  };
}

export default useGetTagIDTraceability;
