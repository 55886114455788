import { useCallback, useState } from "react";
import useFetch from "../../../hooks/useFetch";
import { useShowMessage } from "../../Common/showMessage";

function useGetMechanicalMinMaxList() {
  const [fetchWrapper] = useFetch();
  const [mechanicalMinMaxLoading, setMechanicalMinMaxLoading] = useState(false);
  const [mechanicalMinMaxList, setMechanicalMinMaxList] = useState([]);

  const showMessage = useShowMessage();

  const getMechanicalMinMaxList = useCallback(() => {
    setMechanicalMinMaxLoading(true);
    fetchWrapper({
      endpoint_url: "configuration/getMechanicalMinMaxList",
      setLoading: setMechanicalMinMaxLoading,
      onSuccess: (fetch_output) => {
        if (fetch_output.status) {
          setMechanicalMinMaxList(fetch_output.data);
        } else {
          showMessage({ type: "warning", message: fetch_output.error });
        }
      },
      onFetchError: () => {
        showMessage({
          apiType: "fetch",
          message: "get mechanical min max list",
        });
      },
      onResponseError: () => {
        showMessage({
          apiType: "response",
          message: "Mechanical Min Max List",
        });
      },
    });
  }, [fetchWrapper]);
  return {
    getMechanicalMinMaxList,
    mechanicalMinMaxList,
    mechanicalMinMaxLoading,
  };
}

export default useGetMechanicalMinMaxList;
