import { useCallback, useEffect, useState } from "react";
import useFetch from "../../hooks/useFetch";
import { useShowMessage } from "../Common/showMessage";
import useRetry from "../../hooks/useRetry";
import { MAX_RETRIES } from "../../configs/configs";

function useGetUserTypeOption() {
  const [fetchWrapper] = useFetch();
  const [userTypeOption, setUserTypeOption] = useState([]);

  const showMessage = useShowMessage();
  const { retryCount, setRetryCount } = useRetry(() => getUserTypeOption());

  const getUserTypeOption = useCallback(() => {
    fetchWrapper({
      endpoint_url: "options/getUserTypeOption",
      onSuccess: (fetch_output) => {
        if (fetch_output.status) {
          setUserTypeOption(fetch_output.options);
          setRetryCount(0);
        } else {
          showMessage({ type: "warning", message: fetch_output.error });
        }
      },
      onFetchError: () => {
        if (retryCount === MAX_RETRIES)
          showMessage({ apiType: "fetch", message: "get user type option" });

        setRetryCount((prev) => prev + 1);
      },
      onResponseError: () => {
        if (retryCount === MAX_RETRIES)
          showMessage({ apiType: "response", message: "Get User Type Option" });

        setRetryCount((prev) => prev + 1);
      },
    });
  }, [fetchWrapper]);

  useEffect(() => {
    getUserTypeOption();
  }, []);

  return { userTypeOption };
}

export default useGetUserTypeOption;
