import { useEffect, useState } from "react";
import { MAX_RETRIES } from "../configs/configs";

function useRetry(callback, maxRetries = MAX_RETRIES, delay = 500) {
  const [retryCount, setRetryCount] = useState(0);

  useEffect(() => {
    if (retryCount > 0 && retryCount <= maxRetries) {
      const retryTimer = setTimeout(() => callback(), delay);
      return () => clearTimeout(retryTimer);
    }
  }, [retryCount]);

  return { retryCount, setRetryCount };
}

export default useRetry;
