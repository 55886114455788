import CustomDonutChart from "../../chart/CustomDonutChart";

export default function IndicatorDonutChart({ data }) {
  const configData = [
    {
      type: "Non-Adjusted",
      value: data.non_adjust,
    },
    {
      type: "Adjusted",
      value: data.adjusted,
    },
  ];

  const total = data.non_adjust + data.adjusted;
  const nonAdjustPercentage = ((data.non_adjust / total) * 100).toFixed(0);

  return (
    <CustomDonutChart
      data={configData}
      appendPadding={5}
      innerRadius={0.7}
      statisticContent={`${
        nonAdjustPercentage == "NaN" ? 0 : nonAdjustPercentage
      }%`}
      chartSize={"16vh"}
    />
  );
}
