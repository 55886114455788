import { Button, Flex } from "antd";
import DataTable from "../common/DataTable";
import FunctionalButton from "../common/FunctionalButton";
import {
  CheckCircleOutlined,
  EyeOutlined,
  MinusCircleOutlined,
} from "@ant-design/icons";
import { WordCloud } from "@ant-design/plots";

export default function DemoSecurityTable({ tableData }) {
  const SECURITY_COLUMN = [
    {
      title: "No.",
      dataIndex: "number",
      key: "number",
      width: 80,
    },
    {
      title: "Lorry Plate",
      dataIndex: "lorry_plate",
      key: "lorry_plate",
      width: 120,
    },
    // {
    //   title: "Delivery Order List",
    //   dataIndex: "delivery_order_list",
    //   key: "delivery_order_list",
    //   render: (_, record) => {
    //     return (
    //       <FunctionalButton
    //         buttonClickFunction={() => {}}
    //         icon={<EyeOutlined />}
    //         type=""
    //         width="40px"
    //       />
    //     );
    //   },
    // },
    {
      title: "Delivery Date",
      dataIndex: "delivery_date",
      key: "delivery_date",
      width: 160,
    },
    {
      title: "Checked In Date Time",
      dataIndex: "checked_in_date_time",
      key: "checked_in_date_time",
      width: 180,
    },
    {
      title: "Checked Out Date Time",
      dataIndex: "checked_out_date_time",
      key: "checked_out_date_time",
      width: 180,
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      width: 180,
      render: (_, record) => {
        return (
          <Flex gap={"middle"} align="center" justify="center">
            <FunctionalButton
              buttonClickFunction={() => {}}
              icon={<CheckCircleOutlined />}
              show_confirmation={true}
              confirmation_message={{
                title: `Are you sure to ${
                  record.check_in ? "check out" : "check in"
                } this lorry ?`,
              }}
              danger={record.check_in}
              disabled={record.check_in && record.check_out}
              width="40px"
            />
          </Flex>
        );
      },
    },
  ];

  return (
    <DataTable
      table_data={tableData}
      table_column={SECURITY_COLUMN}
      columnCenter={true}
      pageSize={10}
    />
  );
}
