import { useCallback } from "react";
import useFetch from "../../../hooks/useFetch";
import { useShowMessage } from "../../Common/showMessage";

function useUpdateMechanicalMinMax() {
  const [fetchWrapper] = useFetch();

  const showMessage = useShowMessage();

  const updateMechanicalMinMax = useCallback(
    (
      data,
      key,
      getMechanicalMinMaxList,
      setFormLoading,
      setMechanicalModal
    ) => {
      return new Promise((resolve, reject) => {
        setFormLoading(true);
        fetchWrapper({
          endpoint_url: "configuration/updateMechanicalMinMax",
          params: {
            data: data,
            key: key,
          },
          setLoading: setFormLoading,
          onSuccess: (fetch_output) => {
            if (fetch_output.status) {
              showMessage({ type: "success", message: fetch_output.message });
              setMechanicalModal(false);
              getMechanicalMinMaxList();
            } else {
              showMessage({ type: "warning", message: fetch_output.error });
            }
            resolve(fetch_output);
          },
          onFailure: (error) => {
            showMessage({ message: error });
            reject(error);
          },
          onFetchError: () => {
            showMessage({
              apiType: "fetch",
              message: "update mechanical min max",
            });
          },
          onResponseError: () => {
            showMessage({
              apiType: "response",
              message: "Update Mechanical Min Max",
            });
          },
        });
      });
    },
    [fetchWrapper]
  );

  return { updateMechanicalMinMax };
}

export default useUpdateMechanicalMinMax;
