// library imports
import { useState, useEffect, useContext, useRef } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import {
  Layout,
  Flex,
  Card,
  Popover,
  Avatar,
  Menu,
  Button,
  Row,
  Col,
  theme,
} from "antd";
import { UnorderedListOutlined } from "@ant-design/icons";
// context imports
import { IndexContext } from "../context/IndexContext";
// hook imports
import useNavMenu from "./NavMenu";
// asset imports
import pixevisionLogo from "../assets/companyLogo/pixevision-logo-long-png.png";
import pixevisionDarkLogo from "../assets/companyLogo/pixevision-logo-long-dark.png";
import masteelLogo from "../assets/companyLogo/masteel-logo.png";
// component imports
import ProfileMenu from "./ProfileMenu";
import useAuthCheck from "../hooks/useAuthCheck";

function MainLayout() {
  // create a ref to point to the layout DOM
  const layoutRef = useRef();

  // use context
  const index_context = useContext(IndexContext);
  const { isLightTheme } = index_context;

  // get the token of current theme
  const { token } = theme.useToken();

  // for navigation
  const navigate = useNavigate();
  const location = useLocation();
  // for top menu collapse to sidebar
  const [collapsed, setCollapsed] = useState(true);
  // for getting current selected menu key
  const [menuSelectedKey, setMenuSelectedKey] = useState("ai_rebar_count");

  const [authenticationState, getAuthenticationState] = useAuthCheck();

  // get nav menu
  const [NavMenu, header_height] = useNavMenu(authenticationState);

  const checkAuthentication = () => {
    getAuthenticationState();
  };

  // styles
  const headerStyle = {
    textAlign: "center",
    height: header_height,
    paddingInline: 0,
    lineHeight: header_height,
    position: "sticky",
    top: 0,
    zIndex: 1,
  };

  const contentStyle = {
    height: "calc(100% -" + header_height + " )",
    width: "100vw",
    minWidth: "100vw",
    overflow: "auto",
    padding: 10,
  };

  const layoutStyle = {
    overflow: "hidden",
    padding: 0,
    margin: 0,
    width: "100%",
    height: "100vh",
  };

  useEffect(() => {
    const path = location.pathname.split("/").pop();
    setMenuSelectedKey(path);
  }, [location]);

  return (
    <Layout style={layoutStyle} ref={layoutRef}>
      <Layout.Header style={headerStyle}>
        <Card
          style={{
            height: "100%",
            width: "100%",
            borderRadius: 0,
            margin: 0,
          }}
          styles={{
            body: {
              height: "100%",
              width: "100%",
              borderRadius: 0,
              paddingBlock: 0,
              paddingLeft: 12,
            },
          }}
          bordered={false}
        >
          <Row style={{ height: "100%" }}>
            <Col xl={0} flex="52px">
              <Flex align="center" style={{ height: "100%", width: "100%" }}>
                <Button
                  icon={<UnorderedListOutlined />}
                  type="text"
                  size="large"
                  onClick={() => setCollapsed(!collapsed)}
                />
              </Flex>
            </Col>
            <Col flex="auto" style={{ height: "100%", paddingLeft: 0 }}>
              <Flex
                justify="space-between"
                align="center"
                style={{ height: "100%", width: "100%" }}
              >
                <img
                  src={masteelLogo}
                  alt="PixeVision"
                  style={{
                    height: "80%",
                  }}
                />
                <Row style={{ height: "100%" }}>
                  <Col
                    style={{ height: "100%" }}
                    xs={0}
                    sm={0}
                    md={0}
                    lg={0}
                    xl={24}
                  >
                    <Menu
                      style={{
                        height: "100%",
                        minWidth: 900,
                        borderBottom: 0,
                        alignItems: "center",
                      }}
                      mode="horizontal"
                      items={NavMenu}
                      onClick={(item) => {
                        checkAuthentication();
                        let path = "";
                        item.keyPath.reverse().map((value) => {
                          path = path + "/" + value;
                        });

                        navigate(path);
                      }}
                      onSelect={(item) => setMenuSelectedKey(item.key)}
                      selectedKeys={menuSelectedKey}
                    ></Menu>
                  </Col>
                </Row>
                <Popover
                  placement="bottom"
                  contentStyle={{ marginTop: "50px" }}
                  content={<ProfileMenu />}
                  arrow={false}
                  trigger="hover"
                  overlayInnerStyle={{
                    borderRadius: 0,
                    marginTop: 10,
                    padding: 0,
                  }}
                >
                  <Avatar style={{ backgroundColor: token["blue-6"] }}>
                    DM
                  </Avatar>
                </Popover>
              </Flex>
            </Col>
          </Row>
        </Card>
      </Layout.Header>
      <Layout style={isLightTheme ? { backgroundColor: "#E6F4F1" } : {}}>
        <Layout.Sider
          breakpoint="xl"
          collapsedWidth="0"
          width="300"
          collapsible
          trigger={null}
          collapsed={collapsed}
          onBreakpoint={() => setCollapsed(true)}
        >
          <Menu
            mode="inline"
            inlineIndent={32}
            style={{ height: "100%" }}
            items={NavMenu}
            onClick={(item) => {
              checkAuthentication();
              let path = "";
              item.keyPath.reverse().map((value) => {
                path = path + "/" + value;
              });
              navigate(path);
            }}
            onSelect={(item) => setMenuSelectedKey(item.key)}
            selectedKeys={menuSelectedKey}
          ></Menu>
        </Layout.Sider>
        <Layout.Content style={contentStyle}>
          {/* add layoutRef into the IndexContext */}
          <IndexContext.Provider
            value={{
              ...index_context,
              layoutRef: layoutRef,
              authenticationState: authenticationState,
            }}
          >
            <Outlet></Outlet>
          </IndexContext.Provider>
        </Layout.Content>
      </Layout>
    </Layout>
  );
}

export default MainLayout;
