import { Flex, Tag, Typography } from "antd";
import DataTable from "../common/DataTable";
import FunctionalButton from "../common/FunctionalButton";
import { DeleteOutlined, EditOutlined, EyeOutlined } from "@ant-design/icons";

export default function DemoTable({
  tableData,
  openDetailModal,
  openEditFormModal,
}) {
  const DEMO_COLUMN = [
    {
      title: "No.",
      dataIndex: "number",
      key: "number",
      width: 50,
    },
    {
      title: "DO",
      dataIndex: "do_number",
      key: "do_number",
      width: 120,
    },
    {
      title: "Customer",
      dataIndex: "customer",
      key: "customer",
      width: 120,
    },
    {
      title: "Address",
      dataIndex: "address",
      key: "address",
      width: 150,
      render: (address) => (
        <>
          {address.split("\n").map((line, index) => (
            <Typography.Text key={index}>
              {line}
              <br />
            </Typography.Text>
          ))}
        </>
      ),
    },
    // {
    //   title: "Product",
    //   dataIndex: "product",
    //   key: "product",
    //   width: 100,
    // },
    // {
    //   title: "Specification",
    //   dataIndex: "specification",
    //   key: "specification",
    //   width: 140,
    // },
    // {
    //   title: "Lorry Plate",
    //   dataIndex: "lorry_plate",
    //   key: "lorry_plate",
    //   width: 120,
    // },
    // {
    //   title: "Number of Bundles",
    //   dataIndex: "number_of_bundles",
    //   key: "number_of_bundles",
    //   width: 160,
    // },
    {
      title: "Delivery Date Time",
      dataIndex: "delivery_date_time",
      key: "delivery_date_time",
      width: 120,
    },
    {
      title: "Create Date Time",
      dataIndex: "create_date_time",
      key: "create_date_time",
      width: 160,
    },
    {
      title: "Last Update Date Time",
      dataIndex: "last_update_date_time",
      key: "last_update_date_time",
      width: 180,
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      width: 100,
      render: (_, record) => {
        return (
          <Tag
            color={
              record.status == "Pending"
                ? "yellow"
                : record.status == "Reject"
                ? "error"
                : record.status == "In Progress"
                ? "processing"
                : "success"
            }
            children={record.status}
          />
        );
      },
    },
    {
      title: "Location",
      dataIndex: "location",
      key: "location",
      width: 100,
    },
    {
      title: "Details",
      dataIndex: "details",
      key: "details",
      width: 80,
      render: (_, record) => {
        return (
          <FunctionalButton
            buttonClickFunction={() => openDetailModal(record)}
            type=""
            icon={<EyeOutlined />}
            width="40px"
          />
        );
      },
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      width: 140,
      render: (_, record) => {
        return (
          <Flex gap={"middle"} align="center" justify="center">
            <FunctionalButton
              buttonClickFunction={() => openEditFormModal(record)}
              disabled={
                record.status == "Reject" || record.status == "In Progress"
              }
              icon={<EditOutlined />}
              width="40px"
            />
            {record.status == "Pending" && (
              <FunctionalButton
                buttonClickFunction={() => {}}
                icon={<DeleteOutlined />}
                danger={true}
                width="40px"
              />
            )}
          </Flex>
        );
      },
    },
  ];

  return (
    <DataTable
      table_column={DEMO_COLUMN}
      table_data={tableData}
      columnCenter={true}
    />
  );
}
