import { Flex } from "antd";
import DataTable from "../../common/DataTable";
import FunctionalButton from "../../common/FunctionalButton";
import { useState } from "react";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import dayjs from "dayjs";

export default function ChemicalTable({
  tableData,
  handleEdit,
  handleDelete,
  deleteLoading,
}) {
  const [loadRowKey, setLoadRowKey] = useState(null);
  const CHEMICAL_TABLE_COLUMN = [
    {
      title: "No. ",
      dataIndex: "number",
      key: "number",
      width: 50,
    },
    {
      title: "Heat No",
      dataIndex: "heat_no",
      key: "heat_no",
      width: 100,
    },
    {
      title: "Billet Production Date",
      dataIndex: "billet_production_date",
      key: "billet_production_date",
      width: 160,
    },
    {
      title: "C",
      dataIndex: "carbon",
      key: "carbon",
      width: 80,
      render: (_, record) => {
        return (
          <span
            style={{ color: record.carbon_pass ? "red" : "green" }}
            children={record.carbon}
          />
        );
      },
    },
    {
      title: "Si",
      dataIndex: "silicon",
      key: "silicon",
      width: 80,
      render: (_, record) => {
        return (
          <span
            style={{ color: record.silicon_pass ? "red" : "green" }}
            children={record.silicon}
          />
        );
      },
    },
    {
      title: "Mn",
      dataIndex: "manganese",
      key: "manganese",
      width: 80,
      render: (_, record) => {
        return (
          <span
            style={{ color: record.manganese_pass ? "red" : "green" }}
            children={record.manganese}
          />
        );
      },
    },
    {
      title: "P",
      dataIndex: "phosphorus",
      key: "phosphorus",
      width: 80,
      render: (_, record) => {
        return (
          <span
            style={{
              color: record.phosphorus_pass ? "red" : "green",
            }}
            children={record.phosphorus}
          />
        );
      },
    },
    {
      title: "S",
      dataIndex: "sulphur",
      key: "sulphur",
      width: 80,
      render: (_, record) => {
        return (
          <span
            style={{ color: record.sulphur_pass ? "red" : "green" }}
            children={record.sulphur}
          />
        );
      },
    },
    {
      title: "Cu",
      dataIndex: "copper",
      key: "copper",
      width: 80,
      render: (_, record) => {
        return (
          <span
            style={{ color: record.copper_pass ? "red" : "green" }}
            children={record.copper}
          />
        );
      },
    },
    {
      title: "Ni",
      dataIndex: "nickel",
      key: "nickel",
      width: 80,
      render: (_, record) => {
        return (
          <span
            style={{ color: record.nickel_pass ? "red" : "green" }}
            children={record.nickel}
          />
        );
      },
    },
    {
      title: "Cr",
      dataIndex: "chromium",
      key: "chromium",
      width: 80,
      render: (_, record) => {
        return (
          <span
            style={{ color: record.chromium_pass ? "red" : "green" }}
            children={record.chromium}
          />
        );
      },
    },
    {
      title: "V",
      dataIndex: "vanadium",
      key: "vanadium",
      width: 80,
      render: (_, record) => {
        return (
          <span
            style={{ color: record.vanadium_pass ? "red" : "green" }}
            children={record.vanadium}
          />
        );
      },
    },
    {
      title: "AI",
      dataIndex: "aluminium",
      key: "aluminium",
      width: 80,
      render: (_, record) => {
        return (
          <span
            style={{ color: record.aluminium_pass ? "red" : "green" }}
            children={record.aluminium}
          />
        );
      },
    },
    {
      title: "Mo",
      dataIndex: "molybdenum",
      key: "molybdenum",
      width: 80,
      render: (_, record) => {
        return (
          <span
            style={{
              color: record.molybdenum_pass ? "red" : "green",
            }}
            children={record.molybdenum}
          />
        );
      },
    },
    {
      title: "Sn",
      dataIndex: "tin",
      key: "tin",
      width: 80,
      render: (_, record) => {
        return (
          <span
            style={{ color: record.tin_pass ? "red" : "green" }}
            children={record.tin}
          />
        );
      },
    },
    {
      title: "B",
      dataIndex: "boron",
      key: "boron",
      width: 80,
      render: (_, record) => {
        return (
          <span
            style={{ color: record.boron_pass ? "red" : "green" }}
            children={record.boron}
          />
        );
      },
    },
    {
      title: "N",
      dataIndex: "nitrogen",
      key: "nitrogen",
      width: 80,
      render: (_, record) => {
        return (
          <span
            style={{ color: record.nitrogen_pass ? "red" : "green" }}
            children={record.nitrogen}
          />
        );
      },
    },
    {
      title: "CE",
      dataIndex: "cerium",
      key: "cerium",
      width: 80,
      render: (_, record) => {
        return (
          <span
            style={{ color: record.cerium_pass ? "red" : "green" }}
            children={record.cerium}
          />
        );
      },
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      width: 160,
      render: (_, record) => {
        const updatedRecord = {
          ...record,
          billet_production_date: record.billet_production_date
            ? dayjs(record.billet_production_date, "YYYY-MM-DD")
            : null,
        };
        return (
          <Flex gap="small">
            <FunctionalButton
              buttonClickFunction={() => handleEdit(updatedRecord)}
              type=""
              width="40px"
              icon={<EditOutlined />}
              disabled={loadRowKey == record.key && deleteLoading}
            />
            <FunctionalButton
              buttonClickFunction={() => {
                handleDelete(record.key);
                setLoadRowKey(record.key);
              }}
              show_confirmation={true}
              confirmation_message={{
                title: "Confirmation",
                content: "Sure to delete this material properties?",
              }}
              danger={true}
              width="40px"
              icon={<DeleteOutlined />}
              trigger_loading={loadRowKey == record.key && deleteLoading}
            />
          </Flex>
        );
      },
    },
  ];

  return (
    <DataTable
      table_column={CHEMICAL_TABLE_COLUMN}
      table_data={tableData}
      pageSize={10}
      columnCenter={true}
    />
  );
}
