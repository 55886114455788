import { useCallback, useState } from "react";
import useFetch from "../../hooks/useFetch";
import { useNavigate } from "react-router-dom";
import {
  operator,
  supervisor,
  account,
  qcOperator,
  demo,
} from "../../configs/configs.js";
import { useShowMessage } from "../Common/showMessage.js";

function useLogin() {
  const navigate = useNavigate();
  const [fetchWrapper] = useFetch();
  const [loginProcessing, setLoginProcessing] = useState(false);

  const showMessage = useShowMessage();

  const login = useCallback(
    (user_inputs, fingerprint) => {
      return new Promise((resolve, reject) => {
        fetchWrapper({
          endpoint_url: "user_management/loginUser",
          params: {
            username: user_inputs.username,
            password: user_inputs.password,
            stage: user_inputs.stage,
            fingerprint: fingerprint,
          },
          onSuccess: (fetch_output) => {
            if (fetch_output.login_status === true) {
              // login success
              showMessage({ type: "success", message: "Login Success!" });

              setTimeout(() => {
                setLoginProcessing(false);
                if (
                  fetch_output.type == operator ||
                  fetch_output.type == supervisor
                ) {
                  navigate("/LoginRecord", { replace: true });
                } else if (fetch_output.type == account) {
                  navigate("/Digital_Mill_Cert/Delivery_Order", {
                    replace: true,
                  });
                } else if (fetch_output.type == qcOperator) {
                  navigate("/Digital_Mill_Cert/Material_Properties", {
                    replace: true,
                  });
                } else if (fetch_output.type == demo) {
                  navigate("/Demo/Demo_Calendar", { replace: true });
                } else {
                  navigate("/Analytics/Dashboard", { replace: true });
                } //replace this so back wont go back to login page
              }, 2000);
              localStorage.removeItem("delivery_order_id");
            } else {
              // incorrect credential
              showMessage({ type: "warning", message: fetch_output.error });
              setLoginProcessing(false);
            }
            resolve(fetch_output);
          },
          onFailure: (error) => {
            showMessage({ message: error });
            reject(error);
          },
          onFetchError: () => {
            showMessage({ apiType: "fetch", message: "login" });
          },
          onResponseError: () => {
            showMessage({ apiType: "response", message: "User Login" });
          },
        });
      });
    },
    [fetchWrapper]
  );

  return { login, loginProcessing, setLoginProcessing };
}

export default useLogin;
