import { useCallback, useState } from "react";
import useFetch from "../../../hooks/useFetch";
import { useShowMessage } from "../../Common/showMessage";

function useAddTagID() {
  const [fetchWrapper] = useFetch();
  const [tagId, setTagId] = useState(null);
  const [tagIdLoading, setTagIdLoading] = useState(false);

  const showMessage = useShowMessage();

  const addTagID = useCallback(
    (tagIdData, setState, resetQrScanner) => {
      return new Promise((resolve, reject) => {
        setTagIdLoading(true);
        fetchWrapper({
          endpoint_url: "ai_rebar_count/addTagID",
          params: {
            tag_id_data: tagIdData,
          },
          setLoading: setTagIdLoading,
          onSuccess: (fetch_output) => {
            if (fetch_output.status) {
              if (fetch_output.verify) {
                setTagId({ key: fetch_output.key, value: fetch_output.tag_id });
                setState("rebarState");
              }
              if (fetch_output.rework) {
                showMessage({
                  type: "success",
                  message: "This bundle will be rework stage",
                });
              } else {
                showMessage({ type: "success", message: fetch_output.message });
              }
              resetQrScanner();
            } else {
              showMessage({ type: "warning", message: fetch_output.error });
            }
            resolve(fetch_output);
          },
          onFailure: (error) => {
            showMessage({ message: error });
            reject(error);
          },
          onFetchError: () => {
            showMessage({ apiType: "fetch", message: "add Tag ID" });
          },
          onResponseError: () => {
            showMessage({ apiType: "response", message: "Add Tag ID" });
          },
        });
      });
    },
    [fetchWrapper]
  );

  return { addTagID, tagId, setTagId, tagIdLoading };
}

export default useAddTagID;
