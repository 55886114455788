import { useCallback } from "react";
import useFetch from "../../../hooks/useFetch";
import useDownloadFile from "../../DownloadFile/downloadFile";
import { useShowMessage } from "../../Common/showMessage";

function useDownloadProduction() {
  const [fetchWrapper] = useFetch();
  const { downloadFile } = useDownloadFile();

  const showMessage = useShowMessage();

  const downloadProduction = useCallback(
    (download_data, setDownloadLoading) => {
      setDownloadLoading(true);
      return new Promise((resolve, reject) => {
        fetchWrapper({
          endpoint_url: "statistics/downloadProduction",
          params: {
            download: download_data,
          },
          setLoading: setDownloadLoading,
          onSuccess: (fetch_output) => {
            if (fetch_output.status) {
              downloadFile(fetch_output.folder_path, fetch_output.file_name);
            } else {
              showMessage({ type: "warning", message: fetch_output.error });
            }
            resolve(fetch_output);
          },
          onFailure: (error) => {
            showMessage({ message: error });
            reject(error);
          },
          onFetchError: () => {
            showMessage({
              apiType: "fetch",
              message: "download production excel",
            });
          },
          onResponseError: () => {
            showMessage({
              apiType: "response",
              message: "Download Production Excel",
            });
          },
        });
      });
    },
    [fetchWrapper]
  );

  return { downloadProduction };
}

export default useDownloadProduction;
