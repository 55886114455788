import { useEffect, useState } from "react";
import useFetch from "../hooks/useFetch";
import { useNavigate } from "react-router-dom";

export default function useAuthCheck() {
  const [fetchWrapper] = useFetch();

  // for navigation
  const navigate = useNavigate();

  // for getting authentication state
  const [authenticationState, setAuthenticationState] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  function getAuthenticationState() {
    setIsLoading(true);
    fetchWrapper({
      endpoint_url: "user_management/checkUser",
      setLoading: setIsLoading,
      onSuccess: (response) => {
        setAuthenticationState({ ...authenticationState, ...response });
      },

      onResponseError: (response) => {
        setAuthenticationState(response);
        navigate("/Login", { replace: true });
        localStorage.removeItem("delivery_order_id");
      },

      onFetchError: (response) => {
        setAuthenticationState(response);
        navigate("/Login", { replace: true });
        localStorage.removeItem("delivery_order_id");
      },
    });
  }

  useEffect(() => {
    getAuthenticationState();
  }, []);

  return [authenticationState, getAuthenticationState, isLoading];
}
