import { Card, Col, Flex, Row } from "antd";
import DailyStatusCard from "../../components/bundle_list/daily_summary/DailyStatusCard";
import DailyBundleTable from "../../components/bundle_list/daily_summary/DailyBundleTable";
import { useEffect, useState } from "react";
import useGetDailyBundle from "../../functions/BundleList/daily_summary/getDailyBundle";
import MissingBundleModal from "../../components/bundle_list/daily_summary/MissingBundleModal";
import DailyBundleSearchForm from "../../components/bundle_list/daily_summary/DailyBundleSearchForm";
import CustomRangePicker from "../../components/common/CustomRangePicker";
import CustomDownloadExcelButton from "../../components/common/CustomDownloadExcelButton";
import FunctionalButton from "../../components/common/FunctionalButton";
import {
  BarChartOutlined,
  CheckCircleOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";

const SUMMARY_TYPE = [
  {
    title: "expected_bundles",
    prefix: "bundles",
    icon: <CheckCircleOutlined />,
  },
  { title: "actual_bundles", prefix: "bundles", icon: <CheckCircleOutlined /> },
  {
    title: "missing_bundles",
    prefix: "bundles",
    icon: <ExclamationCircleOutlined />,
  },
  { title: "expected_weight", prefix: "tonnes", icon: <BarChartOutlined /> },
  { title: "actual_weight", prefix: "tonnes", icon: <BarChartOutlined /> },
  {
    title: "missing_weight",
    prefix: "tonnes",
    icon: <ExclamationCircleOutlined />,
  },
];

const EXCEL_HEADER = {
  date: "Date",
  factory: "Factory",
  product: "Product",
  type: "Type",
  min_sequence: "Min Sequence",
  max_sequence: "Max Sequence",
  expected_bundles: "Expected Bundles",
  actual_bundles: "Actual Bundles",
  missing_bundles: "Missing Bundles",
  expected_weight: "Expected Weight (Tonnes)",
  actual_weight: "Actual Weight (Tonnes)",
  missing_weight: "Missing Weight (Tonnes)",
};

export default function DailySummaryPage() {
  const [selectDate, setSelectDate] = useState([]);
  const [missingModal, setMissingModal] = useState(false);
  const [missingDetails, setMissingDetails] = useState({
    data: null,
    name: null,
  });
  const [filterDailyList, setFilterDailyList] = useState([]);
  const [searchModal, setSearchModal] = useState(false);

  const { getDailyBundle, dailyList, dailyStatus, dailyLoading } =
    useGetDailyBundle();

  const onDateChange = (value) => {
    setSelectDate(value);
  };

  const onDetailsClick = (value, name) => {
    setMissingDetails({ data: value, name: name });
    setMissingModal(true);
  };

  const onModalClose = () => {
    setMissingDetails({ data: null, name: null });
    setMissingModal(false);
  };

  const resetFilter = () => {
    setFilterDailyList(dailyList);
  };

  useEffect(() => {
    if (selectDate.length > 0) {
      getDailyBundle(selectDate[0], selectDate[1]);
    }
  }, [selectDate]);

  useEffect(() => {
    setFilterDailyList(dailyList);
  }, [dailyList]);

  return (
    <>
      <Flex vertical="vertical" style={{ height: "100%" }} gap={"small"}>
        <Row gutter={[12, 12]}>
          {SUMMARY_TYPE.map((value) => {
            return (
              <Col xs={24} sm={12} md={12} lg={8} xl={8} xxl={4}>
                <DailyStatusCard
                  title={value.title}
                  content={dailyStatus?.[value.title]}
                  percentage={dailyStatus?.[`${value.title}_percentage`]}
                  prefix={value.prefix}
                  loading={dailyLoading}
                  icon={value.icon}
                />
              </Col>
            );
          })}
        </Row>
        <Row style={{ height: "100%" }}>
          <Col span={24}>
            <Card
              style={{ height: "100%" }}
              title={"Daily Bundles Table"}
              extra={
                <Flex gap={"middle"} align="center" wrap>
                  <CustomRangePicker onDateChange={onDateChange} />
                  <CustomDownloadExcelButton
                    fileName={"Daily Summary"}
                    dataList={dailyList}
                    excelHeader={EXCEL_HEADER}
                  />
                  <FunctionalButton
                    buttonClickFunction={resetFilter}
                    children={"Reset"}
                    type=""
                    disabled={dailyLoading}
                  />
                  <FunctionalButton
                    buttonClickFunction={() => setSearchModal(true)}
                    children={"Advanced Search"}
                    disabled={dailyLoading}
                  />
                </Flex>
              }
              loading={dailyLoading}
            >
              <DailyBundleTable
                onDateChange={onDateChange}
                onDetailsClick={onDetailsClick}
                setSearchModal={setSearchModal}
                resetFilter={resetFilter}
                tableData={filterDailyList}
                loading={dailyLoading}
              />
            </Card>
          </Col>
        </Row>
      </Flex>
      <MissingBundleModal
        modalOpen={missingModal}
        missingDetails={missingDetails}
        onModalClose={onModalClose}
      />
      <DailyBundleSearchForm
        modalOpen={searchModal}
        setModalOpen={setSearchModal}
        tableData={dailyList}
        setFilterTable={setFilterDailyList}
      />
    </>
  );
}
