import { useContext } from "react";
import { IndexContext } from "../../context/IndexContext";
import { Descriptions } from "antd";

export default function CustomDescription({
  title,
  titleStrong = true,
  items,
  labelFontSize = "16px",
  layout = "horizontal",
  contentFontSize = "16px",
  column = 3,
  style = {},
}) {
  const { isLightTheme } = useContext(IndexContext);

  const processedItems = items.map((item) => ({
    ...item,
    label: titleStrong ? <strong>{item.label}</strong> : item.label,
    labelStyle: {
      fontSize: labelFontSize,
      color: isLightTheme ? "black" : "white",
    },
    children: item.children ? item.children : "---",
    contentStyle: {
      ...item.contentStyle,
      fontSize: contentFontSize,
      marginBottom: "10px",
    },
  }));

  return (
    <Descriptions
      title={title}
      items={processedItems}
      layout={layout}
      style={style}
      column={column}
    />
  );
}
