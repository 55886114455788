import DataTable from "../../common/DataTable";
import { Tag } from "antd";
import { CheckCircleOutlined, CloseCircleOutlined } from "@ant-design/icons";
import { useTableSearch } from "../../common/TableSearch";

export default function MissingBundleTable({ tableData }) {
  const { getColumnSearchProps } = useTableSearch();

  const MISSING_COLUMN = [
    {
      title: "No.",
      dataIndex: "number",
      key: "number",
      width: 40,
    },
    {
      title: "Tag ID",
      dataIndex: "TagID",
      key: "TagID",
      width: 140,
      ...getColumnSearchProps("TagID"),
    },
    {
      title: "Capture By",
      dataIndex: "insert_by",
      key: "insert_by",
      width: 100,
      ...getColumnSearchProps("insert_by"),
    },
    {
      title: "Capture Date Time",
      dataIndex: "insert_date_time",
      key: "insert_date_time",
      width: 160,
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      width: 120,
      filters: [
        {
          text: "Success",
          value: true,
        },
        {
          text: "Missing",
          value: false,
        },
      ],
      onFilter: (value, record) => record.status == value,
      render: (_, record) => {
        return record.status ? (
          <Tag icon={<CheckCircleOutlined />} color="success">
            Success
          </Tag>
        ) : (
          <Tag icon={<CloseCircleOutlined />} color="error">
            Missing
          </Tag>
        );
      },
    },
  ];

  return (
    <DataTable
      table_data={tableData}
      table_column={MISSING_COLUMN}
      tableWidth={0}
    />
  );
}
