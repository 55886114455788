import { useCallback } from "react";
import useFetch from "../../hooks/useFetch";
import { useNavigate } from "react-router-dom";
import { useShowMessage } from "../Common/showMessage";

function useCheckDeliveryOrderConnection() {
  const navigate = useNavigate();
  const [fetchWrapper] = useFetch();

  const showMessage = useShowMessage();

  const checkDeliveryOrderConnection = useCallback(() => {
    fetchWrapper({
      endpoint_url: "delivery_order_connection/checkDeliveryOrderConnection",
      onSuccess: (fetch_output) => {
        if (fetch_output.status) {
          navigate("/Ai_Rebar_Count", { replace: true });
        } else {
          showMessage({ type: "warning", message: fetch_output.error });
        }
      },
      onFetchError: () => {
        showMessage({
          apiType: "fetch",
          message: "check delivery order connection",
        });
      },
      onResponseError: () => {
        showMessage({
          apiType: "response",
          message: "Check Delivery Order Connection",
        });
      },
    });
  }, [fetchWrapper]);

  return { checkDeliveryOrderConnection };
}

export default useCheckDeliveryOrderConnection;
