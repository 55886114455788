import { useCallback, useState } from "react";
import useFetch from "../../../hooks/useFetch";
import { useShowMessage } from "../../Common/showMessage";

function useGetCustomerList() {
  const [fetchWrapper] = useFetch();
  const [customerList, setCustomerList] = useState([]);
  const [customerLoading, setCustomerLoading] = useState(false);
  const showMessage = useShowMessage();

  const getCustomerList = useCallback(() => {
    setCustomerLoading(true);
    fetchWrapper({
      endpoint_url: "configuration/getCustomerList",
      setLoading: setCustomerLoading,
      onSuccess: (fetch_output) => {
        if (fetch_output.status) {
          setCustomerList(fetch_output.data);
        } else {
          showMessage({ type: "warning", message: fetch_output.error });
        }
      },
      onFetchError: () => {
        showMessage({ apiType: "fetch", message: "get customer list" });
      },
      onResponseError: () => {
        showMessage({ apiType: "response", message: "Get Customer List" });
      },
    });
  }, [fetchWrapper]);

  return { getCustomerList, customerList, customerLoading };
}

export default useGetCustomerList;
