// since react doesnt suppotr dynamic import, loads all dev at once
import { DEV_CONFIGS } from "./dev_configs";
import { PROD_CONFIGS } from "./prod_configs";
import { STAG_CONFIGS } from "./stag_configs";
import { DEMO_CONFIGS } from "./demo_configs";

// currently support 4 modes
// DEV: development
// PROD: production
// STAG: staging
// DEMO: demo
const ENV_MODE = "PROD";

let ENV_CONFIGS = null;
switch (ENV_MODE) {
  case "DEV":
    ENV_CONFIGS = DEV_CONFIGS;
    break;
  case "PROD":
    ENV_CONFIGS = PROD_CONFIGS;
    break;
  case "STAG":
    ENV_CONFIGS = STAG_CONFIGS;
    break;
  case "DEMO":
    ENV_CONFIGS = DEMO_CONFIGS;
    break;
  default:
    break;
}

// environment specific constants/settings will be at their respective files.
// only the common configs that are the same acrosss different environment will be listed below

// get the backend url
let current_url = "";
if (window.location.href.split(":").length == 2) {
  //frontend url has no port number
  current_url = window.location.href.split("/").slice(0, 3);
  current_url[current_url.length - 1] += ENV_CONFIGS.backend_port;
  current_url = current_url.join("/");
} else {
  current_url =
    window.location.href.split(":").slice(0, 2).join(":") +
    ENV_CONFIGS.backend_port;
}

// all configs, including both common configs and env configs
export const CONFIGS = {
  refresh_token_url: "user_management/refreshUser",
  backend_url: current_url + ENV_CONFIGS.backend_url_path,
  ...ENV_CONFIGS,
};

export const operator = 4;
export const supervisor = 3;
export const manager = 2;
export const account = 5;
export const qcOperator = 6;
export const demo = 7;

export const MAX_RETRIES = 3;
