import {
  DeleteOutlined,
  EditOutlined,
  UserDeleteOutlined,
} from "@ant-design/icons";
import { Button, Flex } from "antd";
import DataTable from "../../common/DataTable";
import { useState } from "react";
import FunctionalButton from "../../common/FunctionalButton";

export default function UserManagementTable({
  tableData,
  handleEdit,
  handleDelete,
  deleteLoading,
}) {
  const [loadRowKey, setLoadRowKey] = useState(null);
  const USER_TABLE_COLUMN = [
    {
      title: "No.",
      dataIndex: "number",
      key: "number",
      width: 80,
    },
    {
      title: "Username",
      dataIndex: "username",
      key: "username",
      width: 140,
    },
    {
      title: "User Type",
      dataIndex: "user_type",
      key: "user_type",
      width: 140,
    },
    {
      title: "Factory",
      dataIndex: "factory",
      key: "factory",
      width: 120,
    },
    {
      title: "Created By",
      dataIndex: "created_by",
      key: "created_by",
      width: 140,
    },
    {
      title: "Create Date Time",
      dataIndex: "create_date_time",
      key: "create_date_time",
      width: 200,
    },
    {
      title: "Last Update By",
      dataIndex: "last_update_by",
      key: "last_update_by",
      width: 160,
    },
    {
      title: "Last Update Date Time",
      dataIndex: "last_update_date_time",
      key: "last_update_date_time",
      width: 200,
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      width: 140,
      render: (_, record) => {
        return (
          <Flex gap="small">
            {record.is_removed && (
              <Button icon={<UserDeleteOutlined />} danger type="link" />
            )}

            <FunctionalButton
              buttonClickFunction={() => handleEdit(record.action)}
              type=""
              width="40px"
              icon={<EditOutlined />}
              disabled={
                (loadRowKey == record.key && deleteLoading) || record.is_removed
              }
            />
            {!record.disabled && (
              <FunctionalButton
                buttonClickFunction={() => {
                  handleDelete(record.key);
                  setLoadRowKey(record.key);
                }}
                show_confirmation={true}
                confirmation_message={{
                  title: "Confirmation",
                  content: "Sure to delete this user?",
                }}
                danger={true}
                width="40px"
                icon={<DeleteOutlined />}
                trigger_loading={loadRowKey == record.key && deleteLoading}
                disabled={record.is_removed}
              />
            )}
          </Flex>
        );
      },
    },
  ];

  return (
    <DataTable
      table_column={USER_TABLE_COLUMN}
      table_data={tableData}
      pageSize={10}
      columnCenter={true}
    />
  );
}
