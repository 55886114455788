import CustomColumnChart from "../../chart/CustomColumnChart";

export default function ProductionChart({ chartData }) {
  return (
    <CustomColumnChart
      data={chartData}
      isGroup={true}
      seriesField={"type"}
      xField={"time"}
      xAxis={"Date"}
      yAxis={"Quantity"}
      chartSize={"45vh"}
    />
  );
}
