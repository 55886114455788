import { useCallback, useState } from "react";
import useFetch from "../../hooks/useFetch";
import { useShowMessage } from "../Common/showMessage";

function useUpdateDOProgress() {
  const [fetchWrapper] = useFetch();
  const [updateLoading, setUpdateLoading] = useState(false);

  const showMessage = useShowMessage();

  const updateDOProgress = useCallback(
    (key, handleDOProgressList) => {
      return new Promise((resolve, reject) => {
        setUpdateLoading(true);
        fetchWrapper({
          endpoint_url: "do_progress/updateDOProgress",
          params: {
            key: key,
          },
          setLoading: setUpdateLoading,
          onSuccess: (fetch_output) => {
            if (fetch_output.status) {
              showMessage({ type: "success", message: fetch_output.message });
              handleDOProgressList();
            } else {
              showMessage({ type: "warning", message: fetch_output.error });
            }
            resolve(fetch_output);
          },
          onFailure: (error) => {
            showMessage({ message: error });
            reject(error);
          },
          onFetchError: () => {
            showMessage({
              apiType: "fetch",
              message: "update delivery order progress",
            });
          },
          onResponseError: () => {
            showMessage({
              apiType: "response",
              message: "Update Delivery Order Progress",
            });
          },
        });
      });
    },
    [fetchWrapper]
  );
  return { updateDOProgress, updateLoading };
}

export default useUpdateDOProgress;
