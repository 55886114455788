import { Flex } from "antd";

export default function CustomCardTitle({ leftTitle, rightTitle }) {
  return (
    <Flex justify="space-between" align="center" wrap="wrap" gap="small">
      {leftTitle}
      <Flex gap="small" wrap="wrap">
        {rightTitle}
      </Flex>
    </Flex>
  );
}
