import { useCallback, useContext, useState } from "react";
import useFetch from "../../../hooks/useFetch";
import { IndexContext } from "../../../context/IndexContext";
import { useShowMessage } from "../../Common/showMessage";

function useGetBarMarkList() {
  const [fetchWrapper] = useFetch();
  const { messageApi } = useContext(IndexContext);
  const [barMarkList, setBarMarkList] = useState([]);
  const [barMarkLoading, setBarMarkLoading] = useState(false);

  const showMessage = useShowMessage();

  const getBarMarkList = useCallback(() => {
    setBarMarkLoading(true);
    fetchWrapper({
      endpoint_url: "configuration/getBarMarkList",
      setLoading: setBarMarkLoading,
      onSuccess: (fetch_output) => {
        if (fetch_output.status) {
          setBarMarkList(fetch_output.data);
        } else {
          showMessage({ type: "warning", message: fetch_output.error });
        }
      },
      onFetchError: () => {
        showMessage({ apiType: "fetch", message: "get bar mark list" });
      },
      onResponseError: () => {
        showMessage({ apiType: "response", message: "Get Bar Mark List" });
      },
    });
  }, [fetchWrapper]);

  return { getBarMarkList, barMarkList, barMarkLoading };
}

export default useGetBarMarkList;
