import { useCallback } from "react";
import useFetch from "../../../hooks/useFetch";
import { useShowMessage } from "../../Common/showMessage";

function useUpdateUser() {
  const [fetchWrapper] = useFetch();

  const showMessage = useShowMessage();

  const updateUser = useCallback(
    (data, key, getUserList, setFormLoading, setUserModal) => {
      return new Promise((resolve, reject) => {
        setFormLoading(true);
        fetchWrapper({
          endpoint_url: "configuration/updateUser",
          params: {
            data: data,
            key: key,
          },
          setLoading: setFormLoading,
          onSuccess: (fetch_output) => {
            if (fetch_output.status) {
              showMessage({ type: "success", message: fetch_output.message });
              setUserModal(false);
              getUserList();
            } else {
              showMessage({ type: "warning", message: fetch_output.error });
            }
            resolve(fetch_output);
          },
          onFailure: (error) => {
            showMessage({ message: error });
            reject(error);
          },
          onFetchError: () => {
            showMessage({ apiType: "fetch", message: "update user" });
          },
          onResponseError: () => {
            showMessage({ apiType: "reponse", message: "Update User" });
          },
        });
      });
    },
    [fetchWrapper]
  );
  return { updateUser };
}

export default useUpdateUser;
