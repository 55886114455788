import { useCallback, useState } from "react";
import useFetch from "../../../hooks/useFetch";
import { useShowMessage } from "../../Common/showMessage";

function useVerifyDeliveryOrder() {
  const [fetchtWrapper] = useFetch();
  const [navigateResultType, setNavigateResultType] = useState(null);
  const [tagId, setTagId] = useState(null);
  const [tagIdLoading, setTagIdLoading] = useState(false);

  const showMessage = useShowMessage();

  const verifyDeliveryOrder = useCallback(
    (setNavigateModal) => {
      setTagIdLoading(true);
      fetchtWrapper({
        endpoint_url: "ai_rebar_count/verifyDeliveryOrder",
        onSuccess: (fetch_output) => {
          if (!fetch_output.status) {
            if ("result_type" in fetch_output) {
              setNavigateResultType(fetch_output.result_type);
              setNavigateModal(true);
            } else {
              showMessage({ type: "warning", message: fetch_output.error });
            }
            setTagIdLoading(false);
          }
        },
        onFetchError: () => {
          showMessage({ apiType: "fetch", message: "verify delivery order" });
          setTagIdLoading(false);
        },
        onResponseError: () => {
          showMessage({
            apiType: "response",
            message: "Verify Delivery Order",
          });
          setTagIdLoading(false);
        },
      });
    },
    [fetchtWrapper]
  );

  return {
    verifyDeliveryOrder,
    tagId,
    setTagId,
    tagIdLoading,
    navigateResultType,
  };
}

export default useVerifyDeliveryOrder;
