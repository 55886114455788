import useFetch from "../../../hooks/useFetch";
import { useCallback } from "react";
import { useShowMessage } from "../../Common/showMessage";

function useUpdateChemicalMinMax() {
  const [fetchWrapper] = useFetch();

  const showMessage = useShowMessage();

  const updateChemicalMinMax = useCallback(
    (data, key, getChemicalMinMaxList, setFormLoading, setChemicalModal) => {
      return new Promise((resolve, reject) => {
        setFormLoading(true);
        fetchWrapper({
          endpoint_url: "configuration/updateChemicalMinMax",
          params: {
            data: data,
            key: key,
          },
          setLoading: setFormLoading,
          onSuccess: (fetch_output) => {
            if (fetch_output.status) {
              showMessage({ type: "success", message: fetch_output.message });
              setChemicalModal(false);
              getChemicalMinMaxList();
            } else {
              showMessage({ type: "warning", message: fetch_output.error });
            }
            resolve(fetch_output);
          },
          onFailure: (error) => {
            showMessage({ message: error });
            reject(error);
          },
          onFetchError: () => {
            showMessage({
              apiType: "fetch",
              message: "update chemical min max",
            });
          },
          onResponseError: () => {
            showMessage({
              apiType: "reponse",
              message: "Update Chemical Min Max",
            });
          },
        });
      });
    },
    [fetchWrapper]
  );

  return { updateChemicalMinMax };
}

export default useUpdateChemicalMinMax;
