import DataTable from "../../common/DataTable";

export default function StatisticWIPTable({ tableData, tableColumn }) {
  return (
    <DataTable
      table_column={tableColumn}
      table_data={tableData}
      paginationClose={true}
      columnCenter={true}
    />
  );
}
