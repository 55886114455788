import useFetch from "../../hooks/useFetch";
import { useCallback, useState } from "react";
import { useShowMessage } from "../Common/showMessage";

function useInsertDeliverOrder() {
  const [fetchWrapper] = useFetch();
  const [insertLoading, setInsertLoading] = useState(false);

  const showMessage = useShowMessage();

  const insertDeliverOrder = useCallback(
    (data, handleDeliveryOrderList, setMillCertModal) => {
      return new Promise((resolve, reject) => {
        setInsertLoading(true);
        fetchWrapper({
          endpoint_url: "delivery_order/insertDeliveryOrder",
          params: {
            data: data,
          },
          setLoading: setInsertLoading,
          onSuccess: (fetch_output) => {
            if (fetch_output.status) {
              showMessage({ type: "success", message: fetch_output.message });
              setMillCertModal(false);
              handleDeliveryOrderList();
            } else {
              showMessage({ type: "warning", message: fetch_output.error });
            }
            resolve(fetch_output);
          },
          onFailure: (error) => {
            showMessage({ message: error });
            reject(error);
          },
          onFetchError: () => {
            showMessage({ apiType: "fetch", message: "insert deliver order" });
          },
          onResponseError: () => {
            showMessage({
              apiType: "response",
              message: "Insert Deliver Order",
            });
          },
        });
      });
    },
    [fetchWrapper]
  );

  return { insertDeliverOrder, insertLoading };
}

export default useInsertDeliverOrder;
