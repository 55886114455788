import { useCallback } from "react";
import useFetch from "../../../hooks/useFetch";
import { useShowMessage } from "../../Common/showMessage";

function useUpdateWeightMinMax() {
  const [fetchWrapper] = useFetch();

  const showMessage = useShowMessage();

  const updateWeightMinMax = useCallback(
    (data, key, getWeightMinMaxList, setFormLoading, setWeightModal) => {
      return new Promise((resolve, reject) => {
        setFormLoading(true);
        fetchWrapper({
          endpoint_url: "configuration/updateWeightMinMax",
          params: {
            data: data,
            key: key,
          },
          setLoading: setFormLoading,
          onSuccess: (fetch_output) => {
            if (fetch_output.status) {
              showMessage({ type: "success", message: fetch_output.message });
              setWeightModal(false);
              getWeightMinMaxList();
            } else {
              showMessage({ type: "warning", message: fetch_output.error });
            }
            resolve(fetch_output);
          },
          onFailure: (error) => {
            showMessage({ message: error });
            reject(error);
          },
          onFetchError: () => {
            showMessage({ apiType: "fetch", message: "update weight min max" });
          },
          onResponseError: () => {
            showMessage({
              apiType: "response",
              message: "Update Weight Min Max",
            });
          },
        });
      });
    },
    [fetchWrapper]
  );

  return { updateWeightMinMax };
}

export default useUpdateWeightMinMax;
