import { Card, Col, Row, Typography } from "antd";
import { use, useState } from "react";
import CustomCardTitle from "../../components/common/CustomCardTitle";
import DemoSecurityTable from "../../components/demo/DemoSecurityTable";
import DemoLorrySummary from "../../components/demo/DemoLorrySummary";
import CustomRangePicker from "../../components/common/CustomRangePicker";

const DUMMY_DATA = [
  {
    lorry_plate: "PHD 6366",
    delivery_order_list: [],
    delivery_date: "2024-12-27",
    checked_in_date_time: "-",
    checked_out_date_time: "-",
    check_in: false,
    check_out: false,
  },
  {
    lorry_plate: "IRU 1293",
    delivery_order_list: [],
    delivery_date: "2024-12-27",
    checked_in_date_time: "2024-12-27 13:59:57",
    checked_out_date_time: "-",
    check_in: true,
    check_out: false,
  },
  {
    lorry_plate: "KPI 3948",
    delivery_order_list: [],
    delivery_date: "2024-12-25",
    checked_in_date_time: "2024-12-25 09:24:29",
    checked_out_date_time: "2024-12-25 11:39:58",
    check_in: true,
    check_out: true,
  },
];

export default function DemoSecurityPage() {
  const [securityModal, setSecurityModal] = useState(false);
  const [securityDetails, setSecurityDetails] = useState(null);
  const [dateValue, setDateValue] = useState(null);

  const onDateChange = (value) => setDateValue(value);

  return (
    <>
      <Row gutter={[8, 8]} style={{ height: "100%" }}>
        <Col
          span={24}
          children={<DemoLorrySummary />}
          style={{ height: "10%" }}
        />
        <Col
          span={24}
          children={
            <Card
              className="context-card"
              title={
                <CustomCardTitle
                  leftTitle={<Typography.Text children={"Security Table"} />}
                  rightTitle={<CustomRangePicker onDateChange={onDateChange} />}
                />
              }
            >
              <DemoSecurityTable tableData={DUMMY_DATA} />
            </Card>
          }
          style={{ height: "90%" }}
        />
      </Row>
    </>
  );
}
