import useFetch from "../../hooks/useFetch";
import { useCallback, useState } from "react";
import { useShowMessage } from "../Common/showMessage";

function useGetDeliverOrderList() {
  const [fetchWrapper] = useFetch();
  const [deliverOrderList, setDeliverOrderList] = useState([]);
  const [tableLoading, setTableLoading] = useState(false);

  const showMessage = useShowMessage();

  const getDeliverOrderList = useCallback(
    (startDateSelection, endDateSelection) => {
      setTableLoading(true);
      fetchWrapper({
        endpoint_url: "delivery_order/getDeliveryOrderList",
        params: {
          startDate: startDateSelection,
          endDate: endDateSelection,
        },
        setLoading: setTableLoading,
        onSuccess: (fetch_output) => {
          if (fetch_output.status) {
            setDeliverOrderList(fetch_output.data);
          } else {
            showMessage({ type: "warning", message: fetch_output.error });
          }
        },
        onFetchError: () => {
          showMessage({ apiType: "fetch", message: "get delivery order list" });
        },
        onResponseError: () => {
          showMessage({
            apiType: "response",
            message: "Get Delivery Order List",
          });
        },
      });
    },
    [fetchWrapper]
  );

  return { getDeliverOrderList, deliverOrderList, tableLoading };
}

export default useGetDeliverOrderList;
