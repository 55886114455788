import { useCallback, useEffect, useState } from "react";
import useFetch from "../../hooks/useFetch";
import { useShowMessage } from "../Common/showMessage";
import useRetry from "../../hooks/useRetry";
import { MAX_RETRIES } from "../../configs/configs";

function useGetProductTypeOption() {
  const [fetchWrapper] = useFetch();
  const [typeOption, setTypeOption] = useState([]);
  const [typeSelect, setTypeSelect] = useState(null);

  const showMessage = useShowMessage();
  const { retryCount, setRetryCount } = useRetry(() => getProductTypeOption());

  const getProductTypeOption = useCallback(() => {
    fetchWrapper({
      endpoint_url: "options/getProductTypeOption",
      onSuccess: (fetch_output) => {
        if (fetch_output.status) {
          setTypeOption(fetch_output.options);
          setTypeSelect([fetch_output.options[0].value]);
          setRetryCount(0);
        } else {
          showMessage({ type: "warning", message: fetch_output.error });
        }
      },
      onFetchError: () => {
        if (retryCount === MAX_RETRIES)
          showMessage({ apiType: "fetch", message: "get factory option" });

        setRetryCount((prev) => prev + 1);
      },
      onResponseError: () => {
        if (retryCount === MAX_RETRIES)
          showMessage({ apiType: "response", message: "Get Factory Option" });

        setRetryCount((prev) => prev + 1);
      },
    });
  }, [fetchWrapper]);

  useEffect(() => {
    getProductTypeOption();
  }, []);

  return { typeOption, typeSelect, setTypeSelect };
}

export default useGetProductTypeOption;
