import { useCallback, useState } from "react";
import useFetch from "../../../hooks/useFetch";
import { useShowMessage } from "../../Common/showMessage";

function useGetAllChemicalComposition() {
  const [fetchWrapper] = useFetch();
  const [chemicalComposition, setChemicalComposition] = useState([]);
  const [chemicalLoading, setChemicalLoading] = useState(false);

  const showMessage = useShowMessage();

  const getAllChemicalComposition = useCallback(
    (startDateSelection, endDateSelection) => {
      setChemicalLoading(true);
      fetchWrapper({
        endpoint_url: "material_properties/getAllChemicalComposition",
        params: {
          startDate: startDateSelection,
          endDate: endDateSelection,
        },
        setLoading: setChemicalLoading,
        onSuccess: (fetch_output) => {
          if (fetch_output.status) {
            setChemicalComposition(fetch_output.data);
          } else {
            showMessage({ type: "warning", message: fetch_output.error });
          }
        },
        onFetchError: () => {
          showMessage({
            apiType: "fetch",
            message: "get all chemical composition",
          });
        },
        onResponseError: () => {
          showMessage({
            apiType: "response",
            message: "Get All Chemical Composition",
          });
        },
      });
    },
    [fetchWrapper]
  );
  return { getAllChemicalComposition, chemicalComposition, chemicalLoading };
}

export default useGetAllChemicalComposition;
