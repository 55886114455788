import { useCallback, useState } from "react";
import useFetch from "../../../hooks/useFetch";
import { useNavigate } from "react-router-dom";
import { useShowMessage } from "../../Common/showMessage";

function useUpdateDeliveryOrderConnection() {
  const navigate = useNavigate();
  const [fetchWrapper] = useFetch();
  const [updateLoading, setUpdateLoading] = useState(false);

  const showMessage = useShowMessage();

  const updateDeliveryOrderConnection = useCallback(() => {
    return new Promise((resolve, reject) => {
      setUpdateLoading(true);
      fetchWrapper({
        endpoint_url: "ai_rebar_count/updateDeliveryOrderConnection",
        setLoading: setUpdateLoading,
        onSuccess: (fetch_output) => {
          if (fetch_output.status) {
            navigate("/Delivery_Order_Connection", { replace: true });
          } else {
            showMessage({ type: "warning", message: fetch_output.error });
          }
          resolve(fetch_output);
        },
        onFailure: (error) => {
          showMessage({ type: "error", message: error });
          reject(error);
        },
        onFetchError: () => {
          showMessage({
            apiType: "fetch",
            message: "update delivery order connection",
          });
        },
        onResponseError: () => {
          showMessage({
            apiType: "response",
            message: "Update Delivery Order Connection",
          });
        },
      });
    });
  }, [fetchWrapper]);
  return { updateDeliveryOrderConnection, updateLoading };
}

export default useUpdateDeliveryOrderConnection;
