import { Form } from "antd";
import CustomModal from "../../common/CustomModal";
import CustomSearchForm from "../../common/CustomSearchForm";
import useGetDiameterTypeOption from "../../../functions/Options/getDiameterTypeOption";
import useGetStageOption from "../../../functions/Options/getStageOption";

// Sort Field Option for the Advanced Search Form
const BUNDLE_LIST_SORT = [
  {
    label: "Product",
    value: "product",
  },
  {
    label: "Serial Number",
    value: "tag_id",
  },
  {
    label: "Rebar Qty",
    value: "rebar_qty",
  },
  {
    label: "Last Update",
    value: "last_update",
  },
  {
    label: "Last Update Operator",
    value: "last_update_operator",
  },
];

export default function BundleListSearchForm({
  modalOpen,
  setModalOpen,
  tableData,
  setFilterTable,
}) {
  const [bundleListSearch] = Form.useForm();
  const { diameterOption } = useGetDiameterTypeOption();
  const { stageNameOption } = useGetStageOption();

  const BUNDLE_LIST_FILTER = [
    {
      label: "Product",
      name: "product",
      type: "select",
      options: diameterOption,
    },
    {
      label: "Serial Number",
      name: "tag_id",
      type: "input",
    },
    {
      label: "Stage",
      name: "stage",
      type: "select",
      options: stageNameOption,
    },
    {
      label: "Status",
      name: "status",
      type: "select",
      options: [
        { label: "Verified", value: "Verified" },
        {
          label: "AI Processed",
          value: "AI Processed",
        },
        {
          label: "Verifying",
          value: "Verifying",
        },
      ],
    },
    {
      label: "Rebar Qty (Last Update)",
      name: "rebar_qty",
      type: "input-number",
    },
    {
      label: "Last Update",
      name: "last_update",
      type: "range-select",
    },
    {
      label: "Captured By",
      name: "captured_by",
      type: "input",
    },
    {
      label: "Remark",
      name: "remark",
      type: "select",
      options: [
        {
          label: "Rework",
          value: "rework",
        },
        {
          label: "Matched",
          value: "matched",
        },
      ],
    },
  ];

  // To handle the Advanced Search Form Result
  const handleSubmit = (value) => {
    setModalOpen(false);
    const filter = filterData(tableData, value);
    const sort = sortData(filter, value.sortTags);
    setFilterTable(sort);
  };

  // Function to filter data
  const filterData = (data, filter) => {
    return data.filter((item) => {
      return Object.keys(filter).every((key) => {
        const filterValue = filter[key];
        const itemValue = item[key];

        if (key === "sortTags") return true;
        if (filterValue === undefined || filterValue === null) return true;

        if (key === "last_update" && Array.isArray(filterValue)) {
          const [startDate, endDate] = filterValue;
          const itemDate = new Date(itemValue);
          return (
            itemDate >= new Date(startDate) && itemDate <= new Date(endDate)
          );
        }

        return String(itemValue).includes(String(filterValue));
      });
    });
  };

  // Function to sort data
  const sortData = (data, sort) => {
    return data.sort((a, b) => {
      for (const { sortField, sortOrder } of sort) {
        const fieldA = a[sortField];
        const fieldB = b[sortField];

        let comparison = 0;
        if (typeof fieldA === "string" && typeof fieldB === "string") {
          comparison = fieldA.localeCompare(fieldB);
        } else {
          comparison = fieldA < fieldB ? -1 : fieldA > fieldB ? 1 : 0;
        }

        if (sortOrder === "Descending") comparison *= -1;
        if (comparison !== 0) return comparison;
      }
      return 0;
    });
  };

  return (
    <CustomModal
      title={"Advanced Search Form"}
      close_icon={true}
      visible={modalOpen}
      onCancel={() => setModalOpen(false)}
      content={
        <CustomSearchForm
          form={bundleListSearch}
          onSubmit={handleSubmit}
          filterForm={BUNDLE_LIST_FILTER}
          sorterOption={BUNDLE_LIST_SORT}
        />
      }
    />
  );
}
