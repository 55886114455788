import { MinusOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Col, Flex, Input, Row, Space, theme } from "antd";
import FunctionalButton from "../common/FunctionalButton";

export default function RebarVerificationForm({
  rebarInfo,
  rebarAccuracy,
  userCount,
  setUserCount,
  handleSubmit,
  handleCancel,
  updateLoading,
}) {
  // Get the theme color
  const { token } = theme.useToken();

  // Function to add Rebar Count
  const addCount = () => setUserCount(userCount + 1);
  // Function to minus Rebar Count
  const minusCount = () => setUserCount(userCount - 1);

  return (
    <Space direction="vertical" size="middle">
      <Row gutter={[16, 16]}>
        <Col xs={24} sm={24} md={12} lg={12} xl={24} xxl={12}>
          <Input addonBefore="Overall Accuracy: " value={`${rebarAccuracy}%`} />
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} xl={24} xxl={12}>
          <Input addonBefore="Serial No: " value={rebarInfo.tag_id} />
        </Col>
      </Row>
      <Row gutter={[16, 16]}>
        <Col xs={24} sm={24} md={12} lg={12} xl={24} xxl={12}>
          <Input addonBefore="Rebar Count: " value={rebarInfo.rebar_count} />
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} xl={24} xxl={12}>
          <Space.Compact block>
            <Button
              icon={<MinusOutlined style={{ color: "white" }} />}
              style={{
                backgroundColor: token.colorError,
                width: "15%",
              }}
              onClick={minusCount}
            />
            <Input value={userCount} style={{ width: "70%" }} />
            <Button
              icon={<PlusOutlined style={{ color: "white" }} />}
              style={{
                backgroundColor: token.colorSuccess,
                width: "15%",
                marginLeft: "0px",
              }}
              onClick={addCount}
            />
          </Space.Compact>
        </Col>
      </Row>
      <Flex vertical="vertical" gap="small" align="center">
        <FunctionalButton
          width="50%"
          buttonClickFunction={handleSubmit}
          trigger_loading={updateLoading}
          show_confirmation={true}
          confirmation_message={{
            title: "Rebar Verification Confirmation",
            content: "Are you sure?",
          }}
        >
          Submit
        </FunctionalButton>
        {rebarInfo.enable_cancel && (
          <FunctionalButton
            width="50%"
            buttonClickFunction={handleCancel}
            type=""
          >
            Cancel
          </FunctionalButton>
        )}
      </Flex>
    </Space>
  );
}
