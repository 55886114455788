import { useCallback, useState } from "react";
import useFetch from "../../../hooks/useFetch";
import { useShowMessage } from "../../Common/showMessage";

function useGetUserList() {
  const [fetchWrapper] = useFetch();
  const [userListLoading, setUserListLoading] = useState(false);
  const [userList, setUserList] = useState([]);

  const showMessage = useShowMessage();

  const getUserList = useCallback(() => {
    setUserListLoading(true);
    fetchWrapper({
      endpoint_url: "configuration/getUserList",
      setLoading: setUserListLoading,
      onSuccess: (fetch_output) => {
        if (fetch_output.status) {
          setUserList(fetch_output.data);
        } else {
          showMessage({ type: "warning", message: fetch_output.error });
        }
      },
      onFetchError: () => {
        showMessage({ apiType: "fetch", message: "get user list" });
      },
      onResponseError: () => {
        showMessage({ apiType: "response", message: "Get User List" });
      },
    });
  }, [fetchWrapper]);

  return { getUserList, userList, userListLoading };
}

export default useGetUserList;
