import {
  AuditOutlined,
  CalculatorOutlined,
  CameraOutlined,
  DownloadOutlined,
  FileMarkdownOutlined,
  FileProtectOutlined,
  FileTextOutlined,
  FundProjectionScreenOutlined,
  GoldOutlined,
  InfoCircleOutlined,
  LineChartOutlined,
  MergeOutlined,
  QrcodeOutlined,
  SettingOutlined,
  TableOutlined,
} from "@ant-design/icons";
import { Flex } from "antd";
import {
  account,
  manager,
  operator,
  qcOperator,
  supervisor,
  demo,
} from "../configs/configs";
import { generateRoute } from "../routes/AutoGenerateRoutes";

const header_height = 58;

const MENU_LABEL_ICON = {
  Test: CameraOutlined,
  Camera_Test: CameraOutlined,
  Qr_Scanner_Test: QrcodeOutlined,
  Download_Test: DownloadOutlined,
  Table_Data_Test: TableOutlined,
  Ai_Rebar_Count: CameraOutlined,
  Bundle_List: FileTextOutlined,
  Analytics: LineChartOutlined,
  Dashboard: FundProjectionScreenOutlined,
  Statistics: CalculatorOutlined,
  Traceability: MergeOutlined,
  Digital_Mill_Cert: FileMarkdownOutlined,
  Doprogress: GoldOutlined,
  Delivery_Order: AuditOutlined,
  Material_Properties: FileProtectOutlined,
  Configuration: SettingOutlined,
};

const MENU_LABEL_TEXT = {
  Ai_Rebar_Count: "AI Rebar Count",
  Doprogress: "Do Progress",
};

const MENU_PARENT_SORT = [
  "Ai_Rebar_Count",
  "Bundle_List",
  "Analytics",
  "Digital_Mill_Cert",
  "Configuration",
];

const MENU_LABEL_SORT = [];

const IGNORE_MENU_FOLDER = [
  "Account",
  "Login_Record",
  "Rebar_Verification",
  "Delivery_Order_Connection",
  "Demo",
  "Test",
];
const IGNORE_MENU_PAGE = [];

const USER_ACCESS_LABEL = {
  Test: [operator, supervisor, manager, account, qcOperator, demo],
  Ai_Rebar_Count: [account, qcOperator, demo],
  Bundle_List: [operator, account, qcOperator, demo],
  Analytics: [operator, supervisor, account, qcOperator, demo],
  Digital_Mill_Cert: [operator, demo],
  Doprogress: [account, qcOperator, demo],
  Delivery_Order: [qcOperator, demo],
  Material_Properties: [supervisor, account, demo],
  Configuration: [operator, supervisor, account, qcOperator, demo],
  Demo: [operator, supervisor, manager, account, qcOperator],
};

export default function useNavMenu(authenticationState) {
  const user_type = authenticationState.user_type;
  const { createMenuLabel } = useMenuLabel(user_type);

  if (user_type === undefined) {
    return [[], header_height];
  }

  const routeList = generateRoute();
  const filteredRoutes = routeList.filter(
    (route) =>
      !IGNORE_MENU_FOLDER.includes(route.parent) &&
      !IGNORE_MENU_PAGE.includes(route.name)
  );

  const sortedRoutes = filteredRoutes.sort((a, b) => {
    // Primary sort: by parent using MENU_PARENT_SORT
    const indexAParent = MENU_PARENT_SORT.indexOf(a.parent);
    const indexBParent = MENU_PARENT_SORT.indexOf(b.parent);

    if (indexAParent !== -1 && indexBParent !== -1) {
      // Both parents are in MENU_PARENT_SORT, compare their indices
      if (indexAParent !== indexBParent) {
        return indexAParent - indexBParent;
      }
    } else if (indexAParent !== -1) {
      // Only A is in MENU_PARENT_SORT, A comes first
      return -1;
    } else if (indexBParent !== -1) {
      // Only B is in MENU_PARENT_SORT, B comes first
      return 1;
    } else {
      // Neither is in MENU_PARENT_SORT, compare parent alphabetically
      const parentComparison = a.parent.localeCompare(b.parent);
      if (parentComparison !== 0) {
        return parentComparison;
      }
    }

    // Secondary sort: by name using MENU_LABEL_SORT
    const indexAName = MENU_LABEL_SORT.indexOf(a.name);
    const indexBName = MENU_LABEL_SORT.indexOf(b.name);

    if (indexAName !== -1 && indexBName !== -1) {
      // Both names are in MENU_LABEL_SORT, compare their indices
      return indexAName - indexBName;
    } else if (indexAName !== -1) {
      // Only A is in MENU_LABEL_SORT, A comes first
      return -1;
    } else if (indexBName !== -1) {
      // Only B is in MENU_LABEL_SORT, B comes first
      return 1;
    }

    // Neither is in MENU_LABEL_SORT, compare name alphabetically
    return a.name.localeCompare(b.name);
  });

  const groupedRoutes = sortedRoutes.reduce((acc, route) => {
    if (!acc[route.parent]) acc[route.parent] = [];
    acc[route.parent].push(route);
    return acc;
  }, {});

  const organizeRoutes = (routes) => {
    const menus = [];

    // Process each route group
    Object.entries(routes).forEach(([key, value]) => {
      const groupedMenus = groupByPath(value, true); // Pass `true` for the first parent
      menus.push(...groupedMenus);
    });
    return menus;
  };

  const groupByPath = (items, isTopLevel = false, basePath = "") => {
    const grouped = {};
    items.forEach((item) => {
      const pathParts = item.path.split("/").filter(Boolean);
      const currentPart = pathParts[0];
      if (!grouped[currentPart]) {
        grouped[currentPart] = [];
      }

      grouped[currentPart].push({
        ...item,
        path: pathParts.slice(1).join("/"), // Remove the current part from the path
      });
    });

    const groupedMenu = Object.entries(grouped).map(([key, value]) => {
      const isLeaf = value.every((v) => !v.path); // Check if all items are leaf nodes
      const children = isLeaf
        ? []
        : groupByPath(
            value.filter((v) => v.path), // Only pass items with remaining paths
            false, // Nested children are not top-level
            `${basePath}/${key}`
          );

      const parentItem = value.find((v) => !v.path) || {
        name: key,
        path: `${basePath}/${key}`,
      };

      return createMenuLabel(parentItem, children, isTopLevel); // Pass `isTopLevel` to `createMenuLabel`
    });

    return groupedMenu;
  };

  const NavMenu = organizeRoutes(groupedRoutes);

  return [NavMenu, header_height];
}

const useMenuLabel = (user_type) => {
  const userAccess = (allowUserType = []) => {
    if (allowUserType.length == 0) return false;
    else return allowUserType.includes(user_type);
  };

  const menuLabel = (Icon, text, style = {}) => {
    return (
      <Flex align="center" justify="start" style={style}>
        <Icon style={{ marginRight: 12 }} />
        {text}
      </Flex>
    );
  };

  const getMenuLabelData = (key) => ({
    icon: MENU_LABEL_ICON[key] || InfoCircleOutlined,
    text: MENU_LABEL_TEXT[key] || key.split("_").join(" "),
  });

  const createMenuLabel = (value, children = [], childrenHeader = true) => {
    const routeName = value.name;

    if (!userAccess(USER_ACCESS_LABEL[routeName])) {
      const { icon, text } = getMenuLabelData(routeName);
      return {
        label: menuLabel(icon, text, {
          paddingInline: 8,
          height: childrenHeader > 0 && header_height,
        }),
        key: routeName,
        children: children.length > 0 ? children : undefined,
      };
    }
  };

  return { createMenuLabel };
};
