import { useCallback, useEffect, useState } from "react";
import useFetch from "../../hooks/useFetch";
import { useShowMessage } from "../Common/showMessage";
import useRetry from "../../hooks/useRetry";
import { MAX_RETRIES } from "../../configs/configs";

function useGetDiameterTypeOption() {
  const [fetchWrapper] = useFetch();
  const [diameterOption, setDiameterOption] = useState([]);
  const [diameterSelect, setDiameterSelect] = useState(null);

  const showMessage = useShowMessage();
  const { retryCount, setRetryCount } = useRetry(() => getDiameterTypeOption());

  const getDiameterTypeOption = useCallback(() => {
    fetchWrapper({
      endpoint_url: "options/getDiameterTypeOption",
      onSuccess: (fetch_output) => {
        if (fetch_output.status) {
          setDiameterOption(fetch_output.options);
          setDiameterSelect([fetch_output.options[0].value]);
          setRetryCount(0);
        } else {
          showMessage({ type: "warning", message: fetch_output.error });
        }
      },
      onFetchError: () => {
        if (retryCount === MAX_RETRIES)
          showMessage({
            apiType: "fetch",
            message: "get diameter type option",
          });

        setRetryCount((prev) => prev + 1);
      },
      onResponseError: () => {
        if (retryCount === MAX_RETRIES)
          showMessage({
            apiType: "response",
            message: "Get Diameter Type Option",
          });

        setRetryCount((prev) => prev + 1);
      },
    });
  }, [fetchWrapper]);

  useEffect(() => {
    getDiameterTypeOption();
  }, []);

  return {
    diameterOption,
    diameterSelect,
    setDiameterSelect,
  };
}

export default useGetDiameterTypeOption;
