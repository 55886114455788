import { Flex, Typography } from "antd";
import FunctionalButton from "../../common/FunctionalButton";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import DataTable from "../../common/DataTable";
import { useState } from "react";

export default function CustomerManagementTable({
  tableData,
  handleEdit,
  handleDelete,
  deleteLoading,
}) {
  const [loadRowKey, setLoadRowKey] = useState(null);
  const CUSTOMER_COLUMN = [
    {
      title: "No.",
      dataIndex: "number",
      key: "number",
      width: 80,
    },
    {
      title: "Customer Name",
      dataIndex: "customer_name",
      key: "customer_name",
      width: 120,
    },
    {
      title: "Customer Address",
      dataIndex: "customer_address",
      key: "customer_address",
      width: 160,
      render: (customer_address) => (
        <>
          {customer_address.split("\n").map((line, index) => (
            <Typography.Text key={index}>
              {line}
              <br />
            </Typography.Text>
          ))}
        </>
      ),
    },
    {
      title: "Insert By",
      dataIndex: "insert_by",
      key: "insert_by",
      width: 100,
    },
    {
      title: "Insert Date Time",
      dataIndex: "insert_date_time",
      key: "insert_date_time",
      width: 140,
    },
    {
      title: "Last Update By",
      dataIndex: "last_update_by",
      key: "last_update_by",
      width: 100,
    },
    {
      title: "Last Update Date Time",
      dataIndex: "last_update_date_time",
      key: "last_update_date_time",
      width: 140,
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      width: 100,
      render: (_, record) => {
        return (
          <Flex gap="small">
            <FunctionalButton
              buttonClickFunction={() => handleEdit(record)}
              type=""
              width="40px"
              disabled={loadRowKey == record.key && deleteLoading}
              icon={<EditOutlined />}
            />
            <FunctionalButton
              buttonClickFunction={() => {
                handleDelete(record.key);
                setLoadRowKey(record.key);
              }}
              show_confirmation={true}
              confirmation_message={{
                title: "Confirmation",
                content: "Sure to delete this bar mark?",
              }}
              danger={true}
              width="40px"
              icon={<DeleteOutlined />}
              trigger_loading={loadRowKey == record.key && deleteLoading}
            />
          </Flex>
        );
      },
    },
  ];

  return (
    <DataTable
      table_column={CUSTOMER_COLUMN}
      table_data={tableData}
      columnCenter={true}
    />
  );
}
