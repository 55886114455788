import { useCallback, useState } from "react";
import useFetch from "../../../hooks/useFetch";
import { useShowMessage } from "../../Common/showMessage";

function useGetOneMonthStuckBundles() {
  const [fetchWrapper] = useFetch();
  const [oneMonthStuckChart, setOneMonthStuckChart] = useState([]);

  const showMessage = useShowMessage();

  const getOneMonthStuckBundles = useCallback(
    (setIsLoading) => {
      setIsLoading(true);
      fetchWrapper({
        endpoint_url: "dashboard/getOneMonthStuckBundles",
        setLoading: setIsLoading,
        onSuccess: (fetch_output) => {
          if (fetch_output.status) {
            setOneMonthStuckChart(fetch_output.data);
          } else {
            showMessage({ type: "warning", message: fetch_output.error });
          }
        },
        onFetchError: () => {
          showMessage({
            apiType: "fetch",
            message: "get one month stuck bundles",
          });
        },
        onResponseError: () => {
          showMessage({
            apiType: "response",
            message: "Get One Month Stuck Bundles",
          });
        },
      });
    },
    [fetchWrapper]
  );

  return { getOneMonthStuckBundles, oneMonthStuckChart };
}

export default useGetOneMonthStuckBundles;
