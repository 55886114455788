import { useContext, useEffect, useRef } from "react";
import { IndexContext } from "../../context/IndexContext";
import { Line } from "@antv/g2plot";

export default function CustomLineChart(props) {
  const {
    data = [],
    xField = "date",
    yField = "value",
    seriesField = null,
    xAxis = "",
    yAxis = "",
    fontSize = 14,
    chartSize = "40vh",
    annotations = [],
    isGroup = false,
    tooltipName = "value",
    smooth = true,
    pointShape = "circle",
    pointSize = 4,
    areaOpen = false,
    animationOpen = true,
    animationDuration = 2000,
    animationType = "wave-in",
    animationEasing = "easeSinInOut",
  } = props;

  const { isLightTheme } = useContext(IndexContext);
  const containerRef = useRef(null);
  const chartRef = useRef(null);

  useEffect(() => {
    if (containerRef.current) {
      // Destroy existing chart if already initialized
      if (chartRef.current) {
        chartRef.current.destroy();
        chartRef.current = null;
      }

      // Create a new chart instance with updated styles
      chartRef.current = new Line(containerRef.current, {
        data,
        padding: "auto",
        isGroup: isGroup,
        xField: xField,
        yField: yField,
        seriesField: seriesField,
        xAxis: {
          title: {
            text: xAxis,
            style: {
              fontSize: fontSize,
              fill: isLightTheme ? "#000000" : "#FFFFFF",
            },
          },
        },
        yAxis: {
          title: {
            text: yAxis,
            style: {
              fontSize: fontSize,
              fill: isLightTheme ? "#000000" : "#FFFFFF",
            },
          },
        },
        smooth: smooth,
        area: areaOpen && {
          style: {
            fillOpacity: 0.2,
          },
        },
        animation: animationOpen && {
          appear: {
            animation: animationType,
            duration: animationDuration,
            easing: animationEasing,
          },
        },
        point: {
          shape: pointShape,
          size: pointSize,
        },
        legend: {
          itemName: {
            style: {
              fill: isLightTheme ? "#000000" : "#FFFFFF",
              fontSize: 14,
            },
          },
        },
        tooltip: {
          formatter: (data) => {
            return {
              name: data.type != undefined ? data.type : tooltipName,
              value: data.value,
            };
          },
        },
        interactions: [
          {
            type: "element-selected",
          },
          {
            type: "legend-highlight",
          },
        ],
        annotations: annotations,
      });
      chartRef.current.render();
    }

    return () => {
      if (chartRef.current) {
        chartRef.current.destroy();
        chartRef.current = null;
      }
    };
  }, [isLightTheme, data, annotations]);

  return <div ref={containerRef} style={{ height: chartSize }} />;
}
