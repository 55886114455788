import { useCallback, useState } from "react";
import useFetch from "../../../hooks/useFetch";
import { useShowMessage } from "../../Common/showMessage";

function useGetProductionData() {
  const [fetchWrapper] = useFetch();
  const [productionTableData, setProductionTableData] = useState([]);
  const [productionColumn, setProductionColumn] = useState([]);
  const [productionChartData, setProductionChartData] = useState([]);
  const [productionDownload, setProductionDownload] = useState([]);

  const showMessage = useShowMessage();

  const getProductionData = useCallback(
    (factory, diameter, startDate, endDate, time, setIsLoading) => {
      setIsLoading(true);
      fetchWrapper({
        endpoint_url: "statistics/getProductionData",
        params: {
          factory: factory,
          diameter: diameter,
          startDate: startDate,
          endDate: endDate,
          time: time,
        },
        setLoading: setIsLoading,
        onSuccess: (fetch_output) => {
          if (fetch_output.status) {
            setProductionTableData(fetch_output.table_data);
            setProductionColumn(fetch_output.table_column);
            setProductionChartData(fetch_output.chart_data);
            setProductionDownload(fetch_output.download_data);
          } else {
            showMessage({ type: "warning", message: fetch_output.error });
          }
        },
        onFetchError: () => {
          showMessage({ apiType: "fetch", message: "get production table" });
        },
        onResponseError: () => {
          showMessage({ apiType: "response", message: "Get Production Table" });
        },
      });
    },
    [fetchWrapper]
  );

  return {
    getProductionData,
    productionTableData,
    productionColumn,
    productionChartData,
    productionDownload,
  };
}

export default useGetProductionData;
