import { useCallback, useState } from "react";
import useFetch from "../../../hooks/useFetch";
import { useShowMessage } from "../../Common/showMessage";

function useGetFactoryBundles() {
  const [fetchWrapper] = useFetch();
  const [factoryChart, setFactoryChart] = useState([]);

  const showMessage = useShowMessage();

  const getFactoryBundles = useCallback(
    (setIsLoading) => {
      setIsLoading(true);
      fetchWrapper({
        endpoint_url: "dashboard/getFactoryBundles",
        setLoading: setIsLoading,
        onSuccess: (fetch_output) => {
          if (fetch_output.status) {
            setFactoryChart(fetch_output.data);
          } else {
            showMessage({ type: "warning", message: fetch_output.error });
          }
        },
        onFetchError: () => {
          showMessage({ apiType: "fetch", message: "get factory bundles" });
        },
        onResponseError: () => {
          showMessage({ apiType: "response", message: "Get Factory Bundles" });
        },
      });
    },
    [fetchWrapper]
  );

  return { getFactoryBundles, factoryChart };
}

export default useGetFactoryBundles;
