import useFetch from "../../../hooks/useFetch";
import { useCallback, useState } from "react";
import { useShowMessage } from "../../Common/showMessage";

function useRemoveChemicalComposition() {
  const [fetchWrapper] = useFetch();
  const [chemicalDeleteLoading, setChemicalDeleteLoading] = useState(false);

  const showMessage = useShowMessage();

  const removeChemicalComposition = useCallback(
    (key, handleChemicalComposition) => {
      return new Promise((resolve, reject) => {
        setChemicalDeleteLoading(true);
        fetchWrapper({
          endpoint_url: "material_properties/removeChemicalComposition",
          params: {
            chemical_key: key,
          },
          setLoading: setChemicalDeleteLoading,
          onSuccess: (fetch_output) => {
            if (fetch_output.status) {
              showMessage({ type: "success", message: fetch_output.message });
              handleChemicalComposition();
            } else {
              showMessage({ type: "warning", message: fetch_output.error });
            }
            resolve(fetch_output);
          },
          onFailure: (error) => {
            showMessage({ message: error });
            reject(error);
          },
          onFetchError: () => {
            showMessage({
              apiType: "fetch",
              message: "delete chemical composition",
            });
          },
          onResponseError: () => {
            showMessage({
              apiType: "response",
              message: "Delete Chemical Composition",
            });
          },
        });
      });
    },
    [fetchWrapper]
  );
  return { removeChemicalComposition, chemicalDeleteLoading };
}

export default useRemoveChemicalComposition;
