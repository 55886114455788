import { useCallback, useState } from "react";
import useFetch from "../../../hooks/useFetch";
import { useShowMessage } from "../../Common/showMessage";

function useGetOperatorBundles() {
  const [fetchWrapper] = useFetch();
  const [operatorChart, setOperatorChart] = useState([]);
  const [operatorSummary, setOperatorSummary] = useState({});

  const showMessage = useShowMessage();

  const getOperatorBundles = useCallback(
    (setIsLoading) => {
      setIsLoading(true);
      fetchWrapper({
        endpoint_url: "dashboard/getOperatorBundles",
        setLoading: setIsLoading,
        onSuccess: (fetch_output) => {
          if (fetch_output.status) {
            setOperatorChart(fetch_output.chart_data);
            setOperatorSummary(fetch_output.summary_data);
          } else {
            showMessage({ type: "warning", message: fetch_output.error });
          }
        },
        onFetchError: () => {
          showMessage({ apiType: "fetch", message: "get operator bundles" });
        },
        onResponseError: () => {
          showMessage({ apiType: "response", message: "Get Operator Bundles" });
        },
      });
    },
    [fetchWrapper]
  );

  return { getOperatorBundles, operatorChart, operatorSummary };
}

export default useGetOperatorBundles;
