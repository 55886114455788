import { useCallback, useState } from "react";
import useFetch from "../../../hooks/useFetch";
import { useShowMessage } from "../../Common/showMessage";

function useGetStatisticWipData() {
  const [fetchWrapper] = useFetch();
  const [statisticWipTableData, setStatisticWipTableData] = useState([]);
  const [statisticWipColumn, setStatisticWipColumn] = useState([]);
  const [statisticWipChartData, setStatisticChartData] = useState([]);
  const [statisticWipDownload, setStatisticDownload] = useState([]);

  const showMessage = useShowMessage();

  const getStatisticWipData = useCallback(
    (factory, diameter, startDate, endDate, time, setIsLoading) => {
      setIsLoading(true);
      fetchWrapper({
        endpoint_url: "statistics/getStatisticWipData",
        params: {
          factory: factory,
          diameter: diameter,
          startDate: startDate,
          endDate: endDate,
          time: time,
        },
        setLoading: setIsLoading,
        onSuccess: (fetch_output) => {
          if (fetch_output.status) {
            setStatisticWipTableData(fetch_output.table_data);
            setStatisticWipColumn(fetch_output.table_column);
            setStatisticChartData(fetch_output.chart_data);
            setStatisticDownload(fetch_output.download_data);
          } else {
            showMessage({ type: "warning", message: fetch_output.error });
          }
        },
        onFetchError: () => {
          showMessage({ apiType: "fetch", message: "get statistic wip table" });
        },
        onResponseError: () => {
          showMessage({
            apiType: "response",
            message: "Get Statistic WIP Table",
          });
        },
      });
    },
    [fetchWrapper]
  );

  return {
    getStatisticWipData,
    statisticWipTableData,
    statisticWipColumn,
    statisticWipChartData,
    statisticWipDownload,
  };
}

export default useGetStatisticWipData;
