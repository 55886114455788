import { useCallback, useState } from "react";
import useFetch from "../../hooks/useFetch";
import { useShowMessage } from "../Common/showMessage";

function useGetDOProgressList() {
  const [fetchWrapper] = useFetch();
  const [isLoading, setIsLoading] = useState(false);
  const [doProgressList, setDOProgressList] = useState([]);

  const showMessage = useShowMessage();

  const getDOProgressList = useCallback(
    (startDateSelection, endDateSelection) => {
      setIsLoading(true);
      fetchWrapper({
        endpoint_url: "do_progress/getDOProgressList",
        params: {
          startDate: startDateSelection,
          endDate: endDateSelection,
        },
        setLoading: setIsLoading,
        onSuccess: (fetch_output) => {
          if (fetch_output.status) {
            setDOProgressList(fetch_output.data);
          } else {
            showMessage({ type: "warning", message: fetch_output.error });
          }
        },
        onFetchError: () => {
          showMessage({
            apiType: "fetch",
            message: "get delivery order progress list",
          });
        },
        onResponseError: () => {
          showMessage({
            apiType: "response",
            message: "Get Delivery Order Progress List",
          });
        },
      });
    },
    [fetchWrapper]
  );

  return { getDOProgressList, doProgressList, isLoading };
}

export default useGetDOProgressList;
