import { DownloadOutlined } from "@ant-design/icons";
import { Button } from "antd";
import { exportExcel } from "../../functions/Common/exportExcel";

export default function CustomDownloadExcelButton({
  dataList,
  excelHeader,
  excelColumnWidth = [],
  fileName,
  loading = false,
}) {
  return (
    <Button
      children={<DownloadOutlined />}
      disabled={dataList?.length == 0}
      onClick={() =>
        exportExcel({
          data: dataList,
          headers: excelHeader,
          columnWidths: excelColumnWidth,
          fileName: fileName,
        })
      }
      loading={loading}
    />
  );
}
