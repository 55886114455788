import { Form } from "antd";
import CustomModalForm from "../common/CustomModalForm";
import CustomFormItems from "../common/CustomFormItems";

export default function DemoWeightModal({
  openModal,
  setModalOpen,
  weightType,
}) {
  const [weightForm] = Form.useForm();

  const ARRIIVAL_FORM = [
    {
      label: "Arrival Weight",
      name: "arrival_weight",
      type: "input",
      required: true,
    },
  ];

  const DEPARTURE_FORM = [
    {
      label: "Departure Weight",
      name: "departure_weight",
      type: "input",
      required: true,
    },
  ];

  return (
    <CustomModalForm
      form={weightForm}
      formTitle={
        weightType === "arrival"
          ? "Insert Arrival Weight"
          : "Insert Departure Weight"
      }
      modalOpen={openModal}
      setModalOpen={setModalOpen}
      onSubmit={() => {}}
      context={
        <CustomFormItems
          form_properties={
            weightType === "arrival" ? ARRIIVAL_FORM : DEPARTURE_FORM
          }
        />
      }
    />
  );
}
