import { useCallback, useState } from "react";
import useFetch from "../../../hooks/useFetch";
import { useShowMessage } from "../../Common/showMessage";

function useGetDailyBundle() {
  const [fetchWrapper] = useFetch();
  const showMessage = useShowMessage();

  const [dailyLoading, setDailyLoading] = useState(false);
  const [dailyList, setDailyList] = useState([]);
  const [dailyStatus, setDailyStatus] = useState({});

  const getDailyBundle = useCallback(
    (startDate, endDate) => {
      fetchWrapper({
        endpoint_url: "bundle_list/getDailyBundle",
        params: {
          startDate: startDate,
          endDate: endDate,
        },
        setLoading: setDailyLoading,
        onSuccess: (fetch_output) => {
          if (fetch_output.status) {
            setDailyList(fetch_output.table_data);
            setDailyStatus(fetch_output.status_data);
          } else {
            showMessage({ message: fetch_output.error });
          }
        },
        onFetchError: () =>
          showMessage({ apiType: "fetch", message: "get daily bundle" }),
        onResponseError: () =>
          showMessage({ apiType: "response", message: "get daily bundle" }),
      });
    },
    [fetchWrapper]
  );

  return { getDailyBundle, dailyList, dailyStatus, dailyLoading };
}

export default useGetDailyBundle;
