import { useCallback, useState } from "react";
import useFetch from "../../../hooks/useFetch";
import { useShowMessage } from "../../Common/showMessage";

function useGetDeliveryData() {
  const [fetchWrapper] = useFetch();
  const [deliveryTableData, setDeliveryTableData] = useState([]);
  const [deliveryColumn, setDeliveryColumn] = useState([]);
  const [deliveryChartData, setDeliveryChartData] = useState([]);
  const [deliveryDownload, setDeliveryDownload] = useState([]);

  const showMessage = useShowMessage();

  const getDeliveryData = useCallback(
    (factory, diameter, startDate, endDate, time, setIsLoading) => {
      setIsLoading(true);
      fetchWrapper({
        endpoint_url: "statistics/getDeliveryData",
        params: {
          factory: factory,
          diameter: diameter,
          startDate: startDate,
          endDate: endDate,
          time: time,
        },
        setLoading: setIsLoading,
        onSuccess: (fetch_output) => {
          if (fetch_output.status) {
            setDeliveryTableData(fetch_output.table_data);
            setDeliveryColumn(fetch_output.table_column);
            setDeliveryChartData(fetch_output.chart_data);
            setDeliveryDownload(fetch_output.download_data);
          } else {
            showMessage({ type: "warning", message: fetch_output.error });
          }
        },
        onFetchError: () => {
          showMessage({ apiType: "fetch", message: "get delivery table" });
        },
        onResponseError: () => {
          showMessage({ apiType: "response", message: "Get Delivery Table" });
        },
      });
    },
    [fetchWrapper]
  );

  return {
    getDeliveryData,
    deliveryTableData,
    deliveryColumn,
    deliveryChartData,
    deliveryDownload,
  };
}

export default useGetDeliveryData;
