import { useCallback } from "react";
import useFetch from "../../hooks/useFetch";
import { useShowMessage } from "../Common/showMessage";

function useContinueVerifyRebar() {
  const [fetchWrapper] = useFetch();

  const showMessage = useShowMessage();

  const continueVerifyRebar = useCallback(
    (rebar_key, setTimeOut, navigateBack) => {
      fetchWrapper({
        endpoint_url: "rebar_verification/continueVerifyRebar",
        params: {
          rebar_id: rebar_key,
        },
        onSuccess: (fetch_ouput) => {
          if (fetch_ouput.status) {
            setTimeOut(false);
          }
        },
        onFetchError: () => {
          showMessage({ apiType: "fetch", message: "continue verify rebar" });
          navigateBack();
        },
        onResponseError: () => {
          showMessage({
            apiType: "response",
            message: "Continue Verify Rebar",
          });
          navigateBack();
        },
      });
    },
    [fetchWrapper]
  );

  return { continueVerifyRebar };
}

export default useContinueVerifyRebar;
