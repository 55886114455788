import { useCallback } from "react";
import useFetch from "../../../hooks/useFetch";
import { useShowMessage } from "../../Common/showMessage";

function useInsertCustomer() {
  const [fetchWrapper] = useFetch();

  const showMessage = useShowMessage();

  const insertCustomer = useCallback(
    (data, getCustomerList, setFormLoading, setCustomerModal) => {
      return new Promise((resolve, reject) => {
        setFormLoading(true);
        fetchWrapper({
          endpoint_url: "configuration/insertCustomer",
          params: {
            data: data,
          },
          setLoading: setFormLoading,
          onSuccess: (fetch_output) => {
            if (fetch_output.status) {
              getCustomerList();
              setCustomerModal(false);
              showMessage({ type: "success", message: fetch_output.message });
            } else {
              showMessage({ type: "warning", message: fetch_output.error });
            }
            resolve(fetch_output);
          },
          onFailure: (error) => {
            showMessage({ message: error });
            reject(error);
          },
          onFetchError: () => {
            showMessage({ apiType: "fetch", message: "insert customer" });
          },
          onResponseError: () => {
            showMessage({ apiType: "response", message: "Insert Customer" });
          },
        });
      });
    },
    [fetchWrapper]
  );

  return { insertCustomer };
}

export default useInsertCustomer;
