import { useCallback, useState } from "react";
import useFetch from "../../hooks/useFetch";
import { useNavigate } from "react-router-dom";
import { useShowMessage } from "../Common/showMessage";

function useAddDeliveryOrderConnection() {
  const navigate = useNavigate();

  const [fetchWrapper] = useFetch();
  const [addLoading, setAddLoading] = useState(false);

  const showMessage = useShowMessage();

  const addDeliveryOrderConnection = useCallback(
    (delivery_order_id, data) => {
      return new Promise((resolve, reject) => {
        setAddLoading(true);
        fetchWrapper({
          endpoint_url: "delivery_order_connection/addDeliveryOrderConnection",
          params: {
            delivery_order_id: delivery_order_id,
            data: data,
          },
          setLoading: setAddLoading,
          onSuccess: (fetch_output) => {
            if (fetch_output.status) {
              showMessage({ type: "success", message: fetch_output.message });
              navigate("/Ai_Rebar_Count", { replace: true });
              localStorage.setItem(
                "delivery_order_id",
                fetch_output.delivery_order_id
              );
            } else {
              showMessage({ type: "warning", message: fetch_output.error });
            }
            resolve(fetch_output);
          },
          onFailure: (error) => {
            showMessage({ message: error });
            reject(error);
          },
          onFetchError: () => {
            showMessage({
              apiType: "fetch",
              message: "add delivery order connection",
            });
          },
          onResponseError: () => {
            showMessage({
              apiType: "response",
              message: "Add Delivery Order Connection",
            });
          },
        });
      });
    },
    [fetchWrapper]
  );
  return { addDeliveryOrderConnection, addLoading };
}

export default useAddDeliveryOrderConnection;
