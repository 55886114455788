import { useCallback } from "react";
import useFetch from "../../../hooks/useFetch";
import { useShowMessage } from "../../Common/showMessage";

function useCheckBundleListVerfiyStatus() {
  const [fetchWrapper] = useFetch();

  const showMessage = useShowMessage();

  const checkBundleListVerifyStatus = useCallback(
    (getBundleListInfo, getBundleListSummary, startDate, endDate) => {
      fetchWrapper({
        endpoint_url: "bundle_list/checkBundleListVerifyStatus",
        method: "GET",
        set_content_type_header: false,
        onSuccess: (fetch_output) => {
          if (fetch_output.status) {
            getBundleListInfo(startDate, endDate);
            getBundleListSummary(startDate, endDate);
          }
        },
        onFetchError: () => {
          showMessage({
            apiType: "fetch",
            message: "check bundle list status",
          });
        },
        onResponseError: () => {
          showMessage({
            apiType: "response",
            message: "Check Bundle List Status",
          });
        },
      });
    },
    [fetchWrapper]
  );

  return { checkBundleListVerifyStatus };
}

export default useCheckBundleListVerfiyStatus;
