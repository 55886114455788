import { Pie } from "@ant-design/plots";
import { Empty, Flex } from "antd";
import { useContext } from "react";
import { IndexContext } from "../../../context/IndexContext";
import CustomPieChart from "../../chart/CustomPieChart";

export default function InventoryPieChart({ data, chartTextColor }) {
  const { isLightTheme } = useContext(IndexContext);

  const config = {
    data,
    title: {
      title: "Source of Bundles",
      titleFill: isLightTheme ? "#000000" : "#ffffff",
      align: "center",
      position: "bottom",
    },
    angleField: "value",
    colorField: "type",
    radius: 0.8,
    label: {
      text: (d) => `${d.type}: ${d.percentage}%`,
      position: "outside",
      fill: chartTextColor,
    },
    tooltip: {
      title: "type",
    },
    legend: {
      color: {
        title: false,
        position: "bottom",
        rowPadding: 5,
        itemLabelFill: chartTextColor,
      },
    },
  };

  return (
    <CustomPieChart data={data} chartSize={"22vh"} appendPadding={20} />
    // <div style={{ height: "24vh" }}>
    //   {data.length ? (
    //     <Pie {...config} />
    //   ) : (
    //     <Flex align="center" justify="center" style={{ height: "100%" }}>
    //       <Empty />
    //     </Flex>
    //   )}
    // </div>
  );
}
