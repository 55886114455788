import { SearchOutlined } from "@ant-design/icons";
import { Button, DatePicker, Flex } from "antd";
import dayjs from "dayjs";
import { useEffect, useState } from "react";

export default function CustomRangePicker({
  disabled = false,
  timePicker = "date",
  search = false,
  showTime = false,
  onFilterClick,
  onDateChange,
}) {
  const [selectDate, setSelectDate] = useState([
    dayjs().subtract(6, "day").startOf("day"),
    dayjs().endOf("day"),
  ]);
  const [rangePickerDates, setRangePickerDates] = useState();
  const [isRangePickerOpen, setIsRangePickerOpen] = useState();

  const onRangePickerOpenChange = (open) => setIsRangePickerOpen(open);

  const handleDateChange = (dates) => {
    if (dates) {
      const [start, end] = dates;

      const startOf = (type) => (date) => date ? date.startOf(type) : null;
      const endOf = (type) => (date) => date ? date.endOf(type) : null;

      switch (timePicker) {
        case "week":
          setRangePickerDates([startOf("week")(start), endOf("week")(end)]);
          break;
        case "month":
          setRangePickerDates([startOf("month")(start), endOf("month")(end)]);
          break;
        case "year":
          setRangePickerDates([startOf("year")(start), endOf("year")(end)]);
          break;
        default:
          setRangePickerDates([
            showTime ? start : startOf("day")(start),
            showTime ? end : endOf("day")(end),
          ]);
      }
    }
  };

  useEffect(() => {
    if (!isRangePickerOpen) {
      setSelectDate(rangePickerDates);
    }
  }, [isRangePickerOpen]);

  useEffect(() => {
    if (selectDate?.length > 0) onDateChange(selectDate);
  }, [selectDate]);

  return (
    <Flex gap="small">
      <DatePicker.RangePicker
        format={
          timePicker === "week"
            ? "YYYY-wo"
            : timePicker === "month"
            ? "YYYY-MM"
            : timePicker === "year"
            ? "YYYY"
            : showTime
            ? "DD/MM/YYYY HH:mm:ss"
            : "DD/MM/YYYY"
        }
        defaultValue={[
          dayjs().subtract(6, "day").startOf("day"),
          dayjs().endOf("day"),
        ]}
        allowClear={false}
        showTime={showTime}
        picker={timePicker}
        onChange={handleDateChange}
        onOpenChange={onRangePickerOpenChange}
        disabledDate={(current) => current && current > dayjs()}
        disabled={disabled}
        inputReadOnly={true}
        needConfirm={false}
      />

      {search && (
        <Button
          icon={<SearchOutlined />}
          onClick={onFilterClick}
          disabled={disabled}
        />
      )}
    </Flex>
  );
}
