import { useCallback, useEffect, useState } from "react";
import useFetch from "../../hooks/useFetch";
import { useShowMessage } from "../Common/showMessage";
import useRetry from "../../hooks/useRetry";
import { MAX_RETRIES } from "../../configs/configs";

function useGetFactoryOption() {
  const [fetchWrapper] = useFetch();
  const [factoryOption, setFactoryOption] = useState([]);
  const [factorySelect, setFactorySelect] = useState(null);

  const showMessage = useShowMessage();
  const { retryCount, setRetryCount } = useRetry(() => getFactoryOption());

  const getFactoryOption = useCallback(() => {
    fetchWrapper({
      endpoint_url: "options/getFactoryOption",
      onSuccess: (fetch_output) => {
        if (fetch_output.status) {
          setFactoryOption(fetch_output.options);
          setFactorySelect([fetch_output.options[0].value]);
          setRetryCount(0);
        } else {
          showMessage({ type: "warning", message: fetch_output.error });
        }
      },
      onFetchError: () => {
        if (retryCount === MAX_RETRIES)
          showMessage({ apiType: "fetch", message: "get factory option" });

        setRetryCount((prev) => prev + 1);
      },
      onResponseError: () => {
        if (retryCount === MAX_RETRIES)
          showMessage({ apiType: "response", message: "Get Factory Option" });

        setRetryCount((prev) => prev + 1);
      },
    });
  }, [fetchWrapper]);

  useEffect(() => {
    getFactoryOption();
  }, []);

  return { factoryOption, factorySelect, setFactorySelect };
}

export default useGetFactoryOption;
