import { useCallback, useState } from "react";
import useFetch from "../../../hooks/useFetch";
import { useShowMessage } from "../../Common/showMessage";

function useGetWeightMinMaxList() {
  const [fetchWrapper] = useFetch();
  const [weightMinMaxList, setWeightMinMaxList] = useState([]);
  const [weightMinMaxLoading, setWeightMinMaxLoading] = useState(false);

  const showMessage = useShowMessage();

  const getWeightMinMaxList = useCallback(() => {
    setWeightMinMaxLoading(true);
    fetchWrapper({
      endpoint_url: "configuration/getWeightMinMaxList",
      setLoading: setWeightMinMaxLoading,
      onSuccess: (fetch_output) => {
        if (fetch_output.status) {
          setWeightMinMaxList(fetch_output.data);
        } else {
          showMessage({ type: "warning", message: fetch_output.error });
        }
      },
      onFetchError: () => {
        showMessage({ apiType: "fetch", message: "get weight min max list" });
      },
      onResponseError: () => {
        showMessage({
          apiType: "response",
          message: "Get Weigth Min Max List",
        });
      },
    });
  }, [fetchWrapper]);

  return {
    getWeightMinMaxList,
    weightMinMaxList,
    weightMinMaxLoading,
  };
}

export default useGetWeightMinMaxList;
