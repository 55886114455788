import { Form } from "antd";
import useGetDiameterTypeOption from "../../../functions/Options/getDiameterTypeOption";
import CustomModal from "../../common/CustomModal";
import CustomSearchForm from "../../common/CustomSearchForm";
import useGetFactoryOption from "../../../functions/Options/getFactoryOption";
import useGetProductTypeOption from "../../../functions/Options/getProductTypeOption";
import dayjs from "dayjs";

const DAILY_BUNDLE_SORT = [
  {
    label: "Date",
    value: "date",
  },
  {
    label: "Product",
    value: "product",
  },
  {
    label: "Type",
    value: "type",
  },
  {
    label: "Total Bundles",
    value: "total_bundles",
  },
  {
    label: "Actual Bundles",
    value: "actual_bundles",
  },
  {
    label: "Missing Bundles",
    value: "missing_bundles",
  },
];

export default function DailyBundleSearchForm({
  modalOpen,
  setModalOpen,
  tableData,
  setFilterTable,
}) {
  const [dailyBundleSearch] = Form.useForm();
  const { diameterOption } = useGetDiameterTypeOption();
  const { factoryOption } = useGetFactoryOption();
  const { typeOption } = useGetProductTypeOption();

  const DAILY_BUNDLE_FILTER = [
    {
      label: "Factory",
      name: "factory",
      type: "select",
      options: factoryOption,
    },
    {
      label: "Product",
      name: "product",
      type: "select",
      options: diameterOption,
    },
    {
      label: "Type",
      name: "type",
      type: "select",
      options: typeOption,
    },
    {
      label: "Date",
      name: "date",
      type: "datetime-select",
    },
  ];

  // To handle the Advanced Search Form Result
  const handleSubmit = (value) => {
    setModalOpen(false);
    const filter = filterData(tableData, value);
    const sort = sortData(filter, value.sortTags);
    setFilterTable(sort);
  };

  // Function to filter data
  const filterData = (data, filter) => {
    return data.filter((item) => {
      return Object.keys(filter).every((key) => {
        const filterValue = filter[key];
        const itemValue = item[key];

        if (key === "sortTags") return true;
        if (filterValue === undefined || filterValue === null) return true;

        if (key === "date") {
          const dateValue = dayjs(filterValue).format("YYYY-MM-DD");
          return itemValue == dateValue;
        }

        return String(itemValue).includes(String(filterValue));
      });
    });
  };

  // Function to sort data
  const sortData = (data, sort) => {
    return data.sort((a, b) => {
      for (const { sortField, sortOrder } of sort) {
        const fieldA = a[sortField];
        const fieldB = b[sortField];

        let comparison = 0;
        if (typeof fieldA === "string" && typeof fieldB === "string") {
          comparison = fieldA.localeCompare(fieldB);
        } else {
          comparison = fieldA < fieldB ? -1 : fieldA > fieldB ? 1 : 0;
        }

        if (sortOrder === "Descending") comparison *= -1;
        if (comparison !== 0) return comparison;
      }
      return 0;
    });
  };

  return (
    <CustomModal
      title={"Advanced Search Form"}
      close_icon={true}
      visible={modalOpen}
      onCancel={() => setModalOpen(false)}
      content={
        <CustomSearchForm
          form={dailyBundleSearch}
          onSubmit={handleSubmit}
          filterForm={DAILY_BUNDLE_FILTER}
          sorterOption={DAILY_BUNDLE_SORT}
        />
      }
    />
  );
}
