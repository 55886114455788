import { Card } from "antd";
import CustomDescription from "../common/CustomDescription";

export default function DemoLorrySummary() {
  const LORRY_DESCRIPTION = [
    {
      label: "Total Lorries",
      key: "total_lorries",
      children: 3,
    },
    {
      label: "Checked-In Lorries",
      key: "checked_in_lorries",
      children: 1,
    },
    {
      label: "Checked-Out Lorries",
      key: "checked_out_lorries",
      children: 1,
    },
    {
      label: "Unchecked Lorries",
      key: "unchecked_lorries",
      children: 1,
    },
  ];

  return (
    <Card className="summary-card">
      <CustomDescription items={LORRY_DESCRIPTION} column={4} />
    </Card>
  );
}
