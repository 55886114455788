import { Card, Col, ColorPicker, Flex, List, Row, Tabs } from "antd";
import dayjs from "dayjs";
import { Calendar, dayjsLocalizer, Views } from "react-big-calendar";
import withDragAndDrop from "react-big-calendar/lib/addons/dragAndDrop";
import "react-big-calendar/lib/css/react-big-calendar.css";
import "react-big-calendar/lib/addons/dragAndDrop/styles.css"; // For Drag and Drop styles
import { useCallback, useEffect, useState } from "react";
import { DeleteOutlined, DragOutlined } from "@ant-design/icons";
import FunctionalButton from "../../components/common/FunctionalButton";
import DemoEventModal from "../../components/demo/DemoEventModal";

const localizer = dayjsLocalizer(dayjs);
const DragAndDropCalendar = withDragAndDrop(Calendar);

const defaultItem = [
  {
    key: 4,
    title: "DOP325252",
    value: {
      do_number: "DOP325252",
      so_number: "MSW 22794",
      customer: "PixeVision",
      address:
        "2, Jalan Puteri 5/18,\n Bandar Puteri,\n 47100 Puchong,\n Selangor",
      product: "Y10",
      specification: "GRADE B250",
      lorry_plate: "PHD 6366",
      number_of_bundles: 10,
      create_date_time: "2024-12-24 11:12:13",
      last_update_date_time: "2024-12-24 11:15:23",
    },
    start: new Date(2024, 12, 21, 18, 0, 0),
    end: new Date(2024, 12, 21, 19, 0, 0),
    draggable: false,
    color: "#16ffad",
    resourceId: 1,
  },
];

const defaultResources = [
  { id: 1, title: "IRU 1293" },
  { id: 2, title: "PHD 6366" },
  { id: 3, title: "KPI 3948" },
];

export default function DemoCalendarPage() {
  const [calendarEvents, setCalendarEvents] = useState(defaultItem);
  const [draggedEvent, setDraggedEvent] = useState(null);
  const [currentView, setCurrentView] = useState(Views.WEEK);
  const [selectEvent, setSelectEvent] = useState(null);
  const [eventModal, setEventModal] = useState(false);
  const [colorValue, setColorValue] = useState("#E6F4F1");

  const draggableItems = [
    {
      key: 1,
      title: "DOP163243",
      value: {
        do_number: "DOP163243",
        so_number: "MSW 22456",
        customer: "ASPL",
        address:
          "2, Jalan Puteri 5/18,\n Bandar Puteri,\n 47100 Puchong,\n Selangor",
        product: "Y12",
        specification: "MS146: 2014",
        lorry_plate: "IRU 1293",
        number_of_bundles: 15,
        delivery_date_time: "2024-12-25 16:00:00",
        create_date_time: "2024-12-24 11:14:53",
        last_update_date_time: "2024-12-24 11:18:56",
      },
    },
    {
      key: 2,
      title: "DOP644342",
      value: {
        do_number: "DOP644342",
        so_number: "MSW 27453",
        customer: "Masteel",
        address:
          "Petaling Jaya,\n 29C, Jln Tandang,\n Seksyen 51,\n 46050 Petaling Jaya,\n Selangor",
        product: "Y10",
        specification: "GRADE B250",
        lorry_plate: "PHD 6366",
        number_of_bundles: 8,
        delivery_date_time: "2024-12-25:15:30:00",
        create_date_time: "2024-12-24 11:16:53",
        last_update_date_time: "2024-12-24 11:16:53",
      },
    },
    {
      key: 3,
      title: "DOP325323",
      value: {
        do_number: "DOP325323",
        so_number: "MSW 25436",
        customer: "Open RE",
        address:
          "2, Jalan Puteri 5/18,\n Bandar Puteri,\n 47100 Puchong,\n Selangor",
        product: "Y14",
        specification: "MS146: 2014",
        lorry_plate: "IRU 1293",
        number_of_bundles: 5,
        delivery_date_time: "2024-12-25 17:00:00",
        create_date_time: "2024-12-24 11:18:53",
        last_update_date_time: "2024-12-24 11:20:23",
      },
    },
  ];

  const handleDragStart = useCallback((event) => setDraggedEvent(event), []);

  const onEventDrop = useCallback(({ event, start, end, resourceId }) => {
    if (!event.draggable) return;

    const isAllDay =
      start.getHours() === 0 &&
      start.getMinutes() === 0 &&
      end.getHours() === 0 &&
      end.getMinutes() === 0;

    if (isAllDay) {
      console.log("Moving events to All Day row is not allowed.");
      return;
    }

    setCalendarEvents((prev) => {
      const updatedEvents = prev.map((existingEvent) =>
        existingEvent.key === event.key
          ? { ...existingEvent, start, end, allDay: false, resourceId }
          : existingEvent
      );
      return updatedEvents;
    });
  }, []);

  const onEventResize = useCallback(({ event, start, end }) => {
    if (!event.draggable) return;

    setCalendarEvents((prev) => {
      const updatedEvents = prev.map((existingEvent) =>
        existingEvent.key === event.key
          ? { ...existingEvent, start, end, allDay: false }
          : existingEvent
      );
      return updatedEvents;
    });
  }, []);

  const onDragStart = useCallback(({ event }) => {
    console.log("Drag Start Event: ", event);
  }, []);

  const onDropFromOutside = useCallback(
    ({ start, end, resource }) => {
      if (!draggedEvent) return;

      const isAllDay =
        start.getHours() === 0 &&
        start.getMinutes() === 0 &&
        end.getHours() === 0 &&
        end.getMinutes() === 0;

      if (isAllDay) {
        console.log("Drop in All Day row is not allowed.");
        return;
      }

      const draggableEvent = {
        title: draggedEvent.title,
        key: draggedEvent.key,
        value: draggedEvent.value,
        resourceId: resource,
        color: colorValue,
        allDay: false,
        draggable: true,
        start,
        end,
      };
      setCalendarEvents((prev) => [...prev, draggableEvent]);
      setDraggedEvent(null);
    },
    [draggedEvent]
  );

  const dragFromOutsideItem = useCallback(() => {
    return draggedEvent
      ? {
          title: draggedEvent.title,
          key: draggedEvent.key,
        }
      : null;
  }, [draggedEvent]);

  const isItemInCalendar = (item) => {
    return calendarEvents.some((event) => event.key === item.key);
  };

  const handleDelete = (item) => {
    setCalendarEvents((prev) => {
      const updatedEvents = prev.filter((event) => event.key !== item.key);
      return updatedEvents;
    });
  };

  const handleViewChange = (view) => setCurrentView(view);
  const handleEventSelect = (value) => {
    setSelectEvent(value);
    setEventModal(true);
  };
  const handleColorChange = (value) => setColorValue(value.toHexString());

  const draggableAccessor = (event) => {
    // if (event.draggable == undefined) {
    //   const filterDrag = calendarEvents.find((prev) => prev.key === event.key);
    //   if (filterDrag !== undefined && filterDrag.draggable !== undefined) {
    //     return currentView !== Views.MONTH && filterDrag.draggable;
    //   }
    // }

    // return currentView !== Views.MONTH && event.draggable;
    return currentView !== Views.MONTH;
  };

  // Helper function to calculate luminance
  const getLuminance = (r, g, b) => {
    const a = [r, g, b].map(function (v) {
      v /= 255;
      return v <= 0.03928 ? v / 12.92 : Math.pow((v + 0.055) / 1.055, 2.4);
    });
    return a[0] * 0.2126 + a[1] * 0.7152 + a[2] * 0.0722;
  };

  // Helper function to check if color is dark
  const isColorDark = (color) => {
    // Remove the '#' if present
    if (color[0] === "#") {
      color = color.slice(1);
    }

    // Convert hex to RGB
    let r = parseInt(color.slice(0, 2), 16);
    let g = parseInt(color.slice(2, 4), 16);
    let b = parseInt(color.slice(4, 6), 16);

    // Calculate luminance
    const luminance = getLuminance(r, g, b);

    // If luminance is below a threshold (0.5), the color is considered dark
    return luminance < 0.5;
  };

  useEffect(() => {
    console.log("Calendar Events: ", calendarEvents);
  }, [calendarEvents]);

  return (
    <Row style={{ height: "100%" }}>
      <Col span={6}>
        <Card className="list-card" style={{ margin: "8px" }}>
          <Flex
            vertical="vertical"
            gap={"middle"}
            justify="space-between"
            style={{ height: "100%" }}
          >
            <Tabs
              items={[
                {
                  key: "current",
                  label: "Current",
                  children: (
                    <List
                      dataSource={draggableItems}
                      renderItem={(item) => (
                        <List.Item
                          key={item.key}
                          draggable={!isItemInCalendar(item)}
                          onDragStart={() => handleDragStart(item)}
                          className="list-item"
                        >
                          <FunctionalButton
                            buttonClickFunction={() => handleEventSelect(item)}
                            type="link"
                            children={item.title}
                          />
                          {currentView != Views.MONTH &&
                            (isItemInCalendar(item) ? (
                              <FunctionalButton
                                icon={<DeleteOutlined />}
                                buttonClickFunction={() => handleDelete(item)}
                                width="40px"
                                danger
                              />
                            ) : (
                              <FunctionalButton
                                icon={<DragOutlined />}
                                buttonClickFunction={() => {}}
                                width="40px"
                                type="ghost"
                                disabled={true}
                              />
                            ))}
                        </List.Item>
                      )}
                    />
                  ),
                },
                {
                  key: "old",
                  label: "Old",
                  children: (
                    <List
                      dataSource={defaultItem}
                      renderItem={(item) => (
                        <List.Item
                          className="list-item"
                          key={item.key}
                          children={
                            <FunctionalButton
                              buttonClickFunction={() =>
                                handleEventSelect(item)
                              }
                              type="link"
                              children={item.title}
                            />
                          }
                        />
                      )}
                    />
                  ),
                },
              ]}
              tabBarExtraContent={
                <ColorPicker value={colorValue} onChange={handleColorChange} />
              }
            />
            <Flex gap={"small"}>
              <FunctionalButton
                buttonClickFunction={() => {}}
                danger
                width="100%"
                children={"Reset"}
                show_confirmation={true}
                confirmation_message={{
                  title: "Confirm to reset the calendar event ?",
                }}
              />
              <FunctionalButton
                buttonClickFunction={() => {}}
                width="100%"
                children={"Save"}
                show_confirmation={true}
                confirmation_message={{
                  title: "Confirm to save the calendar event ?",
                }}
              />
            </Flex>
          </Flex>
        </Card>
      </Col>
      <Col span={18}>
        <Card className="context-card">
          <DragAndDropCalendar
            selectable
            resizable
            key={calendarEvents.length}
            localizer={localizer}
            events={calendarEvents}
            style={{ height: "84vh" }}
            resources={defaultResources}
            resourceIdAccessor="id"
            resourceTitleAccessor="title"
            resourceGroupingLayout={true}
            onEventDrop={onEventDrop}
            onEventResize={onEventResize}
            onDragStart={onDragStart}
            onDropFromOutside={onDropFromOutside}
            onSelectEvent={handleEventSelect}
            dragFromOutsideItem={dragFromOutsideItem}
            showMultiDayTimes={true}
            draggableAccessor={draggableAccessor}
            allDayAccessor={() => false}
            defaultView={Views.WEEK}
            views={[Views.MONTH, Views.WEEK, Views.DAY, Views.AGENDA]}
            onView={handleViewChange}
            eventPropGetter={(event) => {
              const backgroundColor = event.color ? event.color : "#E6F4F1";
              const isDark = isColorDark(backgroundColor);
              return {
                style: {
                  backgroundColor,
                  color: isDark ? "white" : "black",
                },
              };
            }}
            components={{
              timeSlotWrapper: ({ children, ...props }) => (
                <div {...props} style={{ minHeight: "40px" }}>
                  {children}
                </div>
              ),
            }}
          />
        </Card>
      </Col>
      <DemoEventModal
        modalOpen={eventModal}
        setModalOpen={setEventModal}
        eventDetails={selectEvent}
      />
    </Row>
  );
}
