import CustomLineChart from "../../../chart/CustomLineChart";

export default function OperatorBundlesChart({ chartData }) {
  return (
    <CustomLineChart
      data={chartData}
      isGroup={true}
      seriesField={"type"}
      xField={"time"}
      xAxis={"Date"}
      yAxis={"Quantity"}
      chartSize={"23vh"}
    />
  );
}
