import { Button, Card, Flex, Typography } from "antd";
import DeliveryOrderTable from "../../components/delivery_order/DeliveryOrderTable";
import { DownloadOutlined, PlusOutlined } from "@ant-design/icons";
import DeliveryOrderForm from "../../components/delivery_order/DeliveryOrderForm";
import { useContext, useEffect, useState } from "react";
import useInsertDeliverOrder from "../../functions/DeliveryOrder/insertDeliverOrder";
import useGetDeliverOrderList from "../../functions/DeliveryOrder/getDeliverOrderList";
import useRemoveDeliverOrder from "../../functions/DeliveryOrder/removeDeliverOrder";
import useUpdateDeliverOrder from "../../functions/DeliveryOrder/updateDeliverOrder";
import useGetDeliveryOrderPreview from "../../functions/DeliveryOrder/getDeliveryOrderPreview";
import DeliveryOrderPreviewModal from "../../components/delivery_order/DeliveryOrderPreviewModal";
import useDownloadMillCert from "../../functions/DeliveryOrder/downloadMillCert";
import DeliveryOrderSearchForm from "../../components/delivery_order/DeliveryOrderSearchForm";
import useGetMillCertFileList from "../../functions/DeliveryOrder/getMillCertFileList";
import MillCertFileTable from "../../components/delivery_order/MillCertFileTable";
import { IndexContext } from "../../context/IndexContext";
import dayjs from "dayjs";
import CustomRangePicker from "../../components/common/CustomRangePicker";

const { Text } = Typography;

export default function DeliveryOrderPage() {
  // For handle Delivery Order Form modal
  const [deliveryOrderModal, setDeliveryOrderModal] = useState(false);
  // For handle Delivery Order Preview modal
  const [previewModal, setPreviewModal] = useState(false);
  // For handle Delivery Order Search modal
  const [searchModal, setSearchModal] = useState(false);
  // For handle specific Delivery Order Mill Cert File List Modal
  const [fileModal, setFileModal] = useState(false);
  // For handle select multiple table row data
  const [selectRow, setSelectRow] = useState([]);

  const [dateValue, setDateValue] = useState(null);

  const { messageApi } = useContext(IndexContext);
  // For handle filter Delivery Order data
  const [filterDeliveryOrder, setFilterDeliveryOrder] = useState([]);
  // For handle Delivery Order edit data
  const [editData, setEditData] = useState({});

  // Api Function to get Delivery Order List
  const { getDeliverOrderList, deliverOrderList, tableLoading } =
    useGetDeliverOrderList();
  // Api Function to insert Delivery Order
  const { insertDeliverOrder, insertLoading } = useInsertDeliverOrder();
  // Api Function to remove Delivery Order
  const { removeDeliverOrder, deliverOrderDeleteLoading } =
    useRemoveDeliverOrder();
  // Api Function to update Delivery Order
  const { updateDeliverOrder, updateLoading } = useUpdateDeliverOrder();
  // Api Function to get Delivery Order Preview data
  const {
    getDeliveryOrderPreview,
    previewSummary,
    previewTable,
    previewMillCert,
    previewLoading,
  } = useGetDeliveryOrderPreview();
  // Api Function to download Mill Cert File
  const { downloadMillCert, downloadLoading } = useDownloadMillCert();
  // Api Function to get Mill Cert File List
  const { getMillCertFileList, fileList, fileListLoading } =
    useGetMillCertFileList();

  const onDateChange = (value) => setDateValue(value);

  // Function to call get Delivery Order List
  const handleDeliveryOrderList = () =>
    getDeliverOrderList(dateValue[0], dateValue[1]);
  // Function to handle submit form data
  const handleFormSubmit = (value, key) => {
    if (key == null) {
      insertDeliverOrder(value, handleDeliveryOrderList, setDeliveryOrderModal);
    } else
      updateDeliverOrder(
        value,
        key,
        handleDeliveryOrderList,
        setDeliveryOrderModal
      );
  };
  // Function to open edit modal and handle edit data
  const handleEditOpen = (value) => {
    setDeliveryOrderModal(true);
    setEditData(value);
  };
  // Function to call remove delivery order data
  const handleDeleteSubmit = (key) =>
    removeDeliverOrder(key, handleDeliveryOrderList);
  // Function to open preview modal and call get Delivery Order Preview
  const handlePreviewOpen = (key) =>
    getDeliveryOrderPreview(key, setPreviewModal);
  // Function to call get mill cert file list
  const handleFileOpen = (key) => getMillCertFileList(key, setFileModal);
  // Function to download multiple mill cert file
  const handleDownloadMultipleFile = () => {
    if (selectRow.some((item) => item.complete == false)) {
      messageApi.open({
        type: "warning",
        content: "There are some delivery order doesn't complete",
      });
    } else {
      downloadMillCert(selectRow, handleDeliveryOrderList);
    }
  };
  // Function to download mill cert file
  const handleDownload = (value) => {
    downloadMillCert([value], handleDeliveryOrderList);
  };
  // Function to reset filter data
  const resetFilter = () => setFilterDeliveryOrder(deliverOrderList);

  // For filter delivery order data
  useEffect(() => {
    setFilterDeliveryOrder(deliverOrderList);
  }, [deliverOrderList]);

  // For get the delivery order data
  useEffect(() => {
    if (dateValue != null) {
      handleDeliveryOrderList();
    }
  }, []);

  return (
    <>
      <Card
        title={
          <Flex justify="space-between" align="center" wrap="wrap" gap="small">
            <Text>Delivery Order Table (Mill Cert Generation)</Text>
            <Flex gap="middle" wrap="wrap">
              <CustomRangePicker
                onDateChange={onDateChange}
                disabled={tableLoading}
                search={true}
                showTime={false}
                onFilterClick={handleDeliveryOrderList}
              />
              <Button
                icon={<DownloadOutlined />}
                disabled={tableLoading || selectRow.length == 0}
                loading={downloadLoading}
                onClick={handleDownloadMultipleFile}
              />
              <Button
                children="Reset"
                disabled={tableLoading}
                onClick={resetFilter}
              />
              <Button
                children="Advanced Search"
                type="primary"
                onClick={() => setSearchModal(true)}
                disabled={tableLoading}
              />
              <Button
                icon={<PlusOutlined style={{ color: "white" }} />}
                onClick={() => setDeliveryOrderModal(true)}
                style={{ backgroundColor: "#0000A3" }}
                disabled={tableLoading}
              />
            </Flex>
          </Flex>
        }
        className="context-card"
        loading={tableLoading}
      >
        <DeliveryOrderTable
          tableData={filterDeliveryOrder}
          handleEdit={handleEditOpen}
          handleDelete={handleDeleteSubmit}
          handlePreview={handlePreviewOpen}
          handleDownload={handleDownload}
          handleFile={handleFileOpen}
          handleSelectRow={setSelectRow}
          previewLoading={previewLoading}
          fileListLoading={fileListLoading}
          downloadLoading={downloadLoading}
          deleteLoading={deliverOrderDeleteLoading}
        />
      </Card>
      <DeliveryOrderForm
        modalOpen={deliveryOrderModal}
        setModalOpen={setDeliveryOrderModal}
        editData={editData}
        setEditData={setEditData}
        onSubmit={handleFormSubmit}
        loading={insertLoading || updateLoading}
      />
      <DeliveryOrderPreviewModal
        modalOpen={previewModal}
        setModalOpen={setPreviewModal}
        summaryData={previewSummary}
        tableData={previewTable}
        millCertType={previewMillCert}
      />
      <DeliveryOrderSearchForm
        modalOpen={searchModal}
        setModalOpen={setSearchModal}
        tableData={deliverOrderList}
        setFilterTable={setFilterDeliveryOrder}
      />
      <MillCertFileTable
        modalOpen={fileModal}
        setModalOpen={setFileModal}
        tableData={fileList}
      />
    </>
  );
}
