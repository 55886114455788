import CustomLineChart from "../../chart/CustomLineChart";

export default function ShiftChart({ chartData }) {
  return (
    <CustomLineChart
      data={chartData}
      isGroup={true}
      seriesField={"type"}
      xField={"time"}
      xAxis={"Date"}
      yAxis={"Quantity"}
      chartSize={"45vh"}
    />
  );
}
