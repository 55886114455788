import { useCallback, useState } from "react";
import useFetch from "../../../hooks/useFetch";
import { useShowMessage } from "../../Common/showMessage";

function useGetOperatorData() {
  const [fetchWrapper] = useFetch();
  const [operatorTableData, setOperatorTableData] = useState([]);
  const [operatorColumn, setOperatorColumn] = useState([]);
  const [operatorChartData, setOperatorChartData] = useState([]);
  const [operatorDownload, setOperatorDownload] = useState([]);

  const showMessage = useShowMessage();

  const getOperatorData = useCallback(
    (factory, diameter, operator, startDate, endDate, time, setIsLoading) => {
      setIsLoading(true);
      fetchWrapper({
        endpoint_url: "statistics/getOperatorData",
        params: {
          factory: factory,
          diameter: diameter,
          operator: operator,
          startDate: startDate,
          endDate: endDate,
          time: time,
        },
        setLoading: setIsLoading,
        onSuccess: (fetch_output) => {
          if (fetch_output.status) {
            setOperatorTableData(fetch_output.table_data);
            setOperatorColumn(fetch_output.table_column);
            setOperatorChartData(fetch_output.chart_data);
            setOperatorDownload(fetch_output.download_data);
          } else {
            showMessage({ type: "warning", message: fetch_output.error });
          }
        },
        onFetchError: () => {
          showMessage({ apiType: "fetch", message: "get operator table" });
        },
        onResponseError: () => {
          showMessage({ apiType: "response", message: "Get Operator Table" });
        },
      });
    },
    [fetchWrapper]
  );

  return {
    getOperatorData,
    operatorTableData,
    operatorColumn,
    operatorChartData,
    operatorDownload,
  };
}

export default useGetOperatorData;
