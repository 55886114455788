import useFetch from "../../hooks/useFetch";
import { useCallback, useState } from "react";
import { useShowMessage } from "../Common/showMessage";

function useGetRebarOverallAccuracy() {
  const [fetchWrapper] = useFetch();
  const [accuracyLoading, setAccuracyLoading] = useState(false);
  const [overallAccuracy, setOverallAccuracy] = useState("-");

  const showMessage = useShowMessage();

  const getRebarOverallAccuracy = useCallback(
    (navigateBack) => {
      fetchWrapper({
        endpoint_url: "rebar_verification/getRebarOverallAccuracy",
        setLoading: setAccuracyLoading,
        onSuccess: (fetch_output) => {
          if (fetch_output.status) {
            setOverallAccuracy(fetch_output.accuracy);
          } else {
            showMessage({ type: "warning", message: fetch_output.error });
            navigateBack();
          }
        },
        onFetchError: () => {
          showMessage({
            apiType: "fetch",
            message: "get rebar overall accuracy",
          });
          navigateBack();
        },
        onResponseError: () => {
          showMessage({ apiType: "response", message: "Get Rebar Info" });
          navigateBack();
        },
      });
    },
    [fetchWrapper]
  );

  return {
    getRebarOverallAccuracy,
    overallAccuracy,
    accuracyLoading,
  };
}

export default useGetRebarOverallAccuracy;
