import { useCallback, useEffect, useState } from "react";
import useFetch from "../../hooks/useFetch";
import { useShowMessage } from "../Common/showMessage";
import useRetry from "../../hooks/useRetry";
import { MAX_RETRIES } from "../../configs/configs";

function useGetMillCertSpecificationOption() {
  const [fetchWrapper] = useFetch();
  const [millCertSpecificationOption, setMillCertSpecificationOption] =
    useState([]);

  const showMessage = useShowMessage();
  const { retryCount, setRetryCount } = useRetry(() =>
    getMillCertSpecification()
  );

  const getMillCertSpecification = useCallback(() => {
    fetchWrapper({
      endpoint_url: "options/getMillCertSpecificationOption",
      onSuccess: (fetch_output) => {
        if (fetch_output.status) {
          setMillCertSpecificationOption(fetch_output.options);
          setRetryCount(0);
        } else {
          showMessage({ type: "warning", message: fetch_output.error });
        }
      },
      onFetchError: () => {
        if (retryCount === MAX_RETRIES)
          showMessage({
            apiType: "fetch",
            message: "get mill cert specification option",
          });

        setRetryCount((prev) => prev + 1);
      },
      onResponseError: () => {
        if (retryCount === MAX_RETRIES)
          showMessage({
            apiType: "response",
            message: "Get Mill Cert Specification Option",
          });

        setRetryCount((prev) => prev + 1);
      },
    });
  }, [fetchWrapper]);

  useEffect(() => {
    getMillCertSpecification();
  }, []);

  return { millCertSpecificationOption };
}

export default useGetMillCertSpecificationOption;
