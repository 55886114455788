import useFetch from "../../hooks/useFetch";
import { useCallback, useState } from "react";
import { useShowMessage } from "../Common/showMessage";

function useGetMillCertFileList() {
  const [fetchWrapper] = useFetch();
  const [fileListLoading, setFileListLoading] = useState(false);
  const [fileList, setFileList] = useState([]);

  const showMessage = useShowMessage();

  const getMillCertFileList = useCallback(
    (key, setFileModal) => {
      setFileListLoading(true);
      fetchWrapper({
        endpoint_url: "delivery_order/getMillCertFileList",
        params: {
          key: key,
        },
        setLoading: setFileListLoading,
        onSuccess: (fetch_output) => {
          if (fetch_output.status) {
            setFileList(fetch_output.data);
            setFileModal(true);
          } else {
            showMessage({ type: "warning", message: fetch_output.message });
          }
        },
        onFetchError: () => {
          showMessage({ apiType: "fetch", message: "get mill cert file list" });
        },
        onResponseError: () => {
          showMessage({
            apiType: "response",
            message: "Get Mill Cert File List",
          });
        },
      });
    },
    [fetchWrapper]
  );

  return { getMillCertFileList, fileList, fileListLoading };
}

export default useGetMillCertFileList;
