import { useCallback, useState } from "react";
import useFetch from "../../../hooks/useFetch";
import { useShowMessage } from "../../Common/showMessage";

function useGetProductionBundles() {
  const [fetchWrapper] = useFetch();
  const [productionChart, setProductionChart] = useState([]);
  const [productionSummary, setProductionSummary] = useState({});

  const showMessage = useShowMessage();

  const getProductionBundles = useCallback(
    (setIsLoading) => {
      setIsLoading(true);
      fetchWrapper({
        endpoint_url: "dashboard/getProductionBundles",
        setLoading: setIsLoading,
        onSuccess: (fetch_output) => {
          if (fetch_output.status) {
            setProductionChart(fetch_output.chart_data);
            setProductionSummary(fetch_output.summary_data);
          } else {
            showMessage({ type: "warning", message: fetch_output.error });
          }
        },
        onFetchError: () => {
          showMessage({ apiType: "fetch", message: "get storage bundles" });
        },
        onResponseError: () => {
          showMessage({ apiType: "response", message: "Get Storage Bundles" });
        },
      });
    },
    [fetchWrapper]
  );

  return { getProductionBundles, productionChart, productionSummary };
}

export default useGetProductionBundles;
