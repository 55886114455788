import { useCallback, useState } from "react";
import useFetch from "../../../hooks/useFetch";
import { useShowMessage } from "../../Common/showMessage";

function useGetShiftBundles() {
  const [fetchWrapper] = useFetch();
  const [shiftChart, setShiftChart] = useState([]);
  const [shiftSummary, setShiftSummary] = useState({});

  const showMessage = useShowMessage();

  const getShiftBundles = useCallback(
    (setIsLoading) => {
      setIsLoading(true);
      fetchWrapper({
        endpoint_url: "dashboard/getShiftBundles",
        setLoading: setIsLoading,
        onSuccess: (fetch_output) => {
          if (fetch_output.status) {
            setShiftChart(fetch_output.chart_data);
            setShiftSummary(fetch_output.summary_data);
          } else {
            showMessage({ type: "warning", message: fetch_output.error });
          }
        },
        onFetchError: () => {
          showMessage({ apiType: "fetch", message: "get shift bundles" });
        },
        onResponseError: () => {
          showMessage({ apiType: "response", message: "Get Shift Bundles" });
        },
      });
    },
    [fetchWrapper]
  );

  return { getShiftBundles, shiftChart, shiftSummary };
}

export default useGetShiftBundles;
