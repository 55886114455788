import { Route } from "react-router-dom";
import ProtectedRoute from "./ProtectedRoutes";
import React from "react";
import {
  account,
  operator,
  supervisor,
  qcOperator,
  demo,
} from "../configs/configs";

const IGNORE_FOLDER = [];
const IGNORE_PAGE = ["Login", "Login_Record"];
const ROUTE_PATH_PARAMS = { Rebar_Verification: "/:rebar_key" };
const DISABLE_USER_TYPE = {
  Delivery_Order_Connection: [account, operator, demo],
  Dashboard: [operator, supervisor, account, qcOperator, demo],
  Statistics: [operator, supervisor, account, qcOperator, demo],
  Traceability: [operator, supervisor, account, qcOperator, demo],
  DOprogress: [operator, account, qcOperator, demo],
  Delivery_Order: [operator, qcOperator, demo],
  Material_Properties: [operator, supervisor, account, demo],
  Ai_Rebar_Count: [account, qcOperator, demo],
  Bundle_List: [operator, account, qcOperator, demo],
  Rebar_Verification: [account, qcOperator, demo],
  Configuration: [operator, supervisor, account, qcOperator, demo],
};

export function generateRoute() {
  const pageModules = require.context("../pages", true, /.\jsx?$/);

  return pageModules.keys().reduce((routes, filePath) => {
    const component = pageModules(filePath).default;
    const formattedPath = filePath
      .replace("./", "/") // Convert to route format
      .replace(".js", "") // Remove file extension
      .replace(/([a-z0-9])([A-Z])/g, "$1_$2") // Convert camelCase to kebab-case
      .toLowerCase() // Convert everything to lowercase
      .replace(/_page$/, ""); // Remove "page" at the end

    const formattedName = formattedPath
      .split("/")
      .map((segment) =>
        segment
          .split("_")
          .map((word) => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize each word
          .join("_")
      )
      .join("/");

    // Split path into parts
    const pathParts = formattedName.split("/");

    for (let path of pathParts) {
      if (IGNORE_FOLDER.includes(path) || IGNORE_PAGE.includes(path))
        return routes;
    }

    // Check if the last part is a duplicate of the previous one
    if (
      pathParts.length > 1 &&
      pathParts[pathParts.length - 1] === pathParts[pathParts.length - 2]
    ) {
      pathParts.pop(); // Remove duplicate last part
    }

    const routeParent = pathParts[1];
    const routeName = pathParts[pathParts.length - 1];
    const routeParams = ROUTE_PATH_PARAMS[routeName] || "";
    const routePath = pathParts.join("/") + routeParams;

    routes.push({
      path: routePath,
      name: routeName,
      parent: routeParent,
      component,
      disabledUserType: DISABLE_USER_TYPE[routeName] || [],
    });

    return routes;
  }, []);
}

export const renderRoutes = (routes) => {
  return routes.map((route) => (
    <Route
      key={route.name}
      path={route.path}
      element={
        <ProtectedRoute
          disabledUserTypes={route.disabledUserType}
          children={React.createElement(route.component)}
        />
      }
    />
  ));
};
