import CustomColumnChart from "../../chart/CustomColumnChart";

export default function InventoryBarChart({ data }) {
  return (
    <CustomColumnChart
      data={data}
      isGroup={true}
      seriesField={"type"}
      xField={"time"}
      xAxis={"Monthly"}
      yAxis={"Quantity"}
      chartSize={"23vh"}
      labelOffset={0}
      labelRotate={7.87}
    />
  );
}
