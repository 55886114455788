import { Flex, Tag } from "antd";
import { useState } from "react";
import FunctionalButton from "../../common/FunctionalButton";
import { EyeOutlined } from "@ant-design/icons";
import DataTable from "../../common/DataTable";
import ProductDetailsModalTable from "./product_details/ProductDetailsModalTable";

export default function ProductManagementTable({ tableData }) {
  const [productDetails, setProductDetails] = useState([]);
  const [productDetailModal, setProductDetailModal] = useState(false);
  const [productDetailName, setProductDetailName] = useState(null);

  const DIAMETER_TABLE_COLUMN = [
    {
      title: "No.",
      dataIndex: "number",
      key: "number",
      width: 80,
    },
    {
      title: "Diameter Type",
      dataIndex: "diameter_type",
      key: "diameter",
      width: 150,
    },
    {
      title: "Diameter (mm)",
      dataIndex: "diameter",
      key: "diameter",
      width: 120,
    },
    {
      title: "One Bundle (Kg)",
      dataIndex: "one_bundle_kg",
      key: "one_bundle_kg",
      width: 150,
    },
    {
      title: "One Bundle (Tonnes)",
      dataIndex: "one_bundle_tonnes",
      key: "one_bundle_tonnes",
      width: 180,
    },
    {
      title: "Five Bundles (Tonnes)",
      dataIndex: "five_bundles_tonnes",
      key: "five_bundles_tonnes",
      width: 180,
    },
    {
      title: "Pieces",
      dataIndex: "pieces",
      key: "pieces",
      width: 120,
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      width: 120,
      render: (_, record) => {
        return (
          <Tag
            color={record.status ? "success" : "error"}
            children={record.status ? "Activated" : "Unactivated"}
          />
        );
      },
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      width: 150,
      render: (_, record) => {
        return (
          <Flex gap="small">
            <FunctionalButton
              buttonClickFunction={() => {
                setProductDetails(record.view);
                setProductDetailName(record.diameter_type);
                setProductDetailModal(true);
              }}
              width="40px"
              icon={<EyeOutlined />}
            />
          </Flex>
        );
      },
    },
  ];

  return (
    <>
      <DataTable
        table_column={DIAMETER_TABLE_COLUMN}
        table_data={tableData}
        pageSize={10}
        columnCenter={true}
      />
      <ProductDetailsModalTable
        tableData={productDetails}
        productName={productDetailName}
        modalOpen={productDetailModal}
        setModalOpen={setProductDetailModal}
      />
    </>
  );
}
