import { useCallback } from "react";
import useFetch from "../../../hooks/useFetch";
import useDownloadFile from "../../DownloadFile/downloadFile";
import { useShowMessage } from "../../Common/showMessage";

function useDownloadOperator() {
  const [fetchWrapper] = useFetch();
  const { downloadFile } = useDownloadFile();

  const showMessage = useShowMessage();

  const downloadOperator = useCallback(
    (download_data, setDownloadLoading) => {
      setDownloadLoading(true);
      return new Promise((resolve, reject) => {
        fetchWrapper({
          endpoint_url: "statistics/downloadOperator",
          params: {
            download: download_data,
          },
          setLoading: setDownloadLoading,
          onSuccess: (fetch_ouput) => {
            if (fetch_ouput.status) {
              downloadFile(fetch_ouput.folder_path, fetch_ouput.file_name);
            } else {
              showMessage({ type: "warning", message: fetch_ouput.error });
            }
            resolve(fetch_ouput);
          },
          onFailure: (error) => {
            showMessage({ message: error });
            reject(error);
          },
          onFetchError: () => {
            showMessage({
              apiType: "fetch",
              message: "download operator excel",
            });
          },
          onResponseError: () => {
            showMessage({
              apiType: "response",
              message: "Download Operator Excel",
            });
          },
        });
      });
    },
    [fetchWrapper]
  );

  return { downloadOperator };
}

export default useDownloadOperator;
