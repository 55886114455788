import { Flex, Input, Select } from "antd";
import { useContext, useEffect, useState } from "react";
import {
  Scanner as ScannerComp,
  boundingBox,
  useDevices,
} from "@yudiel/react-qr-scanner";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import "../../../styles/qrScanner/QrScanner.css";
import { IndexContext } from "../../../context/IndexContext";
import FunctionalButton from "../../common/FunctionalButton";

function QrScannerFunc({ isLoading, handleConfirm }) {
  // For handle the Device Camera Option
  const [deviceSelect, setDeviceSelect] = useState([]);
  // For handle the Device Camera ID (To change the Camera)
  const [currentDeviceId, setCurrentDeviceId] = useState(null);
  // For handle the QR Scan Pause
  const [scanPause, setScanPause] = useState(false);
  // For handle the QR Scan Result
  const [scanResult, setScanResult] = useState();

  // To get the Device Input that have Video
  const videoDevices = useDevices();

  const { messageApi } = useContext(IndexContext);
  // Function for warning message
  const warningMessage = (message) => {
    messageApi.open({
      type: "warning",
      content: message,
    });
  };

  // Function to get the Device Camera Option
  const getVideoDevices = async (videoDevices) => {
    // Map the Device to options
    const devicesMap = videoDevices
      .map((device) => ({
        value: device.deviceId,
        label: device.label,
      }))
      .sort((a, b) => a.label.localeCompare(b.label));

    // Filter the Camera
    const deviceOptions = filterCamera(devicesMap);
    setDeviceSelect(deviceOptions);

    // Check is the Camera in use or not
    const cameraStatus = await checkCameraInUse();
    if (cameraStatus) setCurrentDeviceId(deviceOptions[0].value);
  };

  // Function to check is the Device Camera in use at other place
  const checkCameraInUse = async () => {
    try {
      // Request access to the user's camera
      const stream = await navigator.mediaDevices.getUserMedia({ video: true });
      // Stop all tracks (video tracks in this case)
      stream.getTracks().forEach((track) => track.stop());

      return true;
    } catch (error) {
      if (error.name === "NotAllowedError") {
        warningMessage("Camera access denied or blocked by the user");
      } else if (error.name === "NotReadableError") {
        warningMessage("Camera is currently in use by another application");
      } else if (error.name === "OverconstrainedError") {
        warningMessage(
          "Camera constraints cannot be satisfied. Try closing other tabs or applications using the camera."
        );
      } else {
        warningMessage("Camera error occurred");
      }
      setScanPause(true);
      return false;
    }
  };

  // Function to filter out the Camera are not Back Camera
  const filterCamera = (devices) => {
    try {
      const backDevices = devices.filter((device) =>
        device.label.endsWith("back")
      );

      if (backDevices.length === 0)
        throw new Error("No back camera devices found");
      return backDevices;
    } catch (error) {
      return devices;
    }
  };

  // To reset QR Scan
  const resetQrScanner = () => {
    setScanResult(null);
    setScanPause(false);
  };

  // To handle QR Scan Result
  const handleScanResult = (value) => {
    setScanResult(value);
    setScanPause(true);
  };

  // To handle cancel QR Scan Result
  const handleCancel = () => {
    resetQrScanner();
  };

  // To submit the QR Scan Result
  const handleConfirmResult = () => {
    handleConfirm(scanResult, resetQrScanner);
  };

  // To check is the Device have Camera or not
  useEffect(() => {
    if (videoDevices.length > 0) {
      getVideoDevices(videoDevices);
    }
  }, [videoDevices]);

  return (
    <Flex vertical="vertical" gap="small" align="center">
      <Select
        options={deviceSelect}
        value={currentDeviceId}
        placeholder="Select Device Camera"
        onChange={(value) => setCurrentDeviceId(value)}
        style={{ width: "100%" }}
      />
      <Flex gap="small" style={{ width: "100%" }}>
        <FunctionalButton
          buttonClickFunction={handleCancel}
          icon={<CloseOutlined />}
          disabled={scanResult == null || isLoading}
          width="15%"
          danger={true}
        />
        <Input
          className="qr-instruction-input"
          value={scanResult}
          readOnly
          placeholder="Please scan the QR on Tag"
        />
        <FunctionalButton
          buttonClickFunction={handleConfirmResult}
          trigger_loading={isLoading}
          show_confirmation={true}
          confirmation_message={{
            title: "Confirmation",
            content: "Please Confirm the Serial Number and Heat Number",
          }}
          icon={<CheckOutlined />}
          disabled={scanResult == null}
          width="15%"
        />
      </Flex>
      <div className="qr-scanner">
        {currentDeviceId != null && (
          <ScannerComp
            formats={[
              "qr_code",
              "micro_qr_code",
              "rm_qr_code",
              "maxi_code",
              "pdf417",
              "aztec",
              "data_matrix",
              "matrix_codes",
              "dx_film_edge",
              "databar",
              "databar_expanded",
              "codabar",
              "code_39",
              "code_93",
              "code_128",
              "ean_8",
              "ean_13",
              "itf",
              "linear_codes",
              "upc_a",
              "upc_e",
            ]}
            allowMultiple={true}
            constraints={{
              deviceId: currentDeviceId,
            }}
            components={{
              audio: true,
              onOff: true,
              tourch: true,
              zoom: true,
              finder: true,
              tracker: boundingBox,
            }}
            onScan={(result) => {
              handleScanResult(result[0].rawValue);
            }}
            onError={(error) => {
              messageApi.open({
                type: "warning",
                content: error,
              });
            }}
            scanDelay={1000}
            paused={scanPause}
          />
        )}
      </div>
    </Flex>
  );
}

export default QrScannerFunc;
