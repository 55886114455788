import { useCallback, useState } from "react";
import useFetch from "../../../hooks/useFetch";
import { useShowMessage } from "../../Common/showMessage";

function useGetAllMechanicalProperties() {
  const [fetchWrapper] = useFetch();
  const [mechanicalProperties, setMechanicalProperties] = useState([]);
  const [mechanicalLoading, setMechanicalLoading] = useState(false);

  const showMessage = useShowMessage();

  const getAllMechanicalProperties = useCallback(
    (startDateSelection, endDateSelection) => {
      setMechanicalLoading(true);
      fetchWrapper({
        endpoint_url: "material_properties/getAllMechanicalProperties",
        params: {
          startDate: startDateSelection,
          endDate: endDateSelection,
        },
        setLoading: setMechanicalLoading,
        onSuccess: (fetch_output) => {
          if (fetch_output.status) {
            setMechanicalProperties(fetch_output.data);
          } else {
            showMessage({ type: "warning", message: fetch_output.error });
          }
        },
        onFetchError: () => {
          showMessage({
            apiType: "fetch",
            message: "get all material properties",
          });
        },
        onResponseError: () => {
          showMessage({
            apiType: "response",
            message: "Get All Material Properties",
          });
        },
      });
    },
    [fetchWrapper]
  );
  return {
    getAllMechanicalProperties,
    mechanicalProperties,
    mechanicalLoading,
  };
}

export default useGetAllMechanicalProperties;
