import dayjs from "dayjs";
import * as XLSX from "xlsx";

export const exportExcel = ({
  data,
  headers,
  width = 20,
  columnWidths = [],
  fileName = "Exported Data",
}) => {
  const mappedData = data.map((row) => {
    const mappedRow = {};
    for (const [key, value] of Object.entries(headers)) {
      mappedRow[value] = row[key];
    }
    return mappedRow;
  });

  const worksheet = XLSX.utils.json_to_sheet(mappedData);
  worksheet["!cols"] = columnWidths.length
    ? columnWidths.map((wch) => ({ wch }))
    : Object.values(headers).map(() => ({ wch: width }));

  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

  XLSX.writeFile(
    workbook,
    `${fileName} ${dayjs().format("YYYY-MM-DD HH:mm:ss")}.xlsx`
  );
};
