import { Button, Table, Typography } from "antd";

export default function DeliverOrderConnectionTable({
  tableData,
  handleLoadDetails,
  setConnectionKey,
}) {
  const DELIVERY_ORDER_CONNECTION_COLUMN = [
    {
      title: "Product",
      dataIndex: "product",
      key: "product",
      width: 80,
    },
    {
      title: "DO",
      dataIndex: "delivery_order",
      key: "delivery_order",
      width: 120,
      render: (_, record) => {
        return (
          <Button
            onClick={() => {
              handleLoadDetails(record.details);
              setConnectionKey(record.key);
            }}
            type="link"
            children={record.delivery_order}
          />
        );
      },
    },
    {
      title: "Date Time",
      dataIndex: "date_time",
      key: "date_time",
      width: 140,
      render: (date_time) => (
        <>
          {date_time.split("\n").map((line, index) => (
            <Typography.Text key={index}>
              {line}
              <br />
            </Typography.Text>
          ))}
        </>
      ),
    },
  ];

  return (
    <Table
      columns={DELIVERY_ORDER_CONNECTION_COLUMN}
      dataSource={tableData}
      pagination={{
        responsive: true,
        defaultPageSize: 8,
        showSizeChanger: true,
        pageSizeOptions: [3, 5, 10, 20, 50, 100],
        position: "bottomRight",
        showTotal: (total, range) =>
          `${range[0]}-${range[1]} of ${total} items`,
      }}
      columnCenter={true}
    />
  );
}
