import { useCallback } from "react";
import useFetch from "../../hooks/useFetch";
import { useShowMessage } from "../Common/showMessage";

function useUpdateRebarVerifyStatus() {
  const [fetchWrapper] = useFetch();

  const showMessage = useShowMessage();

  const updateRebarVerifyStatus = useCallback(
    (rebar_key, navigateBack) => {
      fetchWrapper({
        endpoint_url: "rebar_verification/updateRebarVerifyStatus",
        params: {
          rebar_id: rebar_key,
        },
        onSuccess: (fetch_ouput) => {
          if (fetch_ouput.status) {
            navigateBack();
          }
        },
        onFetchError: () => {
          showMessage({
            apiType: "fetch",
            message: "update rebar verify status",
          });
          navigateBack();
        },
        onResponseError: () => {
          showMessage({
            apiType: "response",
            message: "Update Rebar Verify Status",
          });
          navigateBack();
        },
      });
    },
    [fetchWrapper]
  );

  return { updateRebarVerifyStatus };
}

export default useUpdateRebarVerifyStatus;
