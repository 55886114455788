import { Flex, Typography } from "antd";
import DataTable from "../../common/DataTable";
import { EditOutlined } from "@ant-design/icons";
import FunctionalButton from "../../common/FunctionalButton";

export default function MillCertManagementTable({ tableData, handleEdit }) {
  const MILL_CERT_COLUMN = [
    {
      title: "No.",
      dataIndex: "number",
      key: "number",
      width: 80,
    },
    {
      title: "Mill Cert Type",
      dataIndex: "mill_cert_type",
      key: "mill_cert_type",
      width: 140,
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      width: 140,
    },
    {
      title: "Specification",
      dataIndex: "specification",
      key: "specification",
      width: 140,
    },
    {
      title: "Bend Test",
      dataIndex: "bend_test",
      key: "bend_test",
      width: 140,
    },
    {
      title: "Rebend Test",
      dataIndex: "rebend_test",
      key: "rebend_test",
      width: 140,
    },
    {
      title: "Statement",
      dataIndex: "statement",
      key: "statement",
      width: 280,
    },
    {
      title: "Remark",
      dataIndex: "remark",
      key: "remark",
      width: 280,
      render: (remark) => (
        <>
          {remark.split("\n").map((line, index) => (
            <Typography.Text key={index}>
              {line}
              <br />
            </Typography.Text>
          ))}
        </>
      ),
    },
    {
      title: "Last Update By",
      dataIndex: "last_update_by",
      key: "last_update_by",
      width: 140,
    },
    {
      title: "Last Update Date Time",
      dataIndex: "last_update_date_time",
      key: "last_update_date_time",
      width: 180,
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      width: 100,
      render: (_, record) => {
        return (
          <Flex gap="small">
            <FunctionalButton
              buttonClickFunction={() => handleEdit(record)}
              type=""
              width="40px"
              icon={<EditOutlined />}
            />
          </Flex>
        );
      },
    },
  ];

  return (
    <DataTable
      table_column={MILL_CERT_COLUMN}
      table_data={tableData}
      columnCenter={true}
    />
  );
}
