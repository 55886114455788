import { useCallback, useState } from "react";
import useFetch from "../../../hooks/useFetch";
import { useShowMessage } from "../../Common/showMessage";

function useUploadRebarImage() {
  const [fetchWrapper] = useFetch();
  const [rebarImageLoading, setRebarImageLoading] = useState(false);

  const showMessage = useShowMessage();

  const uploadRebarImage = useCallback(
    (formData, setImageName, setImageSrc) => {
      return new Promise((resolve, reject) => {
        setRebarImageLoading(true);
        fetchWrapper({
          endpoint_url: "ai_rebar_count/uploadRebarImage",
          set_content_type_header: false,
          body: formData,
          setLoading: setRebarImageLoading,
          onSuccess: (fetch_output) => {
            if (fetch_output.status) {
              setImageName(fetch_output.filename);
              setImageSrc(fetch_output.image_src);

              showMessage({ type: "success", message: fetch_output.message });
            } else {
              showMessage({ type: "warning", message: fetch_output.error });
            }
            resolve(fetch_output);
          },
          onFailure: (error) => {
            showMessage({ type: "error", message: error });
            reject(error);
          },
          onFetchError: () => {
            showMessage({ apiType: "fetch", message: "upload rebar image" });
          },
          onResponseError: () => {
            showMessage({ apiType: "response", message: "Upload Rebar Image" });
          },
        });
      });
    },
    [fetchWrapper]
  );
  return { uploadRebarImage, rebarImageLoading };
}

export default useUploadRebarImage;
