import { useCallback, useState } from "react";
import useFetch from "../../../hooks/useFetch";
import { useShowMessage } from "../../Common/showMessage";

function useRemoveBarMark() {
  const [fetchWrapper] = useFetch();
  const [barMarkDeleteLoading, setBarMarkDeleteLoading] = useState(false);

  const showMessage = useShowMessage();

  const removeBarMark = useCallback(
    (key, getBarMarkList) => {
      return new Promise((resolve, reject) => {
        setBarMarkDeleteLoading(false);
        fetchWrapper({
          endpoint_url: "configuration/removeBarMark",
          params: { key: key },
          setLoading: setBarMarkDeleteLoading,
          onSuccess: (fetch_output) => {
            if (fetch_output.status) {
              getBarMarkList();
              showMessage({ type: "success", message: fetch_output.message });
            } else {
              showMessage({ type: "warning", message: fetch_output.error });
            }
            resolve(fetch_output);
          },
          onFailure: (error) => {
            showMessage({ type: "error", message: error });
            reject(error);
          },
          onFetchError: () => {
            showMessage({ apiType: "fetch", message: "remove bar mark" });
          },
          onResponseError: () => {
            showMessage({ apiType: "response", message: "Remove Bar Mark" });
          },
        });
      });
    },
    [fetchWrapper]
  );
  return { removeBarMark };
}

export default useRemoveBarMark;
