import { useCallback, useContext, useState } from "react";
import useFetch from "../../hooks/useFetch";
import { IndexContext } from "../../context/IndexContext";
import { useShowMessage } from "../Common/showMessage";

function useGetActivateDeliveryOrder() {
  const [fetchWrapper] = useFetch();
  const { messageApi } = useContext(IndexContext);
  const [activateDeliveryOrder, setActivateDeliveryOrder] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const showMessage = useShowMessage();

  const getActivateDeliveryOrder = useCallback(
    (date) => {
      setIsLoading(true);
      fetchWrapper({
        endpoint_url: "delivery_order_connection/getActivateDeliveryOrder",
        params: {
          date: date,
        },
        setLoading: setIsLoading,
        onSuccess: (fetch_output) => {
          if (fetch_output.status) {
            setActivateDeliveryOrder(fetch_output.data);
          } else {
            showMessage({ type: "warning", message: fetch_output.error });
          }
        },
        onFetchError: () => {
          showMessage({
            apiType: "fetch",
            message: "get activate delivery order",
          });
        },
        onResponseError: () => {
          showMessage({
            apiType: "response",
            message: "Get Activate Delivery Order",
          });
        },
      });
    },
    [fetchWrapper]
  );

  return { getActivateDeliveryOrder, activateDeliveryOrder, isLoading };
}

export default useGetActivateDeliveryOrder;
