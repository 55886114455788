import { Card, Col, Row, Typography } from "antd";
import OperatorBundlesChart from "./daily_trend/operator_bundles/OperatorBundlesChart";
import OperatorBundlesSummary from "./daily_trend/operator_bundles/OperatorBundlesSummary";
import ShiftBundlesSummary from "./daily_trend/shift_bundles/ShiftBundlesSummary";
import ShiftBundlesChart from "./daily_trend/shift_bundles/ShiftBundlesChart";
import ProductionBundlesSummary from "./daily_trend/production_bundles/ProductionBundlesSummary";
import ProductionBundlesChart from "./daily_trend/production_bundles/ProductionBundlesChart";
import DeliveryBundlesSummary from "./daily_trend/delivery_bundles/DeliveryBundlesSummary";
import DeliveryBundlesChart from "./daily_trend/delivery_bundles/DeliveryBundlesChart";

const { Title } = Typography;

export default function DailyTrendCard({
  shiftSummary,
  shiftChartData,
  operatorSummary,
  operatorChartData,
  productionSummary,
  productionChartData,
  deliverySummary,
  deliveryChartData,
  loading,
}) {
  return (
    <Card
      title={<strong>Daily Trend Chart - 7 days</strong>}
      style={{ height: "100%" }}
      loading={loading}
    >
      <Row gutter={[12, 12]}>
        <Col xs={24} sm={24} md={24} lg={12}>
          <Row>
            <Col xs={24} sm={9}>
              <Title level={3}>Shift Bundles</Title>
              <div className="flexbox-container-row">
                <ShiftBundlesSummary summaryData={shiftSummary} />
              </div>
            </Col>
            <Col xs={24} sm={15}>
              <ShiftBundlesChart chartData={shiftChartData} />
            </Col>
          </Row>
        </Col>
        <Col xs={24} sm={24} md={24} lg={12}>
          <Row>
            <Col xs={24} sm={9}>
              <Title level={3}>In Production Bundles</Title>
              <div className="flexbox-container-row">
                <ProductionBundlesSummary summaryData={productionSummary} />
              </div>
            </Col>
            <Col xs={24} sm={15}>
              <ProductionBundlesChart chartData={productionChartData} />
            </Col>
          </Row>
        </Col>
      </Row>
      <Row gutter={[12, 12]}>
        <Col xs={24} sm={24} md={24} lg={12}>
          <Row>
            <Col xs={24} sm={9}>
              <Title level={3}>Operator Bundles</Title>
              <div className="flexbox-container-row">
                <OperatorBundlesSummary summaryData={operatorSummary} />
              </div>
            </Col>
            <Col xs={24} sm={15}>
              <OperatorBundlesChart chartData={operatorChartData} />
            </Col>
          </Row>
        </Col>
        <Col xs={24} sm={24} md={24} lg={12}>
          <Row>
            <Col xs={24} sm={9}>
              <Title level={3}>Delivered Bundles</Title>
              <div className="flexbox-container-row">
                <DeliveryBundlesSummary summaryData={deliverySummary} />
              </div>
            </Col>
            <Col xs={24} sm={15}>
              <DeliveryBundlesChart chartData={deliveryChartData} />
            </Col>
          </Row>
        </Col>
      </Row>
    </Card>
  );
}
