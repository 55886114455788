import { useCallback, useState } from "react";
import useFetch from "../../hooks/useFetch";
import useDownloadFile from "../DownloadFile/downloadFile";
import { useShowMessage } from "../Common/showMessage";

function useDownloadMillCert() {
  const [fetchWrapper] = useFetch();
  const [downloadLoading, setDownloadLoading] = useState(false);
  const { downloadFile } = useDownloadFile();

  const showMessage = useShowMessage();

  const downloadMillCert = useCallback(
    (data, handleDeliveryOrderList) => {
      setDownloadLoading(true);
      fetchWrapper({
        endpoint_url: "delivery_order/downloadMillCert",
        params: {
          delivery_order_data: data,
        },
        setLoading: setDownloadLoading,
        onSuccess: (fetch_output) => {
          if (fetch_output.status) {
            for (let i = 0; i < fetch_output.data.length; i++) {
              downloadFile(
                fetch_output.data[i].folder_path,
                fetch_output.data[i].file_name
              );
            }
            handleDeliveryOrderList();
          } else {
            showMessage({ type: "warning", message: fetch_output.error });
          }
        },
        onFetchError: () => {
          showMessage({ apiType: "fetch", message: "download mill cert file" });
        },
        onResponseError: () => {
          showMessage({
            apiType: "response",
            message: "Download Mill Cert File",
          });
        },
      });
    },
    [fetchWrapper]
  );

  return { downloadMillCert, downloadLoading };
}

export default useDownloadMillCert;
