import { useCallback, useState } from "react";
import useFetch from "../../hooks/useFetch";
import { useShowMessage } from "../Common/showMessage";

function useGetTraceabilityImage() {
  const [fetchWrapper] = useFetch();
  const [imageLoading, setImageLoading] = useState(false);

  const showMessage = useShowMessage();

  const getTraceabilityImage = useCallback(
    (files) => {
      return new Promise((resolve, reject) => {
        setImageLoading(true);
        fetchWrapper({
          endpoint_url: "traceability/getTraceabilityImage",
          params: {
            files: files,
          },
          setLoading: setImageLoading,
          onSuccess: (fetch_output) => {
            if (!fetch_output.status) {
              showMessage({ type: "warning", message: fetch_output.error });
            }

            resolve(fetch_output);
          },
          onFailure: (error) => {
            showMessage({ message: error });
            reject(error);
          },
          onFetchError: () => {
            showMessage({ apiType: "fetch", message: "get traceability data" });
          },
          onResponseError: () => {
            showMessage({
              apiType: "response",
              message: "Get Tag ID Traceability",
            });
          },
        });
      });
    },
    [fetchWrapper]
  );

  return {
    getTraceabilityImage,
    imageLoading,
  };
}

export default useGetTraceabilityImage;
