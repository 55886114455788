import useFetch from "../../../hooks/useFetch";
import { useCallback, useState } from "react";
import { useShowMessage } from "../../Common/showMessage";

function useRemoveMechenicalProperties() {
  const [fetchWrapper] = useFetch();
  const [mechanicalDeleteLoading, setMechanicalDeleteLoading] = useState(false);

  const showMessage = useShowMessage();

  const removeMechanicalProperties = useCallback(
    (key, handleMechanicalProperties) => {
      return new Promise((resolve, reject) => {
        setMechanicalDeleteLoading(true);
        fetchWrapper({
          endpoint_url: "material_properties/removeMechanicalProperties",
          params: {
            mechanical_key: key,
          },
          setLoading: setMechanicalDeleteLoading,
          onSuccess: (fetch_output) => {
            if (fetch_output.status) {
              showMessage({ type: "success", message: fetch_output.message });
              handleMechanicalProperties();
            } else {
              showMessage({ type: "warning", message: fetch_output.error });
            }
            resolve(fetch_output);
          },
          onFailure: (error) => {
            showMessage({ message: error });
            reject(error);
          },
          onFetchError: () => {
            showMessage({
              apiType: "fetch",
              message: "delete mechanical properties",
            });
          },
          onResponseError: () => {
            showMessage({
              apiType: "response",
              message: "Delete Mechanical Properties",
            });
          },
        });
      });
    },
    [fetchWrapper]
  );
  return { removeMechanicalProperties, mechanicalDeleteLoading };
}

export default useRemoveMechenicalProperties;
