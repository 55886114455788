import { Button, Tag } from "antd";
import { useNavigate } from "react-router-dom";
import DataTable from "../../common/DataTable";
import CustomTooltip from "../../common/CustomTooltip";

const STATUS_TOOLTIP_CONTENT = [
  {
    type: "AI Processed",
    description: "This bundle needs to be verified.",
  },
  {
    type: "Verifying",
    description: "This bundle is currently being verified.",
  },
  {
    type: "Verified",
    description: "This bundle has been successfully verified.",
  },
];

const REMARK_TOOLTIP_CONTENT = [
  {
    type: "Matched",
    description:
      "The final count of this bundle matches the expected quantity accurately.",
  },
  {
    type: "Rework",
    description:
      "The final count of this bundle does not match the expected quantity.",
  },
];

export default function BundleListTable({ tableData }) {
  // To navigate
  const navigate = useNavigate();

  // Column for the Bundle Table
  const BUNDLE_TABLE_COLUMN = [
    {
      title: "No.",
      dataIndex: "number",
      key: "number",
      width: 80,
    },
    {
      title: "Product",
      dataIndex: "product",
      key: "product",
      width: 150,
    },
    {
      title: "Serial Number",
      dataIndex: "tag_id",
      key: "tag_id",
      width: 220,
    },
    {
      title: "Stage",
      dataIndex: "stage",
      key: "stage",
      width: 150,
    },
    {
      title: (
        <CustomTooltip title={"Status"} content={STATUS_TOOLTIP_CONTENT} />
      ),
      dataIndex: "status",
      key: "status",
      width: 150,
    },
    {
      title: "Rebar Qty (Expected Qty)",
      dataIndex: "rebar_qty",
      key: "rebar_qty",
      width: 200,
    },
    {
      title: "Last Update",
      dataIndex: "last_update",
      key: "last_update",
      width: 200,
    },
    {
      title: "Captured By",
      dataIndex: "captured_by",
      key: "captured_by",
      width: 140,
    },
    {
      title: "Verify By",
      dataIndex: "verify_by",
      key: "verify_by",
      width: 140,
    },
    {
      title: (
        <CustomTooltip title={"Remark"} content={REMARK_TOOLTIP_CONTENT} />
      ),
      dataIndex: "remark",
      key: "remark",
      width: 150,
      render: (_, record) => {
        return record.remark === "rework" ? (
          <Tag color="blue">Rework</Tag>
        ) : record.remark === "matched" ? (
          <Tag color="green">Matched</Tag>
        ) : (
          "-"
        );
      },
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      width: 150,
      render: (_, record) => {
        return record.verify_status === true ? (
          <Button type="link" disabled>
            In Progress
          </Button>
        ) : (
          <Button
            type="link"
            style={{
              color: record.status !== "Verified" ? "green" : "",
            }} // Change color to green if the status is not "Verified"
            onClick={() => navigateVerification(record.key)}
          >
            {record.status !== "Verified" ? "Verify" : "Review"}
          </Button>
        );
      },
    },
  ];

  // Function to navigate to Rebar Verification Page
  const navigateVerification = (rebar_key) => {
    navigate(`/Rebar_Verification/${rebar_key}`);
  };

  return (
    <DataTable
      table_column={BUNDLE_TABLE_COLUMN}
      table_data={tableData}
      columnCenter={true}
    />
  );
}
