import { Button, Image as AntdImage, Typography, Tag } from "antd";
import DataTable from "../common/DataTable";
import { useRef, useState } from "react";
import TraceabilityModal from "./TraceabilityModal";
import CustomTooltip from "../common/CustomTooltip";

const STATUS_TOOLTIP_CONTENT = [
  {
    type: "AI Processed",
    description: "This bundle needs to be verified.",
  },
  {
    type: "In Progress",
    description: "This bundle is currently being verified.",
  },
  {
    type: "Verified",
    description: "This bundle has been successfully verified.",
  },
];

const REMARK_TOOLTIP_CONTENT = [
  {
    type: "Matched",
    description:
      "The final count of this bundle matches the expected quantity accurately.",
  },
  {
    type: "Rework",
    description:
      "The final count of this bundle does not match the expected quantity.",
  },
];

export default function TraceabilityTable({
  tableData,
  getTraceabilityImage,
  imageLoading,
}) {
  const canvasRef = useRef(null);

  const [loadRowKey, setLoadRowKey] = useState(null);
  const [loadColumn, setLoadColumn] = useState(null);
  // For handle Bundle Image Visible
  const [bunldeVisible, setBundleVisible] = useState(false);
  // For handle Operator Image Visible
  const [operatorVisible, setOperatorVisible] = useState(false);
  // For handle Supervisor Image Visible
  const [supervisorVisible, setSupervisorVisible] = useState(false);
  // For handle Timeline Modal
  const [timelineVisible, setTimelineVisible] = useState(false);

  // For handle Bundle Image data Select
  const [bundleSelectImage, setBundleSelectImage] = useState(null);
  // For handle Operator Image data Select
  const [operatorSelectImage, setOperatorSelectImage] = useState(null);
  // For handle Supervisor Image data Select
  const [supervisorSelectImage, setSupervisorSelectImage] = useState(null);
  // For handle Timeline data Select
  const [timelineData, setTimelineData] = useState(null);
  const [timelineSerial, setTimelineSerial] = useState(null);

  const handleBundlePreview = async (image, coordinate, key) => {
    try {
      setLoadColumn("BundleImage");
      setLoadRowKey(key);

      const { image_src: imageSrc } = await getTraceabilityImage(image);
      if (!imageSrc || imageSrc.length === 0) return;

      const finalImage =
        coordinate && coordinate.length > 0
          ? imageSrc.concat(await drawImageCoordinate(imageSrc, coordinate))
          : imageSrc;

      setBundleSelectImage(finalImage);
      setBundleVisible(true);
    } catch (error) {
      console.error("Error handling bundle image preview:", error);
    } finally {
      // Reset loading state
      setLoadColumn(null);
      setLoadRowKey(null);
    }
  };

  // For handle Operator Image Preview
  const handleOperatorPreview = async (image, key) => {
    try {
      setLoadColumn("OperatorImage");
      setLoadRowKey(key);

      const { image_src: imageSrc } = await getTraceabilityImage(image);
      if (!imageSrc || imageSrc.length === 0) return;

      setOperatorSelectImage(imageSrc);
      setOperatorVisible(true);
    } catch (error) {
      console.error("Error handling operator image preview:", error);
    } finally {
      // Reset loading state
      setLoadColumn(null);
      setLoadRowKey(null);
    }
  };
  // For handle Supervisor Image Preview
  const handleSupervisorPreview = async (image, key) => {
    try {
      setLoadColumn("SupervisorImage");
      setLoadRowKey(key);

      const { image_src: imageSrc } = await getTraceabilityImage(image);
      if (!imageSrc || imageSrc.length === 0) return;

      setSupervisorSelectImage(imageSrc);
      setSupervisorVisible(true);
    } catch (error) {
      console.error("Error handling supervisor image preview:", error);
    } finally {
      // Reset loading state
      setLoadColumn(null);
      setLoadRowKey(null);
    }
  };
  // For handle Timeline Modal Preview
  const handleTimelinePreview = (data, serial_no) => {
    setTimelineData(data);
    setTimelineSerial(serial_no);
    setTimelineVisible(true);
  };

  const onModalCancel = () => {
    setTimelineVisible(false);
  };

  const drawImageCoordinate = (image, coordinate) => {
    return new Promise((resolve) => {
      let result = [];
      const canvas = canvasRef.current;
      const ctx = canvas.getContext("2d");

      canvas.width = 800;
      canvas.height = 800;

      // Track how many images have loaded
      let imagesLoaded = 0;

      for (let i = 1; i < image.length; i++) {
        const img = new Image();
        img.src = image[i];

        img.onload = () => {
          ctx.clearRect(0, 0, canvas.width, canvas.height);
          ctx.drawImage(img, 0, 0, canvas.width, canvas.height);

          coordinate.forEach((coor) => {
            const { x, y, shape_size, shape_type } = coor;

            const pixelX = x * canvas.width;
            const pixelY = y * canvas.height;
            ctx.strokeStyle = "#22DD22";
            ctx.lineWidth = 2;

            if (shape_type === "circle") {
              ctx.beginPath();
              ctx.arc(pixelX, pixelY, shape_size, 0, Math.PI * 2);
              ctx.stroke();
              ctx.closePath();
            } else if (shape_type === "cross") {
              ctx.beginPath();
              ctx.moveTo(pixelX - shape_size / 2, pixelY - shape_size / 2);
              ctx.lineTo(pixelX + shape_size / 2, pixelY + shape_size / 2);
              ctx.stroke();

              ctx.beginPath();
              ctx.moveTo(pixelX - shape_size / 2, pixelY + shape_size / 2);
              ctx.lineTo(pixelX + shape_size / 2, pixelY - shape_size / 2);
              ctx.stroke();
            }
          });

          const dataUrl = canvas.toDataURL("image/jpeg");
          result.push(dataUrl);

          imagesLoaded++;

          // Check if all images are loaded
          if (imagesLoaded === image.length - 1) {
            resolve(result);
          }
        };
      }
    });
  };

  // For Traceability Table Column
  const TRACEABILITY_TABLE_COLUMN = [
    {
      title: "No.",
      dataIndex: "number",
      key: "number",
      width: 50,
    },
    {
      title: "Product",
      dataIndex: "product",
      key: "product",
      width: 80,
    },
    {
      title: "Serial No",
      dataIndex: "serial_no",
      key: "serial_no",
      width: 180,
      render: (_, record) => {
        return (
          <Button
            type="link"
            onClick={() =>
              handleTimelinePreview(record.timeline, record.serial_no)
            }
          >
            {record.serial_no}
          </Button>
        );
      },
    },
    {
      title: "Stage",
      dataIndex: "stage",
      key: "stage",
      width: 90,
    },
    {
      title: (
        <CustomTooltip title={"Status"} content={STATUS_TOOLTIP_CONTENT} />
      ),
      dataIndex: "status",
      key: "status",
      width: 100,
      render: (status) => {
        return status === "AI Processed" ? (
          <Tag color="yellow" children={status} />
        ) : status === "Verifying" ? (
          <Tag color="processing" children={status} />
        ) : (
          <Tag color="success" children={status} />
        );
      },
    },
    {
      title: "Photo Date Time",
      dataIndex: "photo_date_time",
      key: "photo_date_time",
      width: 130,
      render: (photo_date_time) => (
        <>
          {photo_date_time.split("\n").map((line, index) => (
            <Typography.Text key={index}>
              {line}
              <br />
            </Typography.Text>
          ))}
        </>
      ),
    },
    {
      title: "Operator ID",
      dataIndex: "operator_id",
      key: "operator_id",
      width: 100,
    },
    {
      title: "Verification Date Time",
      dataIndex: "validate_time",
      key: "validate_time",
      width: 100,
      render: (validate_time) => (
        <>
          {validate_time.split("\n").map((line, index) => (
            <Typography.Text key={index}>
              {line}
              <br />
            </Typography.Text>
          ))}
        </>
      ),
    },
    {
      title: "Verify ID",
      dataIndex: "verify_id",
      key: "verify_id",
      width: 100,
    },
    {
      title: "AI Count",
      dataIndex: "ai_count",
      key: "ai_count",
      width: 80,
    },
    {
      title: "Final Count (Expected Qty)",
      dataIndex: "final_count",
      key: "final_count",
      width: 120,
    },
    {
      title: "Heat No",
      dataIndex: "heat_no",
      key: "heat_no",
      width: 80,
    },
    {
      title: "Mill Cert ID",
      dataIndex: "mill_cert_id",
      key: "mill_cert_id",
      width: 100,
    },
    {
      title: (
        <CustomTooltip title={"Remark"} content={REMARK_TOOLTIP_CONTENT} />
      ),
      dataIndex: "remark",
      key: "remark",
      width: 100,
      render: (_, record) => {
        return record.remark === "rework" ? (
          <Tag color="blue">Rework</Tag>
        ) : record.remark === "matched" ? (
          <Tag color="green">Matched</Tag>
        ) : (
          "-"
        );
      },
    },
    {
      title: "Bundle Image",
      dataIndex: "bundle_image",
      key: "bundle_image",
      width: 120,
      render: (_, record) => {
        return (
          <Button
            type="link"
            onClick={() =>
              handleBundlePreview(
                record.bundle_image,
                record.image_coordinate,
                record.key
              )
            }
            loading={
              imageLoading &&
              loadRowKey === record.key &&
              loadColumn === "BundleImage"
            }
          >
            View
          </Button>
        );
      },
    },
    {
      title: "Operator Image",
      dataIndex: "operator_image",
      key: "operator_image",
      width: 120,
      render: (_, record) => {
        return (
          <Button
            type="link"
            disabled={record.operator_image.length === 0}
            onClick={() =>
              handleOperatorPreview(record.operator_image, record.key)
            }
            loading={
              imageLoading &&
              loadRowKey === record.key &&
              loadColumn === "OperatorImage"
            }
          >
            View
          </Button>
        );
      },
    },
    {
      title: "Verifier Image",
      dataIndex: "verify_image",
      key: "verify_image",
      width: 120,
      render: (_, record) => {
        return (
          <Button
            type="link"
            disabled={record.verify_image.length === 0}
            onClick={() =>
              handleSupervisorPreview(record.verify_image, record.key)
            }
            loading={
              imageLoading &&
              loadRowKey === record.key &&
              loadColumn === "SupervisorImage"
            }
          >
            View
          </Button>
        );
      },
    },
  ];

  return (
    <>
      <DataTable
        table_column={TRACEABILITY_TABLE_COLUMN}
        table_data={tableData}
        pageSize={9}
        columnCenter={true}
      />
      <AntdImage.PreviewGroup
        items={bundleSelectImage}
        preview={{
          visible: bunldeVisible,
          onVisibleChange: (value) => setBundleVisible(value),
        }}
      />
      <AntdImage.PreviewGroup
        items={operatorSelectImage}
        preview={{
          visible: operatorVisible,
          onVisibleChange: (value) => setOperatorVisible(value),
        }}
      />
      <AntdImage.PreviewGroup
        items={supervisorSelectImage}
        preview={{
          visible: supervisorVisible,
          onVisibleChange: (value) => setSupervisorVisible(value),
        }}
      />
      <TraceabilityModal
        visible={timelineVisible}
        timelineData={timelineData}
        timelineSerial={timelineSerial}
        onModalCancel={onModalCancel}
      />
      <canvas ref={canvasRef} style={{ display: "none" }} />
    </>
  );
}
