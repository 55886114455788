import { Button, Card, Typography } from "antd";
import CustomCardTitle from "../../components/common/CustomCardTitle";
import CustomRangePicker from "../../components/common/CustomRangePicker";
import { useState } from "react";
import { PlusOutlined } from "@ant-design/icons";
import DemoTable from "../../components/demo/DemoTable";
import DemoDetailsModal from "../../components/demo/DemoDetailsModal";
import DemoModalForm from "../../components/demo/DemoModalForm";

const DUMMY_DATA = [
  {
    do_number: "DOP325252",
    so_number: "MSW 22794",
    customer: "PixeVision",
    address:
      "2, Jalan Puteri 5/18,\n Bandar Puteri,\n 47100 Puchong,\n Selangor",
    product: "Y10",
    specification: "GRADE B250",
    lorry_plate: "PHD 6366",
    number_of_bundles: 10,
    status: "Approve",
    delivery_date_time: "2024-12-25 15:00:00",
    create_date_time: "2024-12-24 11:12:13",
    last_update_date_time: "2024-12-24 11:15:23",
  },
  {
    do_number: "DOP163243",
    so_number: "MSW 22456",
    customer: "ASPL",
    address:
      "2, Jalan Puteri 5/18,\n Bandar Puteri,\n 47100 Puchong,\n Selangor",
    product: "Y12",
    specification: "MS146: 2014",
    lorry_plate: "IRU 1293",
    number_of_bundles: 15,
    status: "Reject",
    delivery_date_time: "2024-12-25 16:00:00",
    create_date_time: "2024-12-24 11:14:53",
    last_update_date_time: "2024-12-24 11:18:56",
  },
  {
    do_number: "DOP644342",
    so_number: "MSW 27453",
    customer: "Masteel",
    address:
      "Petaling Jaya,\n 29C, Jln Tandang,\n Seksyen 51,\n 46050 Petaling Jaya,\n Selangor",
    product: "Y10",
    specification: "GRADE B250",
    lorry_plate: "PHD 6366",
    number_of_bundles: 8,
    status: "Pending",
    delivery_date_time: "2024-12-25:15:30:00",
    create_date_time: "2024-12-24 11:16:53",
    last_update_date_time: "2024-12-24 11:16:53",
  },
  {
    do_number: "DOP325323",
    so_number: "MSW 25436",
    customer: "Open RE",
    address:
      "2, Jalan Puteri 5/18,\n Bandar Puteri,\n 47100 Puchong,\n Selangor",
    product: "Y14",
    specification: "MS146: 2014",
    lorry_plate: "IRU 1293",
    number_of_bundles: 5,
    status: "In Progress",
    delivery_date_time: "2024-12-25 17:00:00",
    create_date_time: "2024-12-24 11:18:53",
    last_update_date_time: "2024-12-24 11:20:23",
  },
];

export default function DemoDeliveryOrderPage() {
  const [dateValue, setDateValue] = useState(null);
  const [detailModal, setDetailModal] = useState(false);
  const [doDetails, setDoDetails] = useState({});
  const [demoModalForm, setDemoModalForm] = useState(false);
  const [editDemo, setEditDemo] = useState(false);

  const onDateChange = (value) => setDateValue(value);
  const openDetailModal = (value) => {
    setDoDetails(value);
    setDetailModal(true);
  };

  const openEditFormModal = (value) => {
    setEditDemo(value);
    setDemoModalForm(true);
  };

  return (
    <>
      <Card
        className="context-card"
        title={
          <CustomCardTitle
            leftTitle={<Typography.Text children={"Delivery Order Table"} />}
            rightTitle={
              <>
                <CustomRangePicker
                  onDateChange={onDateChange}
                  search={true}
                  onFilterClick={() => {}}
                />
                <Button children="Reset" />
                <Button type="primary" children="Advanced Search" />
                <Button
                  icon={<PlusOutlined style={{ color: "white" }} />}
                  onClick={() => setDemoModalForm(true)}
                  style={{ backgroundColor: "#0000A3" }}
                />
              </>
            }
          />
        }
      >
        <DemoTable
          tableData={DUMMY_DATA}
          openDetailModal={openDetailModal}
          openEditFormModal={openEditFormModal}
        />
      </Card>
      <DemoDetailsModal
        modalOpen={detailModal}
        setModalOpen={setDetailModal}
        detailsData={doDetails}
      />
      <DemoModalForm
        modalOpen={demoModalForm}
        setModalOpen={setDemoModalForm}
        editData={editDemo}
        setEditData={setEditDemo}
      />
    </>
  );
}
