import { Card, Col, Row, Typography } from "antd";
import DemoWeightTable from "../../components/demo/DemoWeightTable";
import DemoWeightModal from "../../components/demo/DemoWeightModal";
import { useEffect, useState } from "react";
import DemoLorrySummary from "../../components/demo/DemoLorrySummary";
import CustomCardTitle from "../../components/common/CustomCardTitle";
import CustomRangePicker from "../../components/common/CustomRangePicker";

const DUMMY_DATA = [
  {
    lorry_plate: "PHD 6366",
    delivery_date: "2024-12-27",
    arrival_weight: "-",
    departure_weight: "-",
    arrival_weight_time: "-",
    departure_weight_time: "-",
    check_in: true,
    arrival: false,
    departure: false,
  },
  {
    lorry_plate: "IRU 1293",
    delivery_date: "2024-12-27",
    arrival_weight: "2.5 tonnes",
    departure_weight: "-",
    arrival_weight_time: "2024-12-27 14:10:23",
    departure_weight_time: "-",
    check_in: true,
    arrival: true,
    departure: false,
  },
  {
    lorry_plate: "KPI 3948",
    delivery_date: "2024-12-25",
    arrival_weight: "2.7 tonnes",
    departure_weight: "3.8 tonnes",
    arrival_weight_time: "2024-12-25 10:13:52",
    departure_weight_time: "2024-12-25 11:30:41",
    check_in: true,
    arrival: true,
    departure: true,
  },
];

export default function DemoWeightPage() {
  const [weightModal, setWeightModal] = useState(false);
  const [weightType, setWeightType] = useState(null);
  const [dateValue, setDateValue] = useState(null);

  const openWeightModal = (value) => {
    setWeightType(value);
    setWeightModal(true);
  };

  const onDateChange = (value) => setDateValue(value);

  useEffect(() => {
    console.log("Weight Type: ", weightType);
  }, [weightType]);

  return (
    <>
      <Row gutter={[8, 8]} style={{ height: "100%" }}>
        <Col
          span={24}
          style={{ height: "10%" }}
          children={<DemoLorrySummary />}
        />
        <Col
          span={24}
          style={{ height: "90%" }}
          children={
            <Card
              className="context-card"
              title={
                <CustomCardTitle
                  leftTitle={<Typography.Text children={"Weight Table"} />}
                  rightTitle={<CustomRangePicker onDateChange={onDateChange} />}
                />
              }
            >
              <DemoWeightTable
                tableData={DUMMY_DATA}
                openWeightModal={openWeightModal}
              />
            </Card>
          }
        />
      </Row>
      <DemoWeightModal
        openModal={weightModal}
        setModalOpen={setWeightModal}
        weightType={weightType}
      />
    </>
  );
}
