import { useCallback, useState } from "react";
import useFetch from "../../../hooks/useFetch";
import { useShowMessage } from "../../Common/showMessage";

function useGetBundleNonAdjustedRate() {
  const [fetchWrapper] = useFetch();
  const [nonAdjustedRateChart, setNonAdjustedRateChart] = useState([]);

  const showMessage = useShowMessage();

  const getBundleNonAdjustedRate = useCallback(
    (setIsLoading) => {
      setIsLoading(true);
      fetchWrapper({
        endpoint_url: "dashboard/getBundleNonAdjustedRate",
        setLoading: setIsLoading,
        onSuccess: (fetch_ouput) => {
          if (fetch_ouput.status) {
            setNonAdjustedRateChart(fetch_ouput.data);
          } else {
            showMessage({ type: "warning", message: fetch_ouput.error });
          }
        },
        onFetchError: () => {
          showMessage({
            apiType: "fetch",
            message: "get bundle non-adjusted rate",
          });
        },
        onResponseError: () => {
          showMessage({
            apiType: "response",
            message: "Get BUndle Non-Adjusted Rate",
          });
        },
      });
    },
    [fetchWrapper]
  );

  return { getBundleNonAdjustedRate, nonAdjustedRateChart };
}

export default useGetBundleNonAdjustedRate;
