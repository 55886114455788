import { useCallback } from "react";
import useFetch from "../../../hooks/useFetch";
import { useShowMessage } from "../../Common/showMessage";

function useSnapRebarImage() {
  const [fetchWrapper] = useFetch();

  const showMessage = useShowMessage();

  const snapRebarImage = useCallback(
    (
      url,
      tagId,
      checkRebarStatus,
      setImageUploading,
      resetErrorCount,
      addErrorCount,
      setResultType
    ) => {
      return new Promise((resolve, reject) => {
        fetchWrapper({
          endpoint_url: "ai_rebar_count/snapRebarImage",
          params: {
            image_url: url,
            tag_key: tagId.key,
            tag_id: tagId.value,
          },
          onSuccess: (fetch_output) => {
            if (fetch_output.status) {
              checkRebarStatus(
                fetch_output.key,
                setImageUploading,
                resetErrorCount,
                addErrorCount,
                setResultType
              );
              resetErrorCount();
            } else {
              addErrorCount();
              setImageUploading({ progress: 100, state: false, res: false });
              setResultType("upload_fail");
            }
            resolve(fetch_output);
          },
          onFailure: (error) => {
            setImageUploading({ progress: 100, state: false, res: false });
            setResultType("upload_fail");
            showMessage({ type: "error", message: error });

            reject(error);
          },
          onFetchError: () => {
            setImageUploading({ progress: 100, state: false, res: false });
            setResultType("upload_fail");
            showMessage({ apiType: "fetch", message: "upload rebar image" });
          },
          onResponseError: () => {
            setImageUploading({ progress: 100, state: false, res: false });
            setResultType("upload_fail");
            showMessage({ apiType: "response", message: "Upload Rebar Image" });
          },
        });
      });
    },
    [fetchWrapper]
  );
  return { snapRebarImage };
}

export default useSnapRebarImage;
