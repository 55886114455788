import { BellOutlined, InfoCircleOutlined } from "@ant-design/icons";
import { Card, Col, Flex, Row, Typography } from "antd";

export default function DailyStatusCard({
  title,
  content,
  percentage,
  prefix,
  loading,
  icon,
}) {
  return (
    <Card
      title={
        <Flex justify="flex-start" align="center" gap={"small"}>
          {icon ? icon : <InfoCircleOutlined />}
          <span>
            {title.split("_").map((value) => {
              return value.charAt(0).toUpperCase() + value.slice(1) + " ";
            })}
          </span>
        </Flex>
      }
      loading={loading}
    >
      <Row>
        <Col span={24}>
          <Flex align="flex-end" justify="flex-start" gap={"small"}>
            <Typography.Title level={2} style={{ margin: 0 }}>
              {content ?? "----"}
            </Typography.Title>
            {percentage && (
              <Typography.Title level={4} style={{ margin: 0 }}>
                ({percentage}%)
              </Typography.Title>
            )}
          </Flex>
        </Col>
        <Col span={24}>
          <Flex align="center" justify="flex-end">
            <Typography.Title level={5} style={{ margin: 0 }}>
              {prefix ?? "----"}
            </Typography.Title>
          </Flex>
        </Col>
      </Row>
    </Card>
  );
}
