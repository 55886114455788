import { useCallback, useState } from "react";
import useFetch from "../../hooks/useFetch";
import useDownloadFile from "../DownloadFile/downloadFile";
import { useShowMessage } from "../Common/showMessage";

function useDownloadTraceability() {
  const [fetchtWrapper] = useFetch();
  const [downloadLoading, setDownloadLoading] = useState(false);
  const { downloadFile } = useDownloadFile();

  const showMessage = useShowMessage();

  const downloadTraceability = useCallback(
    (download_data) => {
      setDownloadLoading(true);
      fetchtWrapper({
        endpoint_url: "traceability/downloadTraceability",
        params: {
          download: download_data,
        },
        setLoading: setDownloadLoading,
        onSuccess: (fetch_ouput) => {
          if (fetch_ouput.status) {
            downloadFile(fetch_ouput.folder_path, fetch_ouput.file_name);
          } else {
            showMessage({ type: "warning", message: fetch_ouput.error });
          }
        },
        onFetchError: () => {
          showMessage({
            apiType: "fetch",
            message: "download traceability excel file",
          });
        },
        onResponseError: () => {
          showMessage({
            apiType: "response",
            message: "Download Traceability Excel",
          });
        },
      });
    },
    [fetchtWrapper]
  );
  return { downloadTraceability, downloadLoading };
}

export default useDownloadTraceability;
