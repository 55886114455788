import { useCallback, useState } from "react";
import useFetch from "../../../hooks/useFetch";
import { useShowMessage } from "../../Common/showMessage";

function useRemoveCustomer() {
  const [fetchWrapper] = useFetch();
  const [customerDeleteLoading, setCustomerDeleteLoading] = useState(false);

  const showMessage = useShowMessage();

  const removeCustomer = useCallback(
    (key, getCustomerList) => {
      return new Promise((resolve, reject) => {
        setCustomerDeleteLoading(true);
        fetchWrapper({
          endpoint_url: "configuration/removeCustomer",
          params: { key: key },
          setLoading: setCustomerDeleteLoading,
          onSuccess: (fetch_output) => {
            if (fetch_output.status) {
              getCustomerList();
              showMessage({ type: "success", message: fetch_output.message });
            } else {
              showMessage({ type: "warning", message: fetch_output.error });
            }
            resolve(fetch_output);
          },
          onFailure: (error) => {
            showMessage({ message: error });
            reject(error);
          },
          onFetchError: () => {
            showMessage({ apiType: "fetch", message: "insert customer" });
          },
          onResponseError: () => {
            showMessage({ apiType: "response", message: "Insert Customer" });
          },
        });
      });
    },
    [fetchWrapper]
  );

  return { removeCustomer, customerDeleteLoading };
}

export default useRemoveCustomer;
