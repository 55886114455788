import { Button, Modal, Result } from "antd";
import { useNavigate } from "react-router-dom";

const resultTemplate = {
  lost_connection_operator: {
    title: "No DO Selected",
    subTitle: "Press 'Select DO' to navigate back to DO selection page.",
    buttonText: "Select DO",
    redirect: false,
    cancel: false,
  },
  lost_connection_others: {
    title: "No DO Selected",
    subTitle:
      "Press 'Select DO' to navigate to DO selection page or press 'Cancel' to navigate to Bundle List page.",
    buttonText: "Select DO",
    redirect: false,
    cancel: true,
  },
  matched_quantity: {
    title: "DO Bundle Count Reached",
    subTitle:
      "Notify Supervisor and press 'Redirect' back to Delivery Order Conneciton page.",
    buttonText: "Close",
    redirect: true,
    cancel: false,
  },
};

export default function DeliveryOrderModal({
  modalOpen,
  setModalOpen,
  resultType,
  handleCloseConnection,
  updateLoading,
}) {
  const navigate = useNavigate();

  const handleNavigateDeliveryOrder = () => {
    navigate("/Delivery_Order_Connection");
  };

  const handleNavigateBundleList = () => {
    navigate("/Bundle_List/Bundle_Summary");
  };

  return (
    <Modal
      open={modalOpen}
      footer={null}
      closable={false}
      centered
      children={
        resultType != null && (
          <Result
            status={"warning"}
            title={resultTemplate[resultType].title}
            subTitle={resultTemplate[resultType].subTitle}
            extra={
              <>
                {resultTemplate[resultType].redirect ? (
                  <Button
                    children="Redirect"
                    type="primary"
                    onClick={handleCloseConnection}
                    loading={updateLoading}
                  />
                ) : (
                  <Button
                    type="primary"
                    onClick={handleNavigateDeliveryOrder}
                    children={resultTemplate[resultType].buttonText}
                  />
                )}
                {resultTemplate[resultType].cancel && (
                  <Button
                    children="Cancel"
                    onClick={() => {
                      setModalOpen(false);
                      handleNavigateBundleList();
                    }}
                  />
                )}
              </>
            }
          />
        )
      }
    />
  );
}
