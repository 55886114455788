import { useCallback, useState } from "react";
import useFetch from "../../../hooks/useFetch";
import { useShowMessage } from "../../Common/showMessage";

function useRemoveUser() {
  const [fetchWrapper] = useFetch();
  const [userDeleteLoading, setUserDeleteLoading] = useState(false);

  const showMessage = useShowMessage();

  const removeUser = useCallback(
    (key, getUserList) => {
      return new Promise((resolve, reject) => {
        setUserDeleteLoading(true);
        fetchWrapper({
          endpoint_url: "configuration/removeUser",
          params: {
            key: key,
          },
          setLoading: setUserDeleteLoading,
          onSuccess: (fetch_output) => {
            if (fetch_output.status) {
              showMessage({ type: "success", message: fetch_output.message });
              getUserList();
            } else {
              showMessage({ type: "warning", message: fetch_output.error });
            }
            resolve(fetch_output);
          },
          onFailure: (error) => {
            showMessage({ message: error });
            reject(error);
          },
          onFetchError: () => {
            showMessage({ apiType: "fetch", message: "remove user" });
          },
          onResponseError: () => {
            showMessage({ apiType: "response", message: "Remove User" });
          },
        });
      });
    },
    [fetchWrapper]
  );

  return { removeUser, userDeleteLoading };
}

export default useRemoveUser;
