// library imports
import React, { useEffect } from "react";
import ReactDOM from "react-dom/client";
import { ConfigProvider, message, notification } from "antd";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
// style imports
import "./styles/index.css";
// config imports
import {
  CONFIGS,
  account,
  operator,
  supervisor,
  qcOperator,
  demo,
} from "./configs/configs";
// context imports
import { IndexContext } from "./context/IndexContext";
// hook imports
import useThemeSwitcher from "./hooks/useThemeSwitcher";
// component/page imports
import MainLayout from "./layout/MainLayout";
import LoginPage from "./pages/login/LoginPage";
import LoginRecordPage from "./pages/login_record/LoginRecordPage";
import { generateRoute, renderRoutes } from "./routes/AutoGenerateRoutes";

const App = () => {
  // load theme switcher
  const [appTheme, updateTheme, isLightTheme] = useThemeSwitcher();

  // for triggering message
  const [messageApi, messageApiContext] = message.useMessage();
  // for triggering notification
  const [notificationApi, notificationApiContext] =
    notification.useNotification();

  return (
    <ConfigProvider theme={{ ...appTheme }}>
      {messageApiContext}
      {notificationApiContext}
      <IndexContext.Provider
        value={{
          messageApi: messageApi,
          notificationApi: notificationApi,
          isLightTheme: isLightTheme,
          updateTheme: updateTheme,
        }}
      >
        <BrowserRouter basename={CONFIGS.route_basename}>
          <Routes>
            {/* Login Route */}
            <Route path="Login" element={<LoginPage />}></Route>
            <Route path="LoginRecord" element={<LoginRecordPage />}></Route>
            {/* All content pages */}
            <Route element={<MainLayout updateTheme={updateTheme} />}>
              {renderRoutes(generateRoute())}
            </Route>
            {/* Default all unknown route to login page */}
            <Route path="*" element={<Navigate to="/login" />} />
          </Routes>
        </BrowserRouter>
      </IndexContext.Provider>
    </ConfigProvider>
  );
};

ReactDOM.createRoot(document.getElementById("root")).render(<App />);
