import { Button, Card, Divider, Flex, Typography } from "antd";
import { useState, useEffect } from "react";
import "../../styles/bundle_list/BundleSummaryPage.css";
import useGetBundleListSummary from "../../functions/BundleList/bundle_summary/getBundleListSummary";
import useGetBundleListInfo from "../../functions/BundleList/bundle_summary/getBundleListInfo";
import useCheckBundleListVerfiyStatus from "../../functions/BundleList/bundle_summary/checkBundleListVerifyStatus";
import CustomRangePicker from "../../components/common/CustomRangePicker";
import dayjs from "dayjs";
import BundleListSummary from "../../components/bundle_list/bundle_summary/BundleListSummary";
import BundleListSearchForm from "../../components/bundle_list/bundle_summary/BundleListSearchForm";
import BundleListTable from "../../components/bundle_list/bundle_summary/BundleListTable";

const { Text } = Typography;

export default function BundleSummaryPage() {
  // To open the Advanced Search Form Modal
  const [searchModal, setSearchModal] = useState(false);
  const [dateValue, setDateValue] = useState([
    dayjs().subtract(6, "day").startOf("day"),
    dayjs().endOf("day"),
  ]);
  // For handle loading disabled
  const [loadingDisabled, setLoadingDisabled] = useState(false);

  // Api Function to Get the Bundle List Summary
  const { getBundleListSummary, bundleSummary, bundleSummaryLoading } =
    useGetBundleListSummary();
  // Api Function to Get the Bundle List Info
  const { getBundleListInfo, bundleListInfo, bundleInfoLoading } =
    useGetBundleListInfo();
  // Api Function to Check the Bundle List Verify Status
  const { checkBundleListVerifyStatus } = useCheckBundleListVerfiyStatus();

  // To filter Bundle List Table Data
  const [filterBundleListInfo, setFilterBundleListInfo] =
    useState(bundleListInfo);

  const onDateChange = (value) => setDateValue(value);

  // To reset the Filter Bundle List Data to Original Bundle List Data
  const resetFilter = () => {
    setFilterBundleListInfo(bundleListInfo);
  };

  // To search new data when user change the date filter
  const handleDateFilter = () => {
    checkDataStatus(dateValue[0], dateValue[1]);
  };

  // For call the api function
  const checkDataStatus = (startDate, endDate) => {
    checkBundleListVerifyStatus(
      getBundleListInfo,
      getBundleListSummary,
      startDate,
      endDate
    );
  };

  // To get the data
  useEffect(() => {
    setFilterBundleListInfo(bundleListInfo);
  }, [bundleListInfo]);

  // To call the function every 3 minutes and get the Bundle List data
  useEffect(() => {
    checkDataStatus(dateValue[0], dateValue[1]);

    const interval = setInterval(() => {
      checkDataStatus(dateValue[0], dateValue[1]);
    }, 6000000);

    return () => clearInterval(interval);
  }, []);

  // To check is the api still loading or not
  useEffect(() => {
    setLoadingDisabled(bundleInfoLoading || bundleSummaryLoading);
  }, [bundleInfoLoading, bundleSummaryLoading]);

  return (
    <>
      <Card
        className="context-card"
        title={
          <Flex justify="space-between" align="center" wrap="wrap" gap="small">
            <Text>Bundle List Table</Text>
            <Flex gap="small" wrap="wrap">
              <CustomRangePicker
                onDateChange={onDateChange}
                disabled={loadingDisabled}
                search={true}
                showTime={true}
                onFilterClick={handleDateFilter}
              />

              <Button
                onClick={() => {
                  resetFilter();
                }}
                disabled={loadingDisabled}
              >
                Reset
              </Button>

              <Button
                type="primary"
                onClick={() => {
                  setSearchModal(true);
                }}
                disabled={loadingDisabled}
              >
                Advanced Search
              </Button>
            </Flex>
          </Flex>
        }
        loading={loadingDisabled}
      >
        <BundleListSummary data={bundleSummary} />
        <Divider />
        <BundleListTable tableData={filterBundleListInfo} />
      </Card>
      <BundleListSearchForm
        modalOpen={searchModal}
        setModalOpen={setSearchModal}
        tableData={bundleListInfo}
        setFilterTable={setFilterBundleListInfo}
      />
    </>
  );
}
