import { Col, Form, Input, Row, Typography } from "antd";
import useGetBarMarkOption from "../../functions/Options/getBarMarkOption";
import useGetMillCertSpecificationOption from "../../functions/Options/getMillCertSpecificationOption";
import useGetDiameterTypeOption from "../../functions/Options/getDiameterTypeOption";
import useGetCustomerOption from "../../functions/Options/getCustomerOption";
import CustomFormItems from "../common/CustomFormItems";
import { useEffect } from "react";
import CustomModalForm from "../common/CustomModalForm";

export default function DemoModalForm({
  modalOpen,
  setModalOpen,
  editData,
  setEditData,
  onSubmit,
  loading,
}) {
  const [demoForm] = Form.useForm();

  const { barMarkOption } = useGetBarMarkOption();
  const { millCertSpecificationOption } = useGetMillCertSpecificationOption();
  const { diameterOption } = useGetDiameterTypeOption();
  const { customerOption } = useGetCustomerOption();

  const DEMO_FORM = [
    {
      label: "Customer",
      name: "customer",
      type: "select",
      required: true,
      options: customerOption,
    },
    {
      label: "Product",
      name: "product",
      type: "select",
      quarter: true,
      required: true,
      options: diameterOption,
    },
    {
      label: "Number of Bundles",
      name: "number_of_bundles",
      type: "input-number",
      required: true,
      quarter: true,
    },
    {
      label: "DO Number",
      name: "do_number",
      type: "input",
      quarter: true,
      required: true,
    },
    {
      label: "CO Number",
      name: "co_number",
      type: "input",
      quarter: true,
      required: true,
    },
    {
      label: "SO Number",
      name: "so_number",
      type: "input",
      quarter: true,
      required: true,
    },
    {
      label: "Specification",
      name: "specification",
      type: "select",
      required: true,
      options: millCertSpecificationOption,
    },
    {
      label: "Bar Marker",
      name: "bar_marker",
      type: "select",
      required: true,
      options: barMarkOption,
    },
  ];

  return (
    <CustomModalForm
      form={demoForm}
      formTitle={
        editData
          ? `Edit Delivery Order - ${editData.do_number}`
          : "Add Delivery Order"
      }
      modalOpen={modalOpen}
      setModalOpen={setModalOpen}
      editData={editData}
      setEditData={setEditData}
      onSubmit={onSubmit}
      loading={loading}
      context={
        <Row gutter={[16, 16]}>
          <CustomFormItems form_properties={DEMO_FORM} />
          <Col span={24}>
            <Typography.Text children={"Address"} />
            <Form.Item
              name="address"
              rules={[
                {
                  required: true,
                  message: `Please input address`,
                },
              ]}
              children={<Input.TextArea rows={5} />}
            />
          </Col>
        </Row>
      }
    />
  );
}
