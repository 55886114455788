import { useCallback, useState } from "react";
import useFetch from "../../hooks/useFetch";
import { useShowMessage } from "../Common/showMessage";

function useGetRebarImageCoordinate() {
  const [fetchWrapper] = useFetch();
  const [circles, setCircles] = useState([]);
  const [crosses, setCrosses] = useState([]);
  const [shapeSize, setShapeSize] = useState(0);
  const [coordinateLoading, setCoordinateLoading] = useState(false);

  const showMessage = useShowMessage();

  const getRebarImageCoordinate = useCallback(
    (rebar_key) => {
      setCoordinateLoading(true);
      fetchWrapper({
        endpoint_url: "rebar_verification/getRebarImageCoordinate",
        params: {
          rebar_id: rebar_key,
        },
        setLoading: setCoordinateLoading,
        onSuccess: (fetch_ouput) => {
          if (fetch_ouput.status) {
            setCircles(fetch_ouput.circle_coordinate);
            setCrosses(fetch_ouput.cross_coordinate);
            setShapeSize(fetch_ouput.shape_size);
          } else {
            showMessage({ type: "warning", message: fetch_ouput.error });
          }
        },
        onFetchError: () => {
          showMessage({
            apiType: "fetch",
            message: "get rebar image coordinate",
          });
        },
        onResponseError: () => {
          showMessage({
            apiType: "response",
            message: "Get Rebar Image Coordinate",
          });
        },
      });
    },
    [fetchWrapper]
  );
  return {
    getRebarImageCoordinate,
    circles,
    crosses,
    shapeSize,
    setShapeSize,
    setCircles,
    setCrosses,
    coordinateLoading,
  };
}

export default useGetRebarImageCoordinate;
