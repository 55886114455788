import { useCallback, useState } from "react";
import useFetch from "../../hooks/useFetch";
import { useShowMessage } from "../Common/showMessage";

function useGetDOProgressDetails() {
  const [fetchWrapper] = useFetch();
  const [previewLoading, setPreviewLoading] = useState(false);
  const [previewSummary, setPreviewSummary] = useState({});
  const [previewTable, setPreviewTable] = useState([]);

  const showMessage = useShowMessage();

  const getDOProgressDetails = useCallback(
    (key, setPreviewModal, setSelectKey) => {
      setPreviewLoading(true);
      fetchWrapper({
        endpoint_url: "do_progress/getDOProgressDetails",
        params: {
          key: key,
        },
        setLoading: setPreviewLoading,
        onSuccess: (fetch_output) => {
          if (fetch_output.status) {
            setPreviewSummary(fetch_output.summary_data);
            setPreviewTable(fetch_output.table_data);
            setPreviewModal(true);
            setSelectKey(key);
          } else {
            showMessage({ type: "warning", message: fetch_output.error });
          }
        },
        onFetchError: () => {
          showMessage({
            apiType: "fetch",
            message: "get delivery order progress details",
          });
        },
        onResponseError: () => {
          showMessage({
            apiType: "response",
            message: "Get Delivery Order Progress Details",
          });
        },
      });
    },
    [fetchWrapper]
  );

  return { getDOProgressDetails, previewLoading, previewSummary, previewTable };
}

export default useGetDOProgressDetails;
