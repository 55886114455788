import {
  DeleteOutlined,
  DownloadOutlined,
  EditOutlined,
  FilePdfOutlined,
} from "@ant-design/icons";
import { Button, Flex, Tag, Tooltip, Typography } from "antd";
import DataTable from "../common/DataTable";
import { useState } from "react";
import FunctionalButton from "../common/FunctionalButton";
import CustomTooltip from "../common/CustomTooltip";

const STATUS_TOOLTIP_CONTENT = [
  {
    type: "Pending",
    description: "The delivery order has just been created.",
  },
  {
    type: "In Progress",
    description: "Some bundles are currently in the delivery order.",
  },
  {
    type: "Completed",
    description: "All bundles in the delivery order have been verified",
  },
  {
    type: "Generated",
    description: "A mill certificate has been generated for the delivery order",
  },
];

export default function DeliveryOrderTable({
  tableData,
  handleEdit,
  handleDelete,
  handlePreview,
  handleDownload,
  handleFile,
  handleSelectRow,
  previewLoading,
  deleteLoading,
  downloadLoading,
  fileListLoading,
}) {
  const [loadRowKey, setLoadRowKey] = useState(null);
  const DELIVERY_ORDER_TABLE_COLUMN = [
    {
      title: "No.",
      dataIndex: "number",
      key: "number",
      width: 60,
    },
    {
      title: "DO",
      dataIndex: "do_number",
      key: "do_number",
      width: 120,
      render: (_, record) => {
        return (
          <Tooltip
            title="Click the deliver oder id for preview"
            children={
              <Button
                type="link"
                children={record.do_number}
                loading={previewLoading && loadRowKey == record.key}
                onClick={() => {
                  handlePreview(record.key);
                  setLoadRowKey(record.key);
                }}
              />
            }
          />
        );
      },
    },
    {
      title: "Customer",
      dataIndex: "customer",
      key: "customer",
      width: 120,
    },
    {
      title: "Address",
      dataIndex: "address",
      key: "address",
      width: 200,
      render: (address) => (
        <>
          {address.split("\n").map((line, index) => (
            <Typography.Text key={index}>
              {line}
              <br />
            </Typography.Text>
          ))}
        </>
      ),
    },
    {
      title: "Product",
      dataIndex: "product",
      key: "product",
      width: 80,
    },
    {
      title: "Specification",
      dataIndex: "specification",
      key: "specification",
      width: 150,
    },
    {
      title: "Description of Good",
      dataIndex: "description_of_good",
      key: "description_of_good",
      width: 150,
    },
    {
      title: "Lorry Plate",
      dataIndex: "lorry_plate",
      key: "lorry_plate",
      width: 100,
    },
    {
      title: "Number of Bundles",
      dataIndex: "number_of_bundles",
      key: "number_of_bundles",
      width: 100,
    },
    {
      title: "Captured Bundles",
      dataIndex: "captured_bundles",
      key: "captured_bundles",
      width: 100,
    },
    {
      title: (
        <CustomTooltip title={"Status"} content={STATUS_TOOLTIP_CONTENT} />
      ),
      dataIndex: "status",
      key: "status",
      width: 100,
      render: (_, record) => {
        return record.status == "Pending" ? (
          <Tag color="yellow" children={record.status} />
        ) : record.status == "In Progress" ? (
          <Tag color="processing" children={record.status} />
        ) : record.status == "Error" ? (
          <Tag color="error" children={record.status} />
        ) : (
          <Tag color="success" children={record.status} />
        );
      },
    },
    {
      title: "Create Date Time",
      dataIndex: "create_date_time",
      key: "create_date_time",
      width: 120,
      render: (create_date_time) => (
        <>
          {create_date_time.split("\n").map((line, index) => (
            <Typography.Text key={index}>
              {line}
              <br />
            </Typography.Text>
          ))}
        </>
      ),
    },
    {
      title: "Last Update Date Time",
      dataIndex: "last_update_date_time",
      key: "last_update_date_time",
      width: 150,
      render: (last_update_date_time) => (
        <>
          {last_update_date_time.split("\n").map((line, index) => (
            <Typography.Text key={index}>
              {line}
              <br />
            </Typography.Text>
          ))}
        </>
      ),
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      width: 125,
      render: (_, record) => {
        return (
          <Flex gap="middle">
            <FunctionalButton
              buttonClickFunction={() => handleEdit(record.action)}
              type=""
              width="40px"
              icon={<EditOutlined />}
              disabled={loadRowKey == record.key && deleteLoading}
            />
            {record.delete && (
              <FunctionalButton
                buttonClickFunction={() => {
                  handleDelete(record.key);
                  setLoadRowKey(record.key);
                }}
                show_confirmation={true}
                confirmation_message={{
                  title: "Confirmation",
                  content: "Sure to delete this material properties?",
                }}
                danger={true}
                width="40px"
                icon={<DeleteOutlined />}
                trigger_loading={loadRowKey == record.key && deleteLoading}
              />
            )}

            {record.complete && (
              <>
                <FunctionalButton
                  buttonClickFunction={() => {
                    handleDownload(record);
                    setLoadRowKey(record.key);
                  }}
                  width="40px"
                  type=""
                  icon={<DownloadOutlined />}
                  trigger_loading={loadRowKey == record.key && downloadLoading}
                />
                <FunctionalButton
                  buttonClickFunction={() => {
                    handleFile(record.key);
                    setLoadRowKey(record.key);
                  }}
                  width="40px"
                  icon={<FilePdfOutlined />}
                  disabled={
                    (loadRowKey == record.key && deleteLoading) || !record.file
                  }
                  trigger_loading={loadRowKey == record.key && fileListLoading}
                />
              </>
            )}
          </Flex>
        );
      },
    },
  ];

  return (
    <>
      <DataTable
        table_column={DELIVERY_ORDER_TABLE_COLUMN}
        table_data={tableData}
        tableRowSelection={true}
        selection_row={handleSelectRow}
        pageSize={7}
        columnCenter={true}
      />
    </>
  );
}
