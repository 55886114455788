import { useCallback, useState } from "react";
import useFetch from "../../../hooks/useFetch";
import { useShowMessage } from "../../Common/showMessage";

function useGetStuckBundles() {
  const [fetchWrapper] = useFetch();
  const [stuckBundlesChart, setStuckBundlesChart] = useState([]);

  const showMessage = useShowMessage();

  const getStuckBundles = useCallback(
    (setIsLoading) => {
      setIsLoading(true);
      fetchWrapper({
        endpoint_url: "dashboard/getStuckBundles",
        setLoading: setIsLoading,
        onSuccess: (fetch_output) => {
          if (fetch_output.status) {
            setStuckBundlesChart(fetch_output.data);
          } else {
            showMessage({ type: "warning", message: fetch_output.error });
          }
        },
        onFetchError: () => {
          showMessage({ apiType: "fetch", message: "get stuck bundles" });
        },
        onResponseError: () => {
          showMessage({ apiType: "response", message: "Get Stuck Bundles" });
        },
      });
    },
    [fetchWrapper]
  );

  return { getStuckBundles, stuckBundlesChart };
}

export default useGetStuckBundles;
