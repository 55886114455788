import { useCallback, useState } from "react";
import useFetch from "../../../hooks/useFetch";
import { useShowMessage } from "../../Common/showMessage";

function useGetBundleCurrentOutput() {
  const [fetchWrapper] = useFetch();
  const [currentOutputChart, setCurrentOutputChart] = useState([]);

  const showMessage = useShowMessage();

  const getBundleCurrentOutput = useCallback(
    (setIsLoading) => {
      setIsLoading(true);
      fetchWrapper({
        endpoint_url: "dashboard/getBundleCurrentOutput",
        setLoading: setIsLoading,
        onSuccess: (fetch_output) => {
          if (fetch_output.status) {
            setCurrentOutputChart(fetch_output.data);
          } else {
            showMessage({ type: "warning", message: fetch_output.error });
          }
        },
        onFetchError: () => {
          showMessage({
            apiType: "fetch",
            message: "get bundles current output",
          });
        },
        onResponseError: () => {
          showMessage({
            apiType: "response",
            message: "Get Bundles Current Output",
          });
        },
      });
    },
    [fetchWrapper]
  );

  return { getBundleCurrentOutput, currentOutputChart };
}

export default useGetBundleCurrentOutput;
