import { useCallback, useState } from "react";
import useFetch from "../../../hooks/useFetch";
import { useShowMessage } from "../../Common/showMessage";

function useGetBundleListSummary() {
  const [fetchWrapper] = useFetch();
  const [bundleSummaryLoading, setBundleSummaryLoading] = useState(false);
  const [bundleSummary, setBundleSummary] = useState({
    bundles_complete: 0,
    rebar_count_progress: {
      verified: 0,
      ai_counted: 100,
    },
    breakdown_verified: {
      adjusted: 100,
      non_adjust: 100,
    },
    stage: [
      {
        ai_process: 0,
        verified: 0,
        pending: 0,
        stage: "Initial",
      },
      {
        ai_process: 0,
        verified: 0,
        pending: 0,
        stage: "Rework",
      },
      {
        ai_process: 0,
        verified: 0,
        pending: 0,
        stage: "Load",
      },
    ],
  });

  const showMessage = useShowMessage();

  const getBundleListSummary = useCallback(
    (startDate, endDate) => {
      setBundleSummaryLoading(true);
      fetchWrapper({
        endpoint_url: "bundle_list/getBundleListSummary",
        params: {
          startDate: startDate,
          endDate: endDate,
        },
        setLoading: setBundleSummaryLoading,
        onSuccess: (fetch_output) => {
          if (fetch_output.status) {
            setBundleSummary(fetch_output.data);
          } else {
            showMessage({ type: "warning", content: fetch_output.error });
          }
        },
        onFetchError: () => {
          showMessage({ apiType: "fetch", message: "get bundle list summary" });
        },
        onResponseError: () => {
          showMessage({ apiType: "response", message: "Bundle List Summary" });
        },
      });
    },
    [fetchWrapper]
  );

  return { getBundleListSummary, bundleSummary, bundleSummaryLoading };
}

export default useGetBundleListSummary;
