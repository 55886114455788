import { useCallback, useContext, useState } from "react";
import useFetch from "../../hooks/useFetch";
import { IndexContext } from "../../context/IndexContext";
import { useShowMessage } from "../Common/showMessage";

function useRemoveBundle() {
  const [fetchWrapper] = useFetch();
  const { messageApi } = useContext(IndexContext);
  const [removeLoading, setRemoveLoading] = useState(false);

  const showMessage = useShowMessage();

  const removeBundle = useCallback(
    (
      key,
      handleDOProgressList,
      getDOProgressDetails,
      selectKey,
      setPreviewModal,
      setSelectKey
    ) => {
      return new Promise((resolve, reject) => {
        setRemoveLoading(true);
        fetchWrapper({
          endpoint_url: "do_progress/removeBundle",
          params: {
            key: key,
          },
          setLoading: setRemoveLoading,
          onSuccess: (fetch_output) => {
            if (fetch_output.status) {
              showMessage({ type: "success", message: fetch_output.message });
              setPreviewModal(false);
              handleDOProgressList();
              getDOProgressDetails(selectKey, setPreviewModal, setSelectKey);
            } else {
              showMessage({ type: "warning", message: fetch_output.error });
            }
            resolve(fetch_output);
          },
          onFailure: (error) => {
            showMessage({ message: error });
            reject(false);
          },
          onFetchError: () => {
            showMessage({ apiType: "fetch", message: "remove bundle" });
            reject(false);
          },
          onResponseError: () => {
            showMessage({ apiType: "respose", message: "remove bundle" });
            reject(false);
          },
        });
      });
    },
    [fetchWrapper]
  );

  return { removeBundle, removeLoading };
}

export default useRemoveBundle;
