import { useCallback, useContext, useState } from "react";
import useFetch from "../../../hooks/useFetch";
import { IndexContext } from "../../../context/IndexContext";
import { useShowMessage } from "../../Common/showMessage";

function useUpdateRebarReject() {
  const [fetchWrapper] = useFetch();
  const { messageApi } = useContext(IndexContext);
  const [retakeLoading, setRetakeLoading] = useState(false);
  const [retakeSuccess, setRetakeSuccess] = useState(false);

  const showMessage = useShowMessage();

  const updateRebarReject = useCallback(
    (rebar_key) => {
      return new Promise((resolve, reject) => {
        setRetakeLoading(true);
        setRetakeSuccess(false);
        fetchWrapper({
          endpoint_url: "ai_rebar_count/updateRebarReject",
          params: {
            rebar_key: rebar_key,
          },
          isLoading: setRetakeLoading,
          onSuccess: (fetch_output) => {
            if (fetch_output.status) {
              showMessage({ type: "success", message: fetch_output.message });
              setRetakeSuccess(true);
            } else {
              showMessage({ type: "warning", message: fetch_output.error });
              setRetakeSuccess(false);
            }
            resolve(fetch_output);
          },
          onFailure: (error) => {
            showMessage({ message: error });
            setRetakeSuccess(true);
            reject(false);
          },
          onFetchError: () => {
            showMessage({ apiType: "fetch", message: "retake rebar image" });
            setRetakeSuccess(true);
            reject(false);
          },
          onResponseError: () => {
            showMessage({
              apiType: "response",
              message: "Upload Rebar Rework",
            });
            setRetakeSuccess(true);
            reject(false);
          },
        });
      });
    },
    [fetchWrapper]
  );
  return { updateRebarReject, retakeLoading, retakeSuccess };
}

export default useUpdateRebarReject;
