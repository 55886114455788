import CustomDescription from "../common/CustomDescription";
import CustomModal from "../common/CustomModal";
import dayjs from "dayjs";
import { Image, Typography } from "antd";
import barMarkImage from "../../assets/Bar_Mark.png";

export default function DemoEventModal({
  modalOpen,
  setModalOpen,
  eventDetails,
}) {
  const EVENT_DESCRIPTION = [
    {
      label: "DO Number",
      key: "do_number",
      children: eventDetails?.title,
    },
    {
      label: "SO Number",
      key: "so_number",
      children: eventDetails?.value.so_number,
    },
    {
      label: "CO Number",
      key: "co_number",
      children: eventDetails?.value.co_number,
    },
    {
      label: "Customer",
      key: "customer",
      children: eventDetails?.value.customer,
    },
    {
      label: "Address",
      key: "address",
      children: eventDetails?.value.address
        ? eventDetails.value.address.split("\n").map((line, index) => (
            <>
              <Typography.Text key={index} children={line} />
              <br />
            </>
          ))
        : null,
      contentStyle: { display: "block" },
      span: 2,
    },
    {
      label: "Product",
      key: "product",
      children: eventDetails?.value.product,
    },
    {
      label: "Number of Bundles",
      key: "number_of_bundles",
      children: eventDetails?.value.number_of_bundles,
    },
    {
      label: "Lorry Plate",
      key: "lorry_plate",
      children: eventDetails?.value.lorry_plate,
    },
    {
      label: "Specification",
      key: "specification",
      children: eventDetails?.value.specification,
    },
    {
      label: "Description of Good",
      key: "description_of_good",
      children: eventDetails?.value.description_of_good,
    },
    {
      label: "Bar Mark",
      key: "bar_mark",
      children: eventDetails?.value.bar_mark ? (
        <Image src={barMarkImage} />
      ) : (
        <Image src={barMarkImage} />
      ),
    },
    {
      label: "Start Time",
      key: "start",
      children: eventDetails?.start
        ? dayjs(eventDetails.start).format("YYYY-MM-DD hh:mm:ss A")
        : null,
    },
    {
      label: "End Time",
      key: "end",
      children: eventDetails?.end
        ? dayjs(eventDetails.end).format("YYYY-MM-DD hh:mm:ss A")
        : null,
    },
  ];
  return (
    <CustomModal
      visible={modalOpen}
      onCancel={() => setModalOpen(false)}
      title={"Delivery Order Details"}
      width={1200}
      content={
        <>
          {
            <CustomDescription
              items={EVENT_DESCRIPTION}
              style={{ marginTop: "30px" }}
            />
          }
        </>
      }
    />
  );
}
