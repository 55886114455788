import { useCallback, useState } from "react";
import useFetch from "../../../hooks/useFetch";
import { useShowMessage } from "../../Common/showMessage";

function useGetProductList() {
  const [fetchWrapper] = useFetch();
  const [productListLoading, setProductListLoading] = useState(false);
  const [productList, setProductList] = useState([]);

  const showMessage = useShowMessage();

  const getProductList = useCallback(() => {
    setProductListLoading(true);
    fetchWrapper({
      endpoint_url: "configuration/getProductList",
      setLoading: setProductListLoading,
      onSuccess: (fetch_ouput) => {
        if (fetch_ouput.status) {
          setProductList(fetch_ouput.data);
        } else {
          showMessage({ type: "warning", message: fetch_ouput.error });
        }
      },
      onFetchError: () => {
        showMessage({ apiType: "fetch", message: "get product list" });
      },
      onResponseError: () => {
        showMessage({ apiType: "response", message: "Get Product List" });
      },
    });
  }, [fetchWrapper]);

  return { getProductList, productList, productListLoading };
}

export default useGetProductList;
