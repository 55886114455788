import { Card, Flex, List, Typography } from "antd";

export default function MissingBundleList({ missingDetails, pageSize }) {
  return (
    <List
      grid={{
        gutter: 16,
        xs: 1,
        sm: 2,
        md: 2,
        lg: 3,
        xl: 3,
        xxl: 3,
      }}
      dataSource={missingDetails?.data?.missing_serial || []}
      renderItem={(item, index) => {
        let serialName = missingDetails?.name;

        return (
          <List.Item>
            <Card style={{ border: "2px solid #ddd" }}>
              <Flex align={"center"} justify={"center"}>
                <Typography.Title level={4} style={{ margin: 0 }}>
                  {serialName}-{item}
                </Typography.Title>
              </Flex>
            </Card>
          </List.Item>
        );
      }}
      pagination={{
        position: "bottom",
        align: "end",
        pageSize: pageSize,
      }}
    />
  );
}
