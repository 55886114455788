import CustomColumnChart from "../../../chart/CustomColumnChart";

export default function ProductionBundlesChart({ chartData }) {
  return (
    <CustomColumnChart
      data={chartData}
      isGroup={true}
      seriesField={"type"}
      xField={"time"}
      xAxis={"Date"}
      yAxis={"Quantity"}
      chartSize={"23vh"}
      labelOffset={0}
      labelRotate={7.87}
    />
  );
}
