import useFetch from "../../../hooks/useFetch";
import { useCallback, useState } from "react";
import { useShowMessage } from "../../Common/showMessage";

function useGetMillCertList() {
  const [fetchWrapper] = useFetch();
  const [millCertLoading, setMillCertLoading] = useState(false);
  const [millCertList, setMillCertList] = useState([]);

  const showMessage = useShowMessage();

  const getMillCertList = useCallback(() => {
    setMillCertLoading(true);
    fetchWrapper({
      endpoint_url: "configuration/getMillCertList",
      setLoading: setMillCertLoading,
      onSuccess: (fetch_output) => {
        if (fetch_output.status) {
          setMillCertList(fetch_output.data);
        } else {
          showMessage({ type: "warning", message: fetch_output.message });
        }
      },
      onFetchError: () => {
        showMessage({ apiType: "fetch", message: "get mill cert list" });
      },
      onResponseError: () => {
        showMessage({ apiType: "response", message: "Get Mill Cert List" });
      },
    });
  }, [fetchWrapper]);

  return { getMillCertList, millCertList, millCertLoading };
}

export default useGetMillCertList;
