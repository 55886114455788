import { useCallback, useState } from "react";
import useFetch from "../../../hooks/useFetch";
import { useShowMessage } from "../../Common/showMessage";

function useGetBundleListInfo() {
  const [fetchWrapper] = useFetch();
  const [bundleInfoLoading, setBundleInfoLoading] = useState(false);
  const [bundleListInfo, setBundleListInfo] = useState([]);

  const showMessage = useShowMessage();

  const getBundleListInfo = useCallback(
    (startDate, endDate) => {
      setBundleInfoLoading(true);
      fetchWrapper({
        endpoint_url: "bundle_list/getBundleListInfo",
        params: {
          startDate: startDate,
          endDate: endDate,
        },
        setLoading: setBundleInfoLoading,
        onSuccess: (fetch_output) => {
          if (fetch_output.status) {
            setBundleListInfo(fetch_output.data);
          } else {
            showMessage({ type: "warning", message: fetch_output.error });
          }
        },
        onFetchError: () => {
          showMessage({ apiType: "fetch", message: "get bundle list info" });
        },
        onResponseError: () => {
          showMessage({ apiType: "response", message: "Bundle List Info" });
        },
      });
    },
    [fetchWrapper]
  );

  return { getBundleListInfo, bundleListInfo, bundleInfoLoading };
}

export default useGetBundleListInfo;
