import { useCallback } from "react";
import useFetch from "../../hooks/useFetch";
import { useShowMessage } from "../Common/showMessage";

function useDownloadFile() {
  const [fetchWrapper] = useFetch();

  const showMessage = useShowMessage();

  const downloadFile = useCallback(
    (folderPath, fileName) => {
      fetchWrapper({
        endpoint_url: "download_file/downloadFile",
        params: {
          folder_path: folderPath,
          file_name: fileName,
        },
        json_output: false,
        onSuccess: (response) => {
          if (response.headers.get("content-type") === "application/json") {
            response.json().then((data) => {
              if (data.error) {
                showMessage({ type: "warning", message: data.error });
              }
            });
          } else {
            response.blob().then((blob) => {
              const url = window.URL.createObjectURL(blob);
              const downloadLink = document.createElement("a");
              downloadLink.href = url;
              downloadLink.setAttribute("download", fileName);
              document.body.appendChild(downloadLink);
              downloadLink.click();
              document.body.removeChild(downloadLink);
            });
          }
        },
        onFetchError: () => {
          showMessage({ apiType: "fetch", message: "download file" });
        },
        onResponseError: () => {
          showMessage({ apiType: "response", message: "Download File" });
        },
      });
    },
    [fetchWrapper]
  );
  return { downloadFile };
}

export default useDownloadFile;
