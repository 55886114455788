import { useEffect } from "react";
import CustomModal from "./CustomModal";
import { Button, Flex, Form } from "antd";

export default function CustomModalForm({
  form,
  formTitle,
  modalOpen,
  setModalOpen,
  editData,
  setEditData = () => {},
  handleFieldChange = () => {},
  additionalData,
  onSubmit,
  loading,
  clearForm = true,
  closeForm = false,
  context,
}) {
  const onFinish = (value) => {
    const formData = { ...value, ...additionalData };

    if (editData) {
      onSubmit(formData, editData["key"]);
    } else onSubmit(formData, null);
  };

  const handleClearForm = () => form.resetFields();
  const handleCloseForm = () => setModalOpen(false);

  useEffect(() => {
    if (modalOpen == false) {
      form.resetFields();
      setEditData(null);
    }
  }, [modalOpen]);

  useEffect(() => {
    if (editData) form.setFieldsValue(editData);
  }, [editData]);

  return (
    <CustomModal
      title={formTitle}
      close_icon={true}
      visible={modalOpen}
      onCancel={() => handleCloseForm()}
      content={
        <Form
          form={form}
          onFinish={onFinish}
          onFieldsChange={handleFieldChange}
        >
          {context}

          <Flex gap="middle" justify="flex-end">
            {closeForm && (
              <Form.Item>
                <Button
                  onClick={handleCloseForm}
                  type="link"
                  children="Cancel"
                  disabled={loading}
                />
              </Form.Item>
            )}
            {clearForm && (
              <Form.Item>
                <Button
                  onClick={handleClearForm}
                  type="link"
                  children="Clear All"
                  disabled={loading}
                />
              </Form.Item>
            )}
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                children={editData ? "Update" : "Create"}
                loading={loading}
              />
            </Form.Item>
          </Flex>
        </Form>
      }
    />
  );
}
