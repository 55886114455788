import React from "react";
import { Modal, Typography, Button } from "antd";
import PropTypes from "prop-types"; // For type checking
import { useContext } from "react";
import { IndexContext } from "../../context/IndexContext";
import { validateProp } from "../../hooks/useValidate"; // Adjust path as needed

const { Text } = Typography;

export default function CustomModal({
  visible,
  onCancel,
  onOk,
  title,
  width = 800,
  title_style = {},
  content,
  content_style = {},
  footer,
  footer_style = {},
  modal_style = {},
  title_icon,
  title_icon_style = {},
  close_icon = false,
}) {
  const { isLightTheme } = useContext(IndexContext);

  // Default styles
  const default_modal_style = {
    ...modal_style,
  };

  const default_title_style = {
    fontSize: "16px",
    fontWeight: "500",
    ...title_style,
  };

  const default_title_icon_style = {
    color: default_title_style.color,
    fontSize: "20px",
    ...title_icon_style,
  };

  const default_content_style = {
    ...content_style,
  };

  const default_footer_style = {
    display: "flex",
    justifyContent: "flex-end",
    padding: "16px",
    ...footer_style,
  };

  return (
    <Modal
      closeIcon={close_icon}
      open={visible}
      onCancel={onCancel}
      width={width}
      onOk={onOk}
      style={default_modal_style}
      footer={null} // We will provide custom footer
      title={
        <div
          style={{
            display: "flex",
            alignItems: "center",
            ...default_title_style,
            fontSize: "20px",
            width: "100%",
          }}
        >
          {title}
          {title_icon && (
            <span style={default_title_icon_style}>{title_icon}</span>
          )}
        </div>
      }
      centered
    >
      <div style={default_content_style}>{content}</div>

      {footer && <div style={default_footer_style}>{footer}</div>}
    </Modal>
  );
}

// PropTypes validation
CustomModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  onOk: PropTypes.func,
  title: validateProp("node"),
  title_style: validateProp("style"),
  content: validateProp("node"),
  content_style: validateProp("style"),
  footer: validateProp("node"),
  footer_style: validateProp("style"),
  modal_style: validateProp("style"),
  title_icon: validateProp("node"),
  title_icon_style: validateProp("style"),
};
