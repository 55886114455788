import { useContext, useEffect, useRef } from "react";
import { IndexContext } from "../../context/IndexContext";
import { Pie } from "@antv/g2plot";

export default function CustomPieChart(props) {
  const {
    data = [],
    appendPadding = 10,
    angleField = "value",
    colorField = "type",
    radius = 1,
    chartSize = "40vh",
    legendOpen = true,
    labelOpen = true,
    labelType = "spider",
    labelHeight = 10,
    labelContent = "{name}\n{percentage}",
    labelLayout = "overlap",
    animationOpen = true,
    animationDuration = 2000,
    animationType = "wave-in",
    animationEasing = "easeCircleOut",
  } = props;

  const { isLightTheme } = useContext(IndexContext);
  const containerRef = useRef(null);
  const chartRef = useRef(null);

  useEffect(() => {
    if (containerRef.current) {
      if (chartRef.current) {
        chartRef.current.destroy();
        chartRef.current = null;
      }

      chartRef.current = new Pie(containerRef.current, {
        data,
        appendPadding: appendPadding,
        angleField: angleField,
        colorField: colorField,
        radius: radius,
        legend: legendOpen,
        label: labelOpen && {
          type: labelType,
          labelHeight: labelHeight,
          content: labelContent,
          layout: labelLayout,
        },
        interactions: [
          {
            type: "element-highlight",
          },
          {
            type: "legend-highlight",
          },
          {
            type: "active-region",
          },
        ],
        animation: animationOpen && {
          appear: {
            animation: animationType,
            duration: animationDuration,
            easing: animationEasing,
          },
        },
      });
      chartRef.current.render();
    }

    return () => {
      if (chartRef.current) {
        chartRef.current.destroy();
        chartRef.current = null;
      }
    };
  }, [isLightTheme, data]);

  return <div ref={containerRef} style={{ height: chartSize }} />;
}
