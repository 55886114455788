import { useCallback, useState } from "react";
import useFetch from "../../hooks/useFetch";
import { useShowMessage } from "../Common/showMessage";

function useGetDeliveryOrderPreview() {
  const [fetchWrapper] = useFetch();
  const [previewLoading, setPreviewLoading] = useState(false);
  const [previewSummary, setPreviewSummary] = useState({});
  const [previewTable, setPreviewTable] = useState([]);
  const [previewMillCert, setPreviewMillCert] = useState("normal_mill_cert");

  const showMessage = useShowMessage();

  const getDeliveryOrderPreview = useCallback(
    (key, setPreviewModal) => {
      setPreviewLoading(true);
      fetchWrapper({
        endpoint_url: "delivery_order/getDeliveryOrderPreview",
        params: {
          delivery_order_key: key,
        },
        setLoading: setPreviewLoading,
        onSuccess: (fetch_output) => {
          if (fetch_output.status) {
            setPreviewSummary(fetch_output.summary_data);
            setPreviewTable(fetch_output.table_data);
            setPreviewMillCert(fetch_output.mill_cert_type);
            setPreviewModal(true);
          } else {
            showMessage({ type: "warning", message: fetch_output.error });
          }
        },
        onFetchError: () => {
          showMessage({
            apiType: "fetch",
            message: "get delivery order preview",
          });
        },
        onResponseError: () => {
          showMessage({
            apiType: "response",
            message: "Get Delivery Order Preview",
          });
        },
      });
    },
    [fetchWrapper]
  );

  return {
    getDeliveryOrderPreview,
    previewSummary,
    previewTable,
    previewMillCert,
    previewLoading,
  };
}

export default useGetDeliveryOrderPreview;
