import { useCallback } from "react";
import useFetch from "../../hooks/useFetch";
import { useNavigate } from "react-router-dom";
import {
  supervisor,
  account,
  qcOperator,
  manager,
} from "../../configs/configs.js";
import { useShowMessage } from "../Common/showMessage.js";

function useCheckUserLoginRecord() {
  const navigate = useNavigate();
  const [fetchWrapper] = useFetch();

  const showMessage = useShowMessage();

  const checkUserLoginRecord = useCallback(() => {
    fetchWrapper({
      endpoint_url: "login_record/checkUserLoginRecord",
      onSuccess: (response) => {
        if (!response.status) {
          navigate("/Login", { replace: true });
          showMessage({ type: "warning", message: response.error });
        } else {
          if (response.record) {
            if (response.user_type === supervisor) {
              navigate("/Bundle_List/Bundle_Summary", { replace: true });
            } else if (response.user_type === manager) {
              navigate("/Analytics/Dashboard", { replace: true });
            } else if (response.user_type == account) {
              navigate("/Digital_Mill_Cert/Delivery_Order", { replace: true });
            } else if (response.user_type == qcOperator) {
              navigate("/Digital_Mill_Cert/Material_Properties", {
                replace: true,
              });
            } else {
              navigate("/Ai_Rebar_Count", { replace: true });
            }
            showMessage({ type: "warning", message: response.message });
          }
        }
      },
      onFetchError: () => {
        showMessage({ apiType: "fetch", message: "check user login record" });
      },
      onResponseError: () => {
        showMessage({
          apiType: "response",
          message: "Check User Login Record",
        });
      },
    });
  }, [fetchWrapper]);

  return { checkUserLoginRecord };
}

export default useCheckUserLoginRecord;
