import CustomLineChart from "../../chart/CustomLineChart";

export default function IndicatorLineChart({ data }) {
  return (
    <CustomLineChart
      data={data}
      isGroup={true}
      seriesField={"category"}
      xField={"time"}
      yAxis={"Quantity"}
      chartSize={"23vh"}
      smooth={false}
      pointShape={"diamond"}
      pointSize={5}
    />
  );
}
