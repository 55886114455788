import { useCallback, useState } from "react";
import useFetch from "../../../hooks/useFetch";
import { useShowMessage } from "../../Common/showMessage";

function useAddRebarManually() {
  const [fetchWrapper] = useFetch();
  const [rebarFormLoading, setRebarFormLoading] = useState(false);
  const showMessage = useShowMessage();

  const addRebarManually = useCallback(
    (rebarData, tagID, imageName, setState, setTagId, reset) => {
      return new Promise((resolve, reject) => {
        setRebarFormLoading(true);
        fetchWrapper({
          endpoint_url: "ai_rebar_count/addRebar",
          params: {
            rebar_data: rebarData,
            tag_id: tagID,
            image_name: imageName,
          },
          setLoading: setRebarFormLoading,
          onSuccess: (fetch_output) => {
            if (fetch_output.status) {
              setState("tagState");
              reset();
              setTagId(null);

              showMessage({ type: "success", message: fetch_output.message });
            } else {
              showMessage({ type: "warning", message: fetch_output.error });
            }
            resolve(fetch_output);
          },
          onFailure: (error) => {
            showMessage({ message: error });
            reject(error);
          },
          onFetchError: () => {
            showMessage({ apiType: "fetch", message: "add rebar" });
          },
          onResponseError: () => {
            showMessage({ apiType: "response", message: "Add Rebar" });
          },
        });
      });
    },
    [fetchWrapper]
  );

  return { addRebarManually, rebarFormLoading };
}

export default useAddRebarManually;
